import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "good-card mb-4 card-hover" }
const _hoisted_2 = { class: "bg-gray border--top p-4" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "mr-4" }
const _hoisted_6 = { class: "flex justify-between" }
const _hoisted_7 = { class: "mr-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "w-[86%]" }

import { IGood } from '@/describers/GoodsDescriber'
import { ref } from 'vue'
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  good: IGood
  year: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoodCard',
  props: {
    good: {},
    year: {}
  },
  setup(__props: any) {

const props = __props
const addedToBasket = ref(props.good.inBasket)
const { numberSeparator } = useTextFilters()

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_checkbox, {
              modelValue: addedToBasket.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addedToBasket).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(props.good.title) + " " + _toDisplayString(_ctx.year), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          (props.good.name !== 'tax_plan')
            ? (_openBlock(), _createElementBlock("h3", _hoisted_8, "₽ " + _toDisplayString(_unref(numberSeparator)(props.good.basePrice.value, ' ')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(props.good.description), 1)
      ])
    ])
  ]))
}
}

})