<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useRequestService } from '@/use/useRequestService'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useUserRepository } from '@/entityes/user/user.repository'
import { FetchError } from 'ofetch'
import { Search } from '@element-plus/icons-vue'

const type = ref<'invoice' | 'user' | 'uid'>('invoice')
const searchString = ref<string>('')
const router = useRouter()
const { isProgress } = useRequestService()
const invoiceRepository = useInvoiceRepository()
const userRepository = useUserRepository()

async function send() {
  if (isProgress.value) return
  try {
    switch (type.value) {
      case 'invoice':
        const invoice = await invoiceRepository.getInvoice(searchString.value)
        router.push({
          name: 'invoice show',
          params: {
            id: invoice.id,
          },
        })
        break
      case 'user':
        const user_id = await userRepository.findUser(searchString.value)
        router.push({
          name: 'user show',
          params: {
            userId: user_id,
          },
        })
        break
      case 'uid':
        const res = await invoiceRepository.getInvoiceByUid(searchString.value)
        router.push({
          name: 'invoice show',
          params: {
            id: res,
          },
        })
        break
    }
  } catch (err) {
    if (err instanceof FetchError) {
      ElMessage.error({
        message: 'Данный запрос не найден',
      })
    } else {
      console.error(err)
    }
  }
}
</script>

<template>
  <div class="border mb-4">
    <form @submit.prevent="send">
      <el-input v-model="searchString" placeholder="Search" @keyup.enter="send" class="mb-4" />
      <div class="flex items-center">
        <el-select class="mr-4" v-model="type" placeholder="-">
          <el-option value="user" :label="`🙂 user`">
            <el-icon :size="14" color="#4b60cecc">
              <UserFilled />
            </el-icon>
            <span>&nbsp;User</span>
          </el-option>
          <el-option value="invoice" :label="`📋 invoice`">
            <el-icon :size="14" color="#4b60cecc">
              <Document />
            </el-icon>
            <span>&nbsp;Invoice</span>
          </el-option>
          <el-option value="uid" label="📋 uid">
            <el-icon :size="14" color="#4b60cecc">
              <Document />
            </el-icon>
            <span>&nbsp;uid</span>
          </el-option>
        </el-select>
        <el-button :icon="Search" type="primary" native-type="submit" :loading="isProgress" />
      </div>
    </form>
  </div>
</template>

<style lang="sass" scoped></style>
