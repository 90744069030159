import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "px-4 pt-4 mb-4" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-wrap mb-4"
}
const _hoisted_6 = { class: "mr-4" }
const _hoisted_7 = { class: "flex items-center mb-1" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex items-center mb-1" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "mb-8" }
const _hoisted_12 = { class: "flex items-center mb-1" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "flex items-center mb-1" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "flex items-center mb-1" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "flex items-center mb-1" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 0,
  class: "flex"
}
const _hoisted_22 = { class: "px-4" }
const _hoisted_23 = { class: "flex flex-wrap mb-4" }
const _hoisted_24 = { class: "mr-4" }
const _hoisted_25 = { class: "flex items-center mb-1" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  key: 0,
  class: "mr-4"
}
const _hoisted_28 = { class: "flex items-center mb-1" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "mr-4" }
const _hoisted_31 = { class: "flex items-center mb-1" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = {
  key: 1,
  class: "mr-4"
}
const _hoisted_34 = { class: "flex items-center mb-1" }
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { key: 2 }
const _hoisted_37 = { class: "flex items mb-1" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = { class: "flex flex-wrap mb-4" }
const _hoisted_40 = { class: "mr-4" }
const _hoisted_41 = { class: "flex items-center mb-1" }
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = {
  key: 0,
  class: "mr-4 mb-4"
}
const _hoisted_44 = { class: "flex mb-1 items-center" }
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = { key: 1 }
const _hoisted_47 = { class: "flex items-center mb-1" }
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = {
  key: 0,
  class: "flex items-center flex-wrap mb-4"
}
const _hoisted_50 = { class: "mr-4" }
const _hoisted_51 = { class: "flex mb-1 items-center" }
const _hoisted_52 = ["innerHTML"]
const _hoisted_53 = { class: "flex mb-1 items-center" }
const _hoisted_54 = ["innerHTML"]
const _hoisted_55 = { class: "px-4 mb-4" }
const _hoisted_56 = { class: "flex items-center mb-1" }
const _hoisted_57 = ["innerHTML"]
const _hoisted_58 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_59 = { class: "mb-4" }
const _hoisted_60 = { key: 0 }
const _hoisted_61 = { key: 0 }
const _hoisted_62 = { class: "flex" }
const _hoisted_63 = {
  key: 1,
  class: "flex items-center flex-wrap"
}
const _hoisted_64 = { class: "mr-4" }
const _hoisted_65 = { class: "mr-4" }
const _hoisted_66 = { class: "mr-4" }
const _hoisted_67 = {
  key: 1,
  class: "flex"
}

import { computed, ref, watch, inject, nextTick, watchEffect, UnwrapNestedRefs } from 'vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import currency from '@/describers/CurrencyDescriber'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { getFields } from '../model/formFields'
import { object as obj, string as str, bool, number as num } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { ElMessageBox } from 'element-plus'
import { setTouchedHelper } from '../lib/setTouchedHelper'
import CustomCheckbox from '@/07-Shared/ui/Checkbox.vue'
import { cloneDeep } from 'lodash'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PropertySale',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

setTouchedHelper(props.item)

const methodOptions = {
  purchase: 'Покупка',
  donation: 'Дарение',
  donation_from_relatives: 'Дарение от близкого родственника',
  inheritance: 'Наследство',
  privatization: 'Приватизация',
  annuity: '<p>Рента с передачей имущества по договору<br class="br"> пожизненного содержания с иждивением</p>',
}
const expired = ref(true)
const expiredPeriod = ref(5)
watchEffect(() => {
  if (!expired.value) {
    ElMessageBox.confirm('Не подлежит декларации', {
      type: 'warning',
      message:
        'Указанный срок владения недвижимостью говорит о том, что декларировать доход от ее продажи нет необходимости.',
      confirmButtonText: 'Удалить этот доход',
      cancelButtonText: 'Не удалять',
    })
      .then(() => {
        emit('delete')
      })
      .catch(() => {
        expired.value = true
      })
  }
})

const { mainFields, otherAttributesField } = getFields(props.yearToCalc)
const schema = obj({
  ...mainFields,
  other_attributes: obj({
    ...otherAttributesField,
    amount_of_expenses: str().checkNumberString(),
    method: str().required(),
    property_type: bool(),
    // depends on shareProperty
    property_denominator: num().when('shareProperty', {
      is: true,
      then: (schema) => schema.required(),
    }),
    property_numerator: num().when('sharedProperty', {
      is: true,
      then: (schema) => schema.required(),
    }),
    // depends on rus value is true
    cadastral_number: str().test('cadastral-number-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      if (rus === true) return str().required().isValidSync(value)
      return true
    }),
    cadastral_value: str()
      .checkNumberString()
      .test('cadastral-number-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === true) return str().required().isValidSync(value)
        return true
      }),
    set_property_deduction: bool(),
    expense_currency: str()
      .length(3)
      .test('expense_currency-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const amountOfExpenses = Number(context.from?.[0].value.amount_of_expenses)
        if (rus) return true
        if (isNaN(amountOfExpenses) || amountOfExpenses <= 0) return true
        return str().required().isValidSync(value)
      }),
    expense_date: str()
      .checkYearValue(props.yearToCalc)
      .test('expense_currency-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const amountOfExpenses = Number(context.from?.[0].value.amount_of_expenses)
        if (rus) return true
        if (isNaN(amountOfExpenses) || amountOfExpenses <= 0) return true
        return str().required().isValidSync(value)
      }),
    // paid tax
    paid_tax: str().checkNumberString(),
    paid_tax_date: str().test('check_tax_date_validation', function (value) {
      const { createError, path } = this
      if (!!this.parent.paid_tax) {
        const reverse = value?.split('.').reverse().join('/') || ''
        const d = new Date(reverse)
        if (!value) return createError({ path, message: 'Поле является обязательным' })
        return (
          d.getFullYear() === props.yearToCalc ||
          createError({ path, message: 'Год не соответсвует отчетному периоду' })
        )
      }
      return true
    }),
    //helpFields
    shareProperty: bool(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, handleSubmit, errorBag, setValues } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: {
    ...{
      rus: true,
      physical_person: true,
      other_attributes: {
        property_type: false,
        method: 'purchase',
        set_property_deduction: true,
      },
    },
    ...{
      ...props.item.item,
    },
  },
})
watch(
  values,
  (value) => {
    if (value.other_attributes?.method && ['purchase', 'donation'].includes(value.other_attributes.method)) {
      expiredPeriod.value = 5
    } else {
      expiredPeriod.value = 3
    }
  },
  { immediate: true }
)
function setOksm(rus: boolean) {
  if (rus) {
    setValues({
      currency: 'RUB',
      other_attributes: {
        expense_currency: 'RUB',
        oksm: '643',
        oksm_broker: '643',
      },
    })
  } else {
    setValues({
      currency: '',
      other_attributes: {
        expense_currency: '',
        oksm: '',
        oksm_broker: '',
      },
    })
  }
}
watch(
  values,
  (val) => {
    val.rus !== undefined && setOksm(val.rus)
  },
  { immediate: true }
)

const dontHaveAmountOfExpense = computed(() => {
  return (
    values.other_attributes?.amount_of_expenses !== undefined ||
    Number(values.other_attributes?.amount_of_expenses) === 0
  )
})
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Доход получен", -1)),
        _createVNode(QuestionPopover, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.helpDescriber?.income_info
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(FormItem, { name: "rus" }, {
          default: _withCtx(({ value, onInput }) => [
            _createVNode(_component_el_radio_group, {
              "model-value": value,
              onChange: 
          (val) => {
            onInput(val)
          }
        
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, { value: true }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("в РФ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_radio, { value: false }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("в иностранном государстве")
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["model-value", "onChange"])
          ]),
          _: 1
        })
      ]),
      (!_unref(values).rus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-semibold" }, "Страна источника выплаты", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.oksm
                    }, null, 8, _hoisted_8)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.oksm" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_select, _mergeProps({ "model-value": value }, field, {
                    filterable: "",
                    class: "width-100",
                    placeholder: "Выберите страну"
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.shortname
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold" }, "Страна зачисления выплаты", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.oksm_broker
                    }, null, 8, _hoisted_10)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.oksm_broker" }, {
                default: _withCtx(({ value, field }) => [
                  _createVNode(_component_el_select, _mergeProps({ "model-value": value }, field, {
                    filterable: "",
                    placeholder: "Выберите страну"
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.shortname
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Способ приобретения", -1)),
          _createVNode(QuestionPopover, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.helpDescriber?.method
              }, null, 8, _hoisted_13)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "other_attributes.method" }, {
          default: _withCtx(({ value, onInput }) => [
            _createVNode(_component_el_radio_group, {
              "model-value": value,
              onChange: 
          (val) => {
            onInput(val)
          }
        
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(methodOptions, (item, key) => {
                  return _createVNode(_component_el_radio, {
                    key: key,
                    value: key
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", { innerHTML: item }, null, 8, _hoisted_14)
                    ]),
                    _: 2
                  }, 1032, ["value"])
                }), 64))
              ]),
              _: 2
            }, 1032, ["model-value", "onChange"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_15, [
                  _cache[7] || (_cache[7] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Срок владения", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.expired
                      }, null, 8, _hoisted_16)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_el_switch, {
                  modelValue: expired.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((expired).value = $event)),
                  "active-text": `Менее ${expiredPeriod.value} лет`,
                  "inactive-text": `Более ${expiredPeriod.value} лет`
                }, null, 8, ["modelValue", "active-text", "inactive-text"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_17, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Тип недвижимости", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.type
                      }, null, 8, _hoisted_18)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.property_type" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_switch, _mergeProps({ "model-value": value }, field, {
                      "active-text": "Нежилая",
                      "inactive-text": "Жилая"
                    }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_19, [
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Продажа имущества", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.share
                    }, null, 8, _hoisted_20)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", {
                class: _normalizeClass([_unref(values)?.other_attributes?.shareProperty ? 'mb-4' : ''])
              }, [
                _createVNode(FormItem, { name: "other_attributes.shareProperty" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_switch, _mergeProps({ "model-value": value }, field, {
                      "active-text": "Доли",
                      "inactive-text": "Целого"
                    }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ], 2),
              (_unref(values)?.other_attributes?.shareProperty)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("div", null, [
                      _createVNode(FormItem, { name: "other_attributes.property_numerator" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "1" }), null, 16, ["model-value"]), [
                            [_directive_mask, ['####']]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                      _createElementVNode("h1", { class: "separator" }, "/")
                    ], -1)),
                    _createElementVNode("div", null, [
                      _createVNode(FormItem, {
                        name: "other_attributes.property_denominator",
                        class: "share_input"
                      }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "1" }), null, 16, ["model-value"]), [
                            [_directive_mask, ['####']]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _cache[32] || (_cache[32] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1)),
    _createElementVNode("div", _hoisted_22, [
      _cache[22] || (_cache[22] = _createElementVNode("p", { class: "font-semibold mb-4" }, "Данные о доходе", -1)),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Сумма дохода от продажи", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.amount
                }, null, 8, _hoisted_26)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "amount" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "Сумма, руб." }), null, 16, ["model-value"])
            ]),
            _: 1
          })
        ]),
        (!_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _cache[12] || (_cache[12] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Валюта продажи", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.currency
                    }, null, 8, _hoisted_29)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "currency" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_select, _mergeProps({ "model-value": value }, field, {
                    filterable: "",
                    class: "width-100",
                    placeholder: "Укажите валюту"
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currency), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.code
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата получения дохода", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.date
                }, null, 8, _hoisted_32)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "date" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_date_picker, _mergeProps({ "model-value": value }, field, {
                type: "date",
                placeholder: "Выберите дату",
                format: "DD.MM.YYYY",
                "value-format": "DD.MM.YYYY"
              }), null, 16, ["model-value"])
            ]),
            _: 1
          })
        ]),
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Кадастровый номер", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.cadastral_number
                    }, null, 8, _hoisted_35)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.cadastral_number" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _cache[15] || (_cache[15] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Кадастровая стоимость", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.cadastral_value
                    }, null, 8, _hoisted_38)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.cadastral_value" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _cache[16] || (_cache[16] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Сумма расходов на покупку", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.amount_of_expenses
                }, null, 8, _hoisted_42)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "other_attributes.amount_of_expenses" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
            ]),
            _: 1
          })
        ]),
        (
          !_unref(values).rus &&
          _unref(values).other_attributes?.amount_of_expenses &&
          Number(_unref(values).other_attributes.amount_of_expenses) > 0
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _cache[17] || (_cache[17] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Валюта покупки", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.expense_currency
                    }, null, 8, _hoisted_45)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.expense_currency" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_select, _mergeProps({ "model-value": value }, field, {
                    filterable: "",
                    class: "w-full",
                    placeholder: "Укажите валюту"
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currency), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.code
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
          !_unref(values).rus &&
          _unref(values).other_attributes?.amount_of_expenses &&
          Number(_unref(values).other_attributes.amount_of_expenses) > 0
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
              _createElementVNode("div", _hoisted_47, [
                _cache[18] || (_cache[18] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата покупки", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.expense_date
                    }, null, 8, _hoisted_48)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.expense_date" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps({ "model-value": value }, field, {
                    type: "date",
                    placeholder: "Выберите дату",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY"
                  }), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (!_unref(values).rus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _cache[19] || (_cache[19] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Налог в иностранном государстве", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.paid_tax
                    }, null, 8, _hoisted_52)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.paid_tax" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_53, [
                _cache[20] || (_cache[20] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата уплаты налога", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.paid_tax_date
                    }, null, 8, _hoisted_54)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.paid_tax_date" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps({
                    type: "date",
                    placeholder: "Выберите дату",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY",
                    "model-value": value
                  }, field), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(FormItem, { name: "other_attributes.set_property_deduction" }, {
        default: _withCtx(({ field, value }) => [
          (dontHaveAmountOfExpense.value)
            ? (_openBlock(), _createBlock(CustomCheckbox, _mergeProps({
                key: 0,
                value: value
              }, field), {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode(" Применить имущественный вычет ")
                ])),
                _: 2
              }, 1040, ["value"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _cache[33] || (_cache[33] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1)),
    _createElementVNode("div", _hoisted_55, [
      _createElementVNode("div", _hoisted_56, [
        _cache[23] || (_cache[23] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Другая сторона договора", -1)),
        _createVNode(QuestionPopover, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.helpDescriber?.other_side
            }, null, 8, _hoisted_57)
          ]),
          _: 1
        })
      ]),
      (_unref(values).rus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
            _createVNode(FormItem, { name: "physical_person" }, {
              default: _withCtx(({ field, value }) => [
                _createVNode(_component_el_radio_group, _mergeProps(field, { "model-value": value }), {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, { value: false }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("Юридическое лицо")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_radio, { value: true }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("Физическое лицо")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040, ["model-value"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_59, [
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
              (_unref(values).physical_person)
                ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                    _createElementVNode("div", _hoisted_62, [
                      _createElementVNode("div", null, [
                        _cache[26] || (_cache[26] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ФИО", -1)),
                        _createVNode(FormItem, { name: "other_attributes.name" }, {
                          default: _withCtx(({ field, value }) => [
                            _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_63, [
                    _createElementVNode("div", _hoisted_64, [
                      _cache[27] || (_cache[27] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование организации", -1)),
                      _createVNode(FormItem, { name: "other_attributes.name_organization" }, {
                        default: _withCtx(({ field, value }) => [
                          _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_65, [
                      _cache[28] || (_cache[28] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ИНН", -1)),
                      _createVNode(FormItem, { name: "other_attributes.inn" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "123456789012" }), null, 16, ["model-value"]), [
                            [_directive_mask, ['############']]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_66, [
                      _cache[29] || (_cache[29] = _createElementVNode("p", { class: "font-semibold mb-1" }, "КПП", -1)),
                      _createVNode(FormItem, { name: "other_attributes.kpp" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "123456789" }), null, 16, ["model-value"]), [
                            [_directive_mask, ['#########']]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", null, [
                      _cache[30] || (_cache[30] = _createElementVNode("p", { class: "mb-1 font-semibold" }, "ОКТМО", -1)),
                      _createVNode(FormItem, { name: "other_attributes.oktmo" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "12345678" }), null, 16, ["model-value"]), [
                            [_directive_mask, ['########']]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_67, [
              _createElementVNode("div", null, [
                _cache[31] || (_cache[31] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование", -1)),
                _createVNode(FormItem, { name: "other_attributes.name_income" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ]))
      ])
    ])
  ], 64))
}
}

})