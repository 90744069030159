import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_6 = { class: "flex items-center justify-center" }

import { ref } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'

export interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DiscountRequest',
  props: {
    id: {}
  },
  setup(__props: any, { expose: __expose }) {

const props = __props
const { sendDiscountRequest, dropDiscountRequest, getInvoice } = useInvoiceRepository()
const visible = ref(false)
const { width } = useWindowWidth()
const form = ref<{ [key: string]: string | boolean }>({
  loss: false,
  portfolio_volume: false,
  relocate: false,
  other: false,
  comment: '',
})
const openModal = () => {
  visible.value = true
}
const closeModal = () => {
  visible.value = false
}
const dialogWidth = () => {
  if (width.value < 1200) {
    if (width.value > 991) {
      return '40%'
    }
  }
  if (width.value < 992) {
    if (width.value > 767) {
      return '60%'
    }
  }
  if (width.value < 767) {
    return '90%'
  }
  return '36%'
}
const clearForm = () => {
  Object.keys(form.value).filter((item) => {
    if (item === 'comment') {
      form.value[item] = ''
    } else {
      form.value[item] = false
    }
  })
}
const request = () => {
  const body = {
    form_id: 'ng_discount_request',
    host: process.env.VUE_APP_SERVER_HOST,
    invoice_id: props.id,
    ...form.value,
  }
  const validFields = Object.keys(form.value).reduce((acc: string[], key: string) => {
    if (form.value[key]) {
      acc.push(key)
    }
    return acc
  }, [])
  if (validFields.length > 0) {
    sendDiscountRequest(body)
      .then(() => {
        clearForm()
        closeModal()
        ElNotification({
          title: 'Заявка принята',
          message: 'наши специалисты скоро свяжутся с вами',
          type: 'success',
        })
      })
      .catch((err) => {
        clearForm()
        closeModal()
        ElNotification({
          title: `Произошла ошибка`,
          message: 'Попробуйте отправить заявку еще раз.',
          type: 'error',
        })
      })
      .then(() => {
        dropDiscountRequest(props.id, { discount_request: true })
          .then(() => {
            getInvoice(props.id)
          })
          .catch((err) => {
            ElNotification({
              title: `Ошибка`,
              message: `При сохранении статуса отправки сообщения о скидке ${err}`,
              type: 'error',
            })
          })
      })
  } else {
    ElNotification({
      title: `Ошибка`,
      message: 'Вы не выбрали ни одного пункта',
      type: 'error',
    })
  }
}
__expose({ openModal })

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_UploadFilled = _resolveComponent("UploadFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: visible.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((visible).value = $event)),
    width: dialogWidth(),
    center: ""
  }, {
    default: _withCtx(() => [
      _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "mb-4" }, "Дарим дополнительную скидку, если у вас:", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_checkbox, {
          modelValue: form.value.loss,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.loss) = $event)),
          label: "Убыток в 2023 году"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_checkbox, {
          modelValue: form.value.portfolio_volume,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.portfolio_volume) = $event)),
          label: "Размер портфеля до $50 000"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_checkbox, {
          modelValue: form.value.relocate,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.relocate) = $event)),
          label: "Переезд в другую страну"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_checkbox, {
          modelValue: form.value.other,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.other) = $event)),
          label: "Опишите вашу ситуацию"
        }, null, 8, ["modelValue"])
      ]),
      (form.value.other)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-bold font-color--deep-gray mb-4" }, "Комментарий", -1)),
            _createVNode(_component_el_input, {
              size: "large",
              modelValue: form.value.comment,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.comment) = $event)),
              type: "textarea",
              class: "textarea-crunch",
              placeholder: "Опишите вашу ситуацию",
              autosize: { minRows: 2, maxRows: 4 }
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_button, {
          onClick: request,
          size: "large",
          type: "primary",
          class: "button-main"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { class: "mr-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_UploadFilled)
              ]),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createTextVNode(" Хочу "))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "width"]))
}
}

})