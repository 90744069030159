import invoices from './Account/invoices'
import { isAuth } from './middlewares/isAuth'
import { RouteRecordRaw } from 'vue-router'
import InvoiceView from '@/views/account/invoice/InvoiceView.vue'
const AccountHome = () => import('../views/account/AccountHome.vue')
const CustomerShow = () => import('../views/account/CustomerShow.vue')

const routes: RouteRecordRaw[] = [
  {
    name: 'account home',
    path: '',
    component: AccountHome,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    path: 'invoices/:id',
    component: InvoiceView,
    children: [...invoices],
  },
  {
    name: 'customer show',
    path: 'user_show/:id',
    component: CustomerShow,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
]
export default routes
