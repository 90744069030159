import Promocode from '@/router/Admin/Promocode'
// import BrokerInfo from '@/router/Admin/BrokerInfo'
import OksmInfo from '@/router/Admin/OksmInfo'
import manager from '@/router/Account/manager+'
import taxPlan from '@/router/Admin/taxPlan'
import { RouteRecordRaw } from 'vue-router'
import { CheckAdminRoleMiddleware } from './middlewares/CheckAdminRoleMiddleware'
import { isAuth } from '@/router/middlewares/isAuth'
const UserShow = () => import('@/views/admin/UserShow.vue')

const AdminRouter: RouteRecordRaw[] = [
  // ...BrokerInfo,
  ...Promocode,
  ...OksmInfo,
  ...manager,
  ...taxPlan,
  {
    path: 'users/:userId',
    name: 'user show',
    props: true,
    component: UserShow,
    meta: {
      middleware: [isAuth, CheckAdminRoleMiddleware],
    },
  },
]
export default AdminRouter
