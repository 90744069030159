<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'
import useColors from '@/07-Shared/model/useColors'

const { colors, isDark } = useColors()
const { screenLessValue } = useWindowWidth()
interface IProps {
  color?: string
  mobile?: boolean
  width?: number
}
defineProps<IProps>()
</script>

<template>
  <el-popover
    :placement="screenLessValue(1200) ? 'top' : 'right'"
    :trigger="screenLessValue(1200) ? 'click' : 'hover'"
    class="margin-h--left-s"
    :width="width || 300"
  >
    <template #reference>
      <span>
        <el-icon
          :color="isDark ? colors.dark.ng_light_gray : colors.light.ng_deep_gray"
          size="large"
          class="margin-h--left-s cursor-point"
          ><QuestionFilled
        /></el-icon>
      </span>
    </template>
    <template #default>
      <slot></slot>
    </template>
  </el-popover>
</template>

<style scoped lang="sass"></style>
