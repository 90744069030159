import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag-next'
import { router } from './router/router'
import store from './store/store'
import GAuth from './plugins/GoogleAuth'
import JsonViewer from 'vue3-json-viewer'
import GlobalComponentRegistration from './GlobalComponentRegistration'
import ElementPlusPlugin from './plugins/ElementPlusPlugin'
import NotificationsPlugin from './plugins/NotificationsPlugin'
import './style/element/index.scss'
import './style/style.sass'
import VueTheMask from 'vue-the-mask'
import 'vue3-json-viewer/dist/index.css'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './plugins/yup'
import formEmitterPlugin from './plugins/formEmitterPlugin'
import './style/element/dark.scss'

const app = createApp(App)
  .use(store)
  .use(VueGtag, {
    property: { id: 'G-VB3GNSWQ03' },
  })
  .use(router)
  .use(NotificationsPlugin)
  .use(VueTheMask)
  .use(JsonViewer)
  .use(ElementPlusPlugin)
  .use(GAuth, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    scope: 'email',
    prompt: 'select_account',
    fetch_basic_profile: false,
  })
  .use(formEmitterPlugin)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
GlobalComponentRegistration(app)

app.mount('#app')
