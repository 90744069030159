<script lang="ts" setup>
import useColors from '@/07-Shared/model/useColors'
import loaderLight from '@/assets/loader.gif'
const msg = 'Магические операции, подождите пожалуйста'
const { isDark } = useColors()
</script>

<template>
  <div class="flex flex-col justify-center items-center mx-auto my-auto max-w-7xl">
    <div v-if="isDark" class="dark-guru-loader m-12" />
    <img v-else :src="loaderLight" alt="load_gif" width="200" />
    <h4>{{ msg }}</h4>
  </div>
</template>

<style scoped lang="sass"></style>
