import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

interface IProps {
  mobileScreen?: boolean
  showDelButton?: boolean
}

interface IEmits {
  (e: 'toggle'): void
  (e: 'remove'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditSaveCardButtonGroup',
  props: {
    mobileScreen: { type: Boolean, default: false },
    showDelButton: { type: Boolean }
  },
  emits: ["toggle", "remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_EditPen = _resolveComponent("EditPen")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createBlock(_component_el_button_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_tooltip, {
        class: "item",
        effect: "dark",
        content: "Редактировать",
        placement: "top-start"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_EditPen)
                ]),
                _: 1
              }),
              (_ctx.mobileScreen)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Редактировать"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_tooltip, {
        class: "item",
        effect: "dark",
        content: "Удалить",
        placement: "top-start"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove'))),
            type: "danger"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Delete)
                ]),
                _: 1
              }),
              (_ctx.mobileScreen)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Удалить"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})