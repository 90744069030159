import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_4 = {
  key: 1,
  class: "font-color--red"
}
const _hoisted_5 = { class: "flex items-center justify-end" }

import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { computed, UnwrapNestedRefs } from 'vue'
import usePropertyDeductionDescriber from '@/04-Widgets/DeductionWidget/model/usePropertyDeductionDescriber'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}
interface IEmits {
  (e: 'remove'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PropertyCard',
  props: {
    item: {}
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { property_type, agreement_type } = usePropertyDeductionDescriber()
const property = computed(
  () => property_type.find((i: any) => i.type === props.item.item?.other_attributes?.property_type)?.label || '-'
)
const agreement = computed(
  () => agreement_type.find((i: any) => i.type === props.item.item?.other_attributes?.agreement_type)?.label || '-'
)

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (props.item)
    ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        align: "middle",
        gutter: 10
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { lg: 4 }, {
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--gray mb-2" }, "Приобретение", -1)),
              _createElementVNode("p", null, _toDisplayString(agreement.value || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 7 }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--gray mb-2" }, "Наименование", -1)),
              _createElementVNode("p", null, _toDisplayString(property.value), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 3 }, {
            default: _withCtx(() => [
              _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-color--gray mb-2" }, "Дата", -1)),
              _createElementVNode("p", null, _toDisplayString(props.item.item?.other_attributes?.date || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 3 }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "font-color--gray mb-2" }, "Сумма", -1)),
              _createElementVNode("p", null, _toDisplayString(props.item.item?.sum || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 3 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, [
                  (props.item?.valueFromServer && !props.item.fieldWasEdited)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Вычет сохранен"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, "Вычет не сохранен"))
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 4 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(EditSaveCardButtonGroup, {
                  onToggle: _ctx.item.toggleEdit,
                  onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove')))
                }, null, 8, ["onToggle"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})