import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "height"]

import { ref, computed } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { VideoPlay } from '@element-plus/icons-vue'

interface IProps {
  showButton?: boolean
  videoLink: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NgInvoiceVideo',
  props: {
    showButton: { type: Boolean, default: false },
    videoLink: {}
  },
  setup(__props: any, { expose: __expose }) {

const props = __props
const videoModalIsOpen = ref(false)
const openVideoModal = () => {
  videoModalIsOpen.value = true
}
const { screenLessValue } = useWindowWidth()
const dialogWidth = computed(() => {
  if (screenLessValue(767)) {
    return {
      frameHeight: 300,
      dialogWidth: '80%',
    }
  }
  if (screenLessValue(1780)) {
    return {
      frameHeight: 600,
      dialogWidth: '80%',
    }
  }
})
__expose({
  openVideoModal,
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.showButton)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            icon: _unref(VideoPlay),
            type: "primary",
            size: "small",
            onClick: openVideoModal,
            class: "subscribe-button"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Как это работает? ")
            ])),
            _: 1
          }, 8, ["icon"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: videoModalIsOpen.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((videoModalIsOpen).value = $event)),
      "destroy-on-close": true,
      width: dialogWidth.value?.dialogWidth,
      title: "Как пользоваться сервисом НДФЛ Гуру"
    }, {
      default: _withCtx(() => [
        _createElementVNode("iframe", {
          src: _ctx.videoLink,
          title: "YouTube video player",
          width: "100%",
          height: dialogWidth.value?.frameHeight,
          allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
          loading: "lazy"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["modelValue", "width"])
  ], 64))
}
}

})