import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex items-center justify-between mb-4" }
const _hoisted_3 = { class: "select" }

import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'
import useTradeOperationsRepository from '@/entityes/invoice/trade_operations/trade_operation.repository'
import CalculatedIncomes from '@/04-Widgets/TradeOperations/ui/CalculatedIncomes.vue'
import { CircleCloseFilled, Refresh } from '@element-plus/icons-vue'
import CommonExpenses from '@/04-Widgets/TradeOperations/ui/CommonExpenses.vue'
import useCommonExpensesRepository from '@/entityes/invoice/trade_operations/сommon_expenses.repository'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import useIssuersRepository from '@/entityes/invoice/trade_operations/issuers.repository'
import IssuersTable from '@/04-Widgets/TradeOperations/ui/IssuersTable.vue'

interface IProps {
  id: string | number
  declarationId: string | number
  showRecalcButton: boolean
}
interface IEmits {
  (e: 'recalculate'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IncomesView',
  props: {
    id: {},
    declarationId: {},
    showRecalcButton: { type: Boolean }
  },
  emits: ["recalculate"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const tradeStore = useTradeOperationsStore()
const { calculatedIncomesInit } = useTradeOperationsRepository()
const { getCommonExpenses } = useCommonExpensesRepository()
const { getIssuers } = useIssuersRepository()
const loading = ref(false)
const activeCalcPage = ref('calcIncome')
function recalcDeclaration() {
  emit('recalculate')
}
const clearAllFiltersAndSearch = () => {
  calculatedIncomesInit(props.id, 1, '', '', '')
  getCommonExpenses(props.declarationId, 1, '', '')
  getIssuers(props.declarationId, 1, '', '')
}
function init() {
  loading.value = true
  calculatedIncomesInit(props.id, 1, '', '', '')
    .then(() => {
      getCommonExpenses(props.declarationId, 1, '', '')
        .then(() => {
          getIssuers(props.declarationId, 1, '', '').catch((err) => {
            ElNotification({
              title: 'Ошибка',
              message: `получения списка эмитентов ${err}`,
              type: 'error',
            })
          })
        })
        .catch((err) => {
          ElNotification({
            title: 'Ошибка',
            message: `получения списка общих расходов ${err}`,
            type: 'error',
          })
        })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
init()

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (loading.value)
    ? (_openBlock(), _createBlock(ClearLoader, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Сделки", -1)),
          _createVNode(_component_el_button_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                class: "box-item",
                effect: "dark",
                content: "Сбросить фильтры",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    loading: loading.value,
                    disabled: !props.showRecalcButton,
                    icon: _unref(CircleCloseFilled),
                    type: "danger",
                    size: "small",
                    onClick: clearAllFiltersAndSearch
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Сбросить фильтры ")
                    ])),
                    _: 1
                  }, 8, ["loading", "disabled", "icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tooltip, {
                class: "box-item",
                effect: "dark",
                content: "Пересчитать на основании таблицы",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "success",
                    onClick: recalcDeclaration,
                    loading: loading.value,
                    disabled: !props.showRecalcButton,
                    icon: _unref(Refresh),
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Пересчитать ")
                    ])),
                    _: 1
                  }, 8, ["loading", "disabled", "icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_tabs, {
            modelValue: activeCalcPage.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeCalcPage).value = $event))
          }, {
            default: _withCtx(() => [
              (!!_unref(tradeStore).calculatedIncomes)
                ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 0,
                    label: "Доходы + Расходы",
                    name: "calcIncome"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CalculatedIncomes, {
                        "calculated-incomes": _unref(tradeStore).calculatedIncomes,
                        okvs: _unref(tradeStore).incomeOkvs,
                        "income-codes": _unref(tradeStore).incomeCodes,
                        id: props.id,
                        "declaration-id": props.declarationId,
                        "t-pages": _unref(tradeStore).incomesPagination.total_pages,
                        "c-page": _unref(tradeStore).incomesPagination.current_page
                      }, null, 8, ["calculated-incomes", "okvs", "income-codes", "id", "declaration-id", "t-pages", "c-page"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!!_unref(tradeStore).calculatedIncomes && !!_unref(tradeStore).commonExpenses)
                ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 1,
                    label: "Общие расходы",
                    name: "commonExpenses"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CommonExpenses, {
                        "declaration-id": props.declarationId,
                        "common-expenses": _unref(tradeStore).commonExpenses,
                        "t-pages": _unref(tradeStore).commonExpensesPagination.total_pages,
                        "c-page": _unref(tradeStore).commonExpensesPagination.current_page
                      }, null, 8, ["declaration-id", "common-expenses", "t-pages", "c-page"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!!_unref(tradeStore).calculatedIncomes && !!_unref(tradeStore).commonExpenses && !!_unref(tradeStore).issuers)
                ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 2,
                    label: "Эмитенты",
                    name: "issuers"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(IssuersTable, {
                        "declaration-id": props.declarationId,
                        issuers: _unref(tradeStore).issuers,
                        "t-pages": _unref(tradeStore).issuersPagination.total_pages,
                        "c-page": _unref(tradeStore).issuersPagination.current_page,
                        ref: "Issuers"
                      }, null, 8, ["declaration-id", "issuers", "t-pages", "c-page"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]))
}
}

})