<template>
  <el-row align="middle">
    <el-col :xs="24" :sm="8" :md="8" :lg="12" class="margin-v-xs">
      <h3 class="font-color--deep-gray mb-4">Загруженные справки</h3>
      <div v-if="loadedFileSize > 0">
        <span v-for="(value, index) in item.item.two_ndfl_files" :key="index" class="mr-2">
          {{ value?.name }}
        </span>
      </div>
      <div v-else>
        <p>Нет загруженных справок</p>
      </div>
    </el-col>
    <el-col :xs="24" :sm="8" :md="8" :lg="8" class="mb-4">
      <p class="font-weight--extra">
        <span v-if="item?.valueFromServer && !item.fieldWasEdited" class="font-color--green">Доход сохранен</span>
        <span v-else class="font-color--red">Доход не сохранен</span>
      </p>
    </el-col>
    <el-col
      :xs="24"
      :sm="{ offset: 0, span: 24 }"
      :md="{ offset: 0, span: 24 }"
      :lg="{ offset: 1, span: 3 }"
      :class="screenLessValue(1200) ? 'margin-top-s' : ''"
    >
      <edit-save-card-button-group @toggle="item.toggleEdit" @remove="$emit('remove')" />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { computed, UnwrapNestedRefs } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'toggle'): void
  (e: 'remove'): void
}
const emit = defineEmits<IEmits>()

const { screenLessValue } = useWindowWidth()

const loadedFileSize = computed<number>(() => {
  return (
    (props.item.item?.two_ndfl_files?.length || 0) +
    (props.item.item?.other_attributes?.two_ndfl_files?.files?.length || 0)
  )
})
</script>

<style lang="sass" scoped></style>
