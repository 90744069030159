<script lang="ts" setup>
import { ref } from 'vue'

export interface IProps {
  setupValue?: string[] | number[]
  searchLabel?: string
  element?: string
}
export interface IEmits {
  (e: 'changeSearchEvent', value: string): void
  (e: 'searchEvent', value: string | number): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const model = ref('')
const searchString = ref('')
function filter(value: string) {
  if (value) {
    const str = `&q[${props.searchLabel}_eq]=${value}`
    emit('searchEvent', str)
  } else {
    emit('searchEvent', '')
  }
}
function sort(value: string) {
  const str = `&q[s]=${props.searchLabel} ${value}`
  emit('searchEvent', str)
}
function search(value: string) {
  if (value === '') {
    emit('searchEvent', '')
  } else {
    const str = `&q[${props.searchLabel}_cont]=${value}`
    emit('searchEvent', str)
  }
}
</script>

<template>
  <div v-if="element === 'filter'">
    <el-select v-model="model" filterable @change="filter" size="small">
      <el-option value="" label="Очистить" />
      <el-option v-for="item in setupValue" :key="item" :label="item" :value="`${item}`" />
    </el-select>
  </div>
  <div v-if="element === 'date_filter'">
    <el-date-picker
      v-model="model"
      type="datetime"
      format="YYYY/MM/DD"
      @change="filter"
      size="small"
      style="max-width: 150px"
    />
  </div>
  <div v-if="element === 'sort'">
    <div class="flex-box flex-box--center">
      <el-icon @click="sort('asc')" size="large" class="button--text-hover"><CaretTop /></el-icon>
    </div>
    <div class="flex-box flex-box--center">
      <el-icon @click="sort('desc')" size="large" class="button--text-hover"><CaretBottom /></el-icon>
    </div>
  </div>
  <div v-if="element === 'search'">
    <el-input v-model="searchString" @input="search" class="mr-2" size="small" />
  </div>
</template>

<style lang="sass" scoped></style>
