<script setup lang="ts">
import { computed } from 'vue'
import useColors from '@/07-Shared/model/useColors'
import { Discount, Bell } from '@element-plus/icons-vue'

interface IProps {
  ototimer: number
}

const props = defineProps<IProps>()
const timerOTO = computed(() => props?.ototimer || 0)
const endOto = computed(() => {
  const date = new Date(Date.now() + timerOTO.value * 1000)
  const day = Number(date.getDate()) > 9 ? date.getDate() : `0${date.getDate()}`
  return `${day}-${date.getMonth() + 1}-${date.getFullYear()}`
})
const { colors, isDark } = useColors()
</script>

<template>
  <div class="bounce-in-right" v-if="timerOTO !== 0">
    <div class="border font-weight--extra p-2" :class="isDark ? 'dark-oto' : 'light-oto'">
      <div class="flex justify-between">
        <p>Скидка 5%</p>
        <el-icon v-if="timerOTO * 1000 < 24 * 3600 * 1000" size="20" class="icon-shake"><Bell /></el-icon>
        <el-icon v-else size="20" class="icon-shake"><Discount /></el-icon>
      </div>
      <div class="flex items-center">
        <p class="mr-1 word">Действительно до:</p>
        <p>{{ endOto }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">

@keyframes BeforeElementAnimation
  0%
    transform: translateX(-100px) skewX(-315deg)
  100%
    transform: translateX(300px) skewX(-15deg)

@keyframes AfterElementAnimation
  0%
    transform: translateX(-100px) skewX(-15deg)
  100%
    transform: translateX(300px) skewX(-15deg)

@keyframes tilt-shaking
  0%
    transform: rotate(0deg)
  25%
    transform: rotate(5deg)
  50%
    transform: rotate(0eg)
  75%
    transform: rotate(-5deg)
  100%
    transform: rotate(0deg)

.bounce-in-right
  -webkit-animation: bounce-in-right 1.1s both
  animation: bounce-in-right 1.1s both

@-webkit-keyframes bounce-in-right
  0%
    -webkit-transform: translateX(600px)
     transform: translateX(600px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in
    opacity: 0
  38%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out
    opacity: 1

  55%
    -webkit-transform: translateX(68px)
    transform: translateX(68px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in

  72%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out

  81%
    -webkit-transform: translateX(32px)
    transform: translateX(32px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in

  90%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out

  95%
    -webkit-transform: translateX(8px)
    transform: translateX(8px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in

  100%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out

@keyframes bounce-in-right
  0%
    -webkit-transform: translateX(600px)
    transform: translateX(600px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in
    opacity: 0

  38%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out
    opacity: 1

  55%
    -webkit-transform: translateX(68px)
    transform: translateX(68px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in

  72%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out

  81%
    -webkit-transform: translateX(32px)
    transform: translateX(32px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in

  90%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out

  95%
    -webkit-transform: translateX(8px)
    transform: translateX(8px)
    -webkit-animation-timing-function: ease-in
    animation-timing-function: ease-in

  100%
    -webkit-transform: translateX(0)
    transform: translateX(0)
    -webkit-animation-timing-function: ease-out
    animation-timing-function: ease-out

.light-oto
  position: relative
  overflow: hidden
  background-image: linear-gradient(150deg, #FF9A9D, #E6A23C)
  background-size: 100% 120%
  //animation: TransitioningBackground 1s ease
  transition: 0.6s
  border: none

  &::before,
  &::after
    content: ''
    display: block
    position: absolute
    height: 100%
    top: 0

  &::before
    filter: blur(30px)
    transform: translateX(-100px) skewX(-315deg)
    animation: BeforeElementAnimation 2s ease-in-out infinite alternate

  &::after
    background: rgba(255, 255, 255, 0.2)
    width: 30px
    filter: blur(5px)
    transform: translateX(-100px) skewX(-15deg)
    animation: AfterElementAnimation 3s ease-in-out infinite alternate

.dark-oto
  position: relative
  overflow: hidden
  background-image: linear-gradient(150deg, #BB468A, #E6A23C)
  background-size: 100% 170%
  //animation: TransitioningBackground 1s ease
  transition: 0.6s
  border: none

  &::before,
  &::after
    content: ''
    display: block
    position: absolute
    height: 100%
    top: 0

  &::before
    filter: blur(30px)
    transform: translateX(-100px) skewX(-315deg)
    animation: BeforeElementAnimation 2s ease-in-out infinite alternate

  &::after
    background: rgba(255, 255, 255, 0.2)
    width: 30px
    filter: blur(5px)
    transform: translateX(-100px) skewX(-15deg)
    animation: AfterElementAnimation 3s ease-in-out infinite alternate


.icon-shake
  animation: tilt-shaking 0.3s infinite
</style>
