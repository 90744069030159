<script lang="ts" setup>
import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import { UnwrapNestedRefs, watch } from 'vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { bool, number, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import Checkbox from '@/07-Shared/ui/Checkbox.vue'
import usePropertyDeductionDescriber from '@/04-Widgets/DeductionWidget/model/usePropertyDeductionDescriber'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
  helpDescriber: any
  admin: any
  goodId: string | number
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()
const { agreement_type, property_type, owner_type, purchase_type } = usePropertyDeductionDescriber()
const cadastr = ref(false)
const baseRefund = ref(true)
const mortgageRefund = ref(false)

const schema = object({
  sum: number().test('sum-test', 'Поле является обязательным', (value) => {
    if (baseRefund.value) {
      return string().required().isValidSync(value)
    }
    return true
  }),
  other_attributes: object({
    mortgage_sum: number().test('mortgage-test', 'Поле является обязательным', (value) => {
      if (mortgageRefund.value) {
        return string().required().isValidSync(value)
      }
      return true
    }),
    last_year_build_deduction: string().test(
      'last_year_build_deduction-test',
      'Поле является обязательным',
      (value) => {
        if (baseRefund.value) {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    last_year_mortgage_deduction: string().test(
      'last_year_mortgage_deduction-test',
      'Поле является обязательным',
      (value) => {
        if (mortgageRefund.value) {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    agreement_type: string().required('Поле является обязательным'),
    date_deed_flat: string().test('date_deed_flat_test', 'Поле является обязательным', (value, context) => {
      const a_type = context.from?.[1].value.agreement_type
      if (a_type === 'investment') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    date_registration_house: string().test(
      'date_registration_house_test',
      'Поле является обязательным',
      (value, context) => {
        const a_type = context.from?.[1].value.agreement_type
        if (a_type === 'buy_sell') {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    date_registration_land: string().test(
      'date_registration_house_test',
      'Поле является обязательным',
      (value, context) => {
        const a_type = context.from?.[1].value.agreement_type
        const p_type = [
          'land_individual_construction',
          'land_with_purchased_residential',
          'residential_building_with_land',
        ].includes(context.from?.[1].value.real_estate_type)
        if (a_type === 'buy_sell' && p_type) {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    real_estate_type: string().required('Поле является обязательным'),
    purchase_type: string(),
    owner_type: string().required('Поле является обязательным'),
    cadastr_number: string().test('cadatr-number-test', 'Поле является обязательным', (value) => {
      if (cadastr.value) {
        return string().required().isValidSync(value)
      }
      return true
    }),
    address: string().required('Поле является обязательным'),
    simplified_build: bool(),
    simplified_mortgage: bool(),
  }),
})
const validationSchema = toTypedSchema(schema)
const { values, validate, setValues } = useForm({
  validationSchema,
  initialValues: Object.assign(
    {
      other_attributes: {
        purchase_type: '',
        simplified_build: false,
        simplified_mortgage: false,
      },
    },
    {
      ...props.item.item,
    }
  ),
  validateOnMount: true,
})

function checkPropertySum() {
  if (values.sum && values.sum > 2000000) {
    ElMessageBox({
      title: 'Вычет по покупке или строительства недвижимости',
      message: 'Получить вычет можно только с суммы не более чем 2 000 000 ₽',
      type: 'success',
      confirmButtonText: 'Понятно',
    }).then(() => {
      setValues({
        sum: 2000000,
      })
    })
  }
}
async function prepareItem() {
  const valid = (await validate()).valid
  emit('update-item', { ...values, valid })
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

watch(baseRefund, (val) => {
  if (!val && !mortgageRefund.value) baseRefund.value = true
})
</script>

<template>
  <div>
    <div class="p-4">
      <p class="font-semibold mb-1">Тип вычета</p>
      <div class="flex items-center">
        <div class="mr-4">
          <Checkbox v-model:value="baseRefund"> Основной имущественный </Checkbox>
        </div>
        <div>
          <Checkbox v-model:value="mortgageRefund"> Проценты по ипотеке </Checkbox>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap mb-4 px-4">
      <div class="mr-2">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Наименование объекта</p>
          <question-popover>
            <div v-html="helpDescriber?.real_estate_type" />
          </question-popover>
        </div>
        <form-item name="other_attributes.real_estate_type" v-slot="{ value, onBlur, onInput }">
          <el-select
            placeholder="Выберите наименование объекта"
            class="min-w-[220px]"
            :model-value="value"
            @update:model-value="onInput"
            @blur="onBlur"
            filterable
          >
            <el-option v-for="item in property_type" :key="item.type" :value="item.type" :label="item.label" />
          </el-select>
        </form-item>
      </div>
      <div class="mr-2">
        <div class="flex items-center mb-1.5">
          <p class="font-semibold mr-1">Способ приобретения</p>
        </div>
        <form-item name="other_attributes.agreement_type" v-slot="{ value, onBlur, onInput }">
          <el-select
            placeholder="Выберите способ приобретения"
            class="min-w-[220px]"
            :model-value="value"
            @update:model-value="onInput"
            @blur="onBlur"
            filterable
          >
            <el-option v-for="item in agreement_type" :key="item.type" :value="item.type" :label="item.label" />
          </el-select>
        </form-item>
      </div>
      <div>
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Признак налогоплательщика</p>
          <question-popover>
            <div v-html="helpDescriber?.owner_type" />
          </question-popover>
        </div>
        <form-item name="other_attributes.owner_type" v-slot="{ value, onBlur, onInput }">
          <el-select
            placeholder="Выберите признак налогоплательщика"
            class="min-w-[220px]"
            :model-value="value"
            @update:model-value="onInput"
            @blur="onBlur"
            filterable
          >
            <el-option v-for="item in owner_type" :key="item.type" :value="item.type" :label="item.label" />
          </el-select>
        </form-item>
      </div>
      <div
        class="mb-4 px-4"
        v-if="
          values.other_attributes?.real_estate_type === 'residential' ||
          values.other_attributes?.real_estate_type === 'residential_building_with_land'
        "
      >
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Способ приобретения дома</p>
          <question-popover>
            <div v-html="helpDescriber?.estimate" />
          </question-popover>
        </div>
        <FormItem name="other_attributes.purchase_type" v-slot="{ value, onInput }">
          <el-select
            placeholder="Выберите способ приобретения дома"
            class="min-w-[220px]"
            :model-value="value"
            @update:model-value="onInput"
            filterable
          >
            <el-option v-for="item in purchase_type" :key="item.type" :value="item.type" :label="item.label" />
          </el-select>
        </FormItem>
      </div>
    </div>
    <div class="bg-gray p-4">
      <div class="mb-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Место нахождения</p>
          <question-popover>
            <div v-html="helpDescriber?.address" />
          </question-popover>
        </div>
        <form-item name="other_attributes.address" v-slot="{ field, value }">
          <el-input v-bind="field" :model-value="value" />
        </form-item>
      </div>
      <div>
        <el-radio-group v-model="cadastr">
          <el-radio :value="true">Кадастровый номер</el-radio>
          <el-radio :value="false">Номер отсутствует</el-radio>
        </el-radio-group>
      </div>
      <div v-if="cadastr">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Кадастровый номер</p>
          <question-popover>
            <div v-html="helpDescriber?.cadastr_number" />
          </question-popover>
        </div>
        <form-item class="flex" name="other_attributes.cadastr_number" v-slot="{ field, value }">
          <el-input v-bind="field" :model-value="value" />
        </form-item>
      </div>
    </div>

    <div class="flex flex-wrap">
      <div class="mr-6 p-4" v-if="values.other_attributes?.agreement_type === 'investment'">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Дата акта о передаче квартиры, комнаты или доли в них</p>
          <question-popover>
            <div v-html="helpDescriber?.date_deed_flat" />
          </question-popover>
        </div>
        <form-item name="other_attributes.date_deed_flat" v-slot="{ field, value }">
          <el-date-picker
            v-bind="field"
            :model-value="value"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            class="mr-2 min-w-[300px]"
          />
        </form-item>
      </div>
      <div class="mr-6 p-4" v-if="values.other_attributes?.agreement_type === 'buy_sell'">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Дата регистрации права собственности на жилой дом, квартиру, комнату</p>
          <question-popover>
            <div v-html="helpDescriber?.date_registration_house" />
          </question-popover>
        </div>
        <form-item name="other_attributes.date_registration_house" v-slot="{ field, value }">
          <el-date-picker
            v-bind="field"
            :model-value="value"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            class="mr-2 min-w-[300px]"
          />
        </form-item>
      </div>
      <div
        class="p-4"
        v-if="
          values.other_attributes?.agreement_type === 'buy_sell' &&
          [
            'land_individual_construction',
            'land_with_purchased_residential',
            'residential_building_with_land',
          ].includes(values?.other_attributes?.real_estate_type as string)
        "
      >
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Дата регистрации права собственности на земельный участок</p>
          <question-popover>
            <div v-html="helpDescriber?.date_registration_house" />
          </question-popover>
        </div>
        <form-item name="other_attributes.date_registration_land" v-slot="{ field, value }">
          <el-date-picker
            v-bind="field"
            :model-value="value"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            class="mr-2 min-w-[300px]"
          />
        </form-item>
      </div>
    </div>
  </div>
  <div class="bg-gray">
    <div class="p-4" v-if="baseRefund || (baseRefund && mortgageRefund)">
      <p class="font-semibold mb-4">Основной имущественный вычет</p>
      <div class="flex flex-wrap mb-4">
        <div class="mr-8">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Стоимость объекта (доли)</p>
            <question-popover>
              <div v-html="helpDescriber?.sum" />
            </question-popover>
          </div>
          <form-item name="sum" v-slot="{ field, value }">
            <el-input
              v-bind="field"
              :model-value="value"
              placeholder="не более 2 000 000 ₽"
              @focusout="checkPropertySum"
            />
          </form-item>
        </div>
        <div>
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Вычет за предыдущие годы по основному имущественному вычету</p>
            <question-popover>
              <div v-html="helpDescriber?.last_year_build_deduction" />
            </question-popover>
          </div>
          <form-item name="other_attributes.last_year_build_deduction" v-slot="{ field, value }">
            <el-input v-bind="field" :model-value="value" />
          </form-item>
        </div>
      </div>
      <div>
        <form-item name="other_attributes.simplified_build" v-slot="{ field, value }">
          <Checkbox :value="value" v-bind="field">
            Вычет был предоставлен в упрощенном порядке
            <question-popover>
              <div v-html="helpDescriber?.simplified" />
            </question-popover>
          </Checkbox>
        </form-item>
      </div>
    </div>
    <div class="el-divider-purple my-2" v-show="mortgageRefund && baseRefund" />
    <div class="p-4" v-if="mortgageRefund || (baseRefund && mortgageRefund)">
      <p class="font-semibold mb-4">Проценты по ипотеке</p>
      <div class="flex items-center flex-wrap mb-4">
        <div class="mr-8">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Проценты по кредитам за все годы</p>
            <question-popover>
              <div v-html="helpDescriber?.mortgage_sum" />
            </question-popover>
          </div>
          <form-item name="other_attributes.mortgage_sum" v-slot="{ field, value }">
            <el-input v-bind="field" :model-value="value" placeholder="123456" @focusout="checkPropertySum" />
          </form-item>
        </div>
        <div>
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Вычет за предыдущие годы по процентам по ипотеке</p>
            <question-popover>
              <div v-html="helpDescriber?.last_year_mortgage_deduction" />
            </question-popover>
          </div>
          <form-item name="other_attributes.last_year_mortgage_deduction" v-slot="{ field, value }">
            <el-input v-bind="field" :model-value="value" />
          </form-item>
        </div>
      </div>
      <div>
        <form-item name="other_attributes.simplified_mortgage" v-slot="{ field, value }">
          <Checkbox :value="value" v-bind="field">
            Вычет был предоставлен в упрощенном порядке
            <question-popover>
              <div v-html="helpDescriber?.simplified" />
            </question-popover>
          </Checkbox>
        </form-item>
      </div>
    </div>
  </div>
</template>
<style lang="sass"></style>
