import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/delete_icon.svg'


const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = { class: "flex items-center justify-between mb-4" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["onClick"]

import { FileFromServer } from '@/entityes/invoice/file/file.type'
import useTextFilters from '@/plugins/TextFilters'
interface IProps {
  noDelete?: boolean
  files: FileFromServer[]
}
interface IEmit {
  (e: 'delete', fileId: number): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FileFromServerItem',
  props: {
    noDelete: { type: Boolean, default: false },
    files: {}
  },
  emits: ["delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_Files = _resolveComponent("Files")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.files, (file) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'active_file_' + file.id,
        class: "mb-4"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_tooltip, {
            effect: "dark",
            content: file.serverName,
            placement: "left"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_el_icon, {
                  size: "18",
                  class: "mr-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Files)
                  ]),
                  _: 1
                }),
                _createElementVNode("span", null, _toDisplayString(file.name), 1)
              ])
            ]),
            _: 2
          }, 1032, ["content"]),
          (!_ctx.noDelete)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _imports_0,
                alt: "delete_icon",
                onClick: ($event: any) => (emit('delete', file.id)),
                width: "18",
                class: "cursor-point button--text-hover"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_el_divider, { class: "el-divider-80" })
      ]))
    }), 128))
  ]))
}
}

})