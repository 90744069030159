<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ITaxPlan } from '@/entityes/invoice/tax_plan/tax_plan.type'
import { UploadFilled } from '@element-plus/icons-vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm, useFormValues } from 'vee-validate'
import { useTaxPlanRepository } from '@/entityes/invoice/tax_plan/tax_plan.repository'
import { useTaxPlanStore } from '@/entityes/invoice/tax_plan/tax_plan.store'
import DataSourceCard from '@/07-Shared/ui/DataSourceCard.vue'
import { SidebarLoader } from '@/05-Features/SidebarLoader'
import { describers } from '@/06-Entities/File/model/LoaderDescriber'
import fileLoader from '@/06-Entities/File/model/FileLoader'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { ElNotification } from 'element-plus'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'

interface IProps {
  taxPlan: ITaxPlan
  profileId: string | number
}
const props = defineProps<IProps>()
const { updateTaxPlan, saveDataSource, getTaxPlan } = useTaxPlanRepository()
const taxPlanStore = useTaxPlanStore()
const loader = fileLoader(
  describers.find((loader) => loader.serverTag === 'interactive_brokers_files') as ILoaderDescriber
)
const schema = toTypedSchema(
  yup.object({
    name: yup.string(),
  })
)
const form = useForm({
  validationSchema: schema,
  initialValues: {
    name: props.taxPlan.name || '',
  },
})
const formValues = useFormValues()
const timer = ref(0)
const showLoaderSideBar = ref(false)
const activeDataSources = computed(() => props.taxPlan.data_sources?.map((i) => i.id))
const activeDataSourcesModel = ref([...activeDataSources.value])
const dataSourceProcessing = computed(() => ({
  data_ids: activeDataSourcesModel.value,
  name: formValues.value.name,
}))
const isLoading = ref(false)
function updateCurrentTaxPlan() {
  isLoading.value = true
  updateTaxPlan(props.profileId, props.taxPlan.id, { ...dataSourceProcessing.value })
    .then((res) => {
      ElNotification({
        title: `Успешно`,
        message: `обновлен таксплан ${props.taxPlan.id}`,
        type: 'success',
      })
      if (res?.async) {
        timer.value = setTimeout(() => {
          getTaxPlan(props.profileId, props.taxPlan.id)
        }, 5000)
      }
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `обновления таксплана ${props.taxPlan.id}. ${err}`,
        type: 'error',
      })
    })
}
function saveFilesToDataSource() {
  const body = {
    file: loader.localFiles.files[0],
    file_name: loader.localFiles.file_names[0],
    file_type: loader.config.serverTag,
  }
  saveDataSource(props.profileId, body)
    .then(() => {
      ElNotification({
        title: `Файл успешно`,
        message: 'загружен',
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `загрузки файла. ${err}`,
        type: 'error',
      })
    })
}
</script>

<template>
  <router-link class="el-button el-button--info mb-8 mt-4" :to="{ name: 'tax plan index' }"
    >К списку налоговых планов</router-link
  >
  <el-row class="flex items-center step-box mb-8" style="align-items: stretch">
    <el-col :lg="12" class="p-4">
      <div class="flex justify-between items-center mb-4">
        <div>
          <p class="font-bold">{{ taxPlan?.name === '' ? `${taxPlan.id} TaxPlan` : taxPlan?.name }}</p>
        </div>
        <el-form @submit.prevent="updateCurrentTaxPlan" :validation-schema="schema">
          <div class="flex">
            <FormItem name="name" v-slot="{ value, onBlur, onInput }" :label="''">
              <el-input
                :model-value="value"
                type="text"
                name="name"
                size="small"
                @blur="onBlur"
                @input="onInput"
                placeholder="Имя таксплана"
              />
            </FormItem>
            <el-tooltip effect="dark" content="Изменить имя" placement="top">
              <el-button
                style="margin-left: -30px; z-index: 1000; width: 30px"
                type="primary"
                :icon="UploadFilled"
                native-type="submit"
                size="small"
              />
            </el-tooltip>
          </div>
        </el-form>
      </div>
      <div class="mb-8">
        <el-tooltip effect="dark" content="Открыть в инвойсе" placement="top">
          <router-link
            :to="{ name: 'invoice show', params: { id: taxPlan?.invoice_id } }"
            target="_blank"
            class="font-weight--extra"
          >
            {{ taxPlan?.invoice_id }} инвойc
          </router-link>
        </el-tooltip>
      </div>
      <el-button-group>
        <el-button :loading="taxPlan.async" type="primary" @click="showLoaderSideBar = true">Загрузить файл</el-button>
        <el-button :icon="UploadFilled" type="success" @click="updateCurrentTaxPlan" :loading="taxPlan.async">
          Сохранить
        </el-button>
      </el-button-group>
    </el-col>
    <el-col :lg="12" class="bg-gray p-4">
      <p class="font-bold mb-8">Итоговые файлы</p>
      <el-scrollbar v-if="taxPlan.result_files?.length > 0" class="pr-8" :max-height="100" always>
        <download-file-card v-for="file in taxPlan.result_files" :file="file" is-result />
      </el-scrollbar>
    </el-col>
  </el-row>
  <div v-if="taxPlanStore.dataSources" class="flex justify-center items-center">
    <div class="tax_plan_info--data_sources mb-8 bg-gray p-4 border width-100">
      <h3 class="margin-v-s">Доступные Источники</h3>
      <el-checkbox-group
        v-loading="taxPlan.async"
        class="tax_plan_info--datasource_list"
        v-model="activeDataSourcesModel"
      >
        <div v-for="item in taxPlanStore.dataSources" :key="item.id" class="flex">
          <el-checkbox :value="item.id">
            <data-source-card :data-source="item" />
          </el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
  </div>
  <sidebar-loader
    :loader-describer="loader"
    v-model:show-sidebar="showLoaderSideBar"
    @readed-file="loader.onUpdateFilesEvent"
    @delete-local-file="loader.onRemoveLocalFile"
    @delete-server-file="loader.onAddDeleteFile"
    @undo-delete-file="loader.undoDeleteFiles"
    @save-files="saveFilesToDataSource"
  />
</template>

<style lang="sass" scoped></style>
