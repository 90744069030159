import { useRequestService } from '@/use/useRequestService'
import { useProfilesStore } from '@/entityes/profiles/profiles.store'
import { IProfile, IProfilesList } from '@/entityes/profiles/profiles.type'

export function useProfilesRepository() {
  const profilesStore = useProfilesStore()
  const { fetch } = useRequestService()

  async function getUserProfiles(userId: string | number) {
    return new Promise((resolve, reject) => {
      fetch<{ profiles: IProfilesList }>(`/admin/users/${userId}/profiles`)
        .then((res) => {
          profilesStore.userAdminProfiles = res.profiles
          resolve(res)
        })
        .catch((err) => {
          reject(err)
          console.log(err)
        })
    })
  }
  async function createNewProfile(body: { name: Pick<IProfile, 'name'> }): Promise<IProfile> {
    return new Promise((resolve, reject) => {
      fetch<IProfile>(`/profiles`, {
        method: 'post',
        body,
      })
        .then((res: IProfile) => {
          resolve(res)
          console.log(res)
        })
        .catch((err) => {
          reject(err)
          console.log(err)
        })
    })
  }
  async function updateProfile(body: Pick<IProfile, 'id' | 'name'>): Promise<IProfile> {
    return fetch(`/profiles/${body.id}`, {
      method: 'patch',
      body,
    })
  }
  async function deleteProfile(id: number | string): Promise<IProfile> {
    return fetch(`/profiles/${id}`, {
      method: 'delete',
    })
  }
  async function setActiveProfile(id: number | string): Promise<IProfile> {
    return fetch(`/profiles/${id}/set_as_active`, {
      method: 'patch',
    })
  }

  return {
    getUserProfiles,
    createNewProfile,
    updateProfile,
    deleteProfile,
    setActiveProfile,
  }
}
