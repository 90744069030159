<template>
  <div class="el-overlay is-message-box" v-if="visible" @click="closeNotArea">
    <div class="el-overlay-message-box">
      <div class="el-message-box">
        <div class="el-message-box__header">
          <h2>{{ title }}</h2>
          <button class="el-message-box__headerbtn is-plain" @click="close">
            <i class="el-icon el-message-box__close">
              <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
                ></path>
              </svg>
            </i>
          </button>
        </div>
        <div class="el-message-box__content">
          <div>
            <div class="el-message-box__input">
              <el-select v-model="selectValue" class="el-input" placeholder="Выберите дополнительный доход">
                <el-option v-for="item in value" :key="item[valueTag]" :label="item[labelTag]" :value="item[valueTag]">
                  <div class="flex items-center justify-between">
                    <span>{{
                      screenLessValue(767) && item[valueTag] === 'assignment_of_rights'
                        ? subStringText(item[labelTag], 3)
                        : item[labelTag]
                    }}</span>
                    <question-popover :color="'#4b60cecc'" v-if="!screenLessValue(1200)">
                      <div v-for="(desc, index) in onBoarding" :key="index">
                        <div style="word-break: break-word" v-html="desc[item?.tag]"></div>
                      </div>
                    </question-popover>
                  </div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="el-message-box__btns">
          <el-button @click="close" plain>Отменить</el-button>
          <el-button type="primary" @click="ok" v-if="selectValue !== ''">Добавить</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import onBoardingDescriber from '@/describers/OnBoardingDescriber'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import useWindowWidth from '@/use/useWindowWidth'
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  title: string
  labelTag: string
  valueTag: string
  value: Record<string, any>[]
}
const props = withDefaults(defineProps<IProps>(), {
  labelTag: 'label',
  valueTag: 'value',
})
interface IEmits {
  (e: 'select', value: string): void
}
const emit = defineEmits<IEmits>()
const visible = ref(false)
const selectValue = ref<string>('')
const { screenLessValue } = useWindowWidth()
const { subStringText } = useTextFilters()
const { onBoarding } = onBoardingDescriber()
function show() {
  visible.value = true
}
function close() {
  visible.value = false
}
function ok() {
  emit('select', selectValue.value)
  selectValue.value = ''
  close()
}
function closeNotArea(e: MouseEvent) {
  if (((e?.target as HTMLElement)?.firstChild as HTMLElement)?.className === 'el-message-box') visible.value = false
}
defineExpose({
  show,
  close,
})
</script>

<style lang="sass" scoped>
//@media(max-width: 767px)
//  .el-message-box
//    max-width: 300px
</style>
