import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/guru_logo.svg'
import _imports_1 from '../../assets/guru_name.svg'
import _imports_2 from '../../assets/new_logo.svg'


const _hoisted_1 = { class: "left-menu-wrapper bg-gray p-2" }
const _hoisted_2 = { class: "mb-4 flex flex-wrap justify-between flex-col" }
const _hoisted_3 = { class: "flex items-center justify-between mb-4" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_2,
  alt: "logo",
  width: "150"
}
const _hoisted_6 = {
  key: 1,
  class: "cursor-point clickable"
}
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "bg-gray" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "mb-4 clickable flex items-center" }
const _hoisted_11 = ["onClick"]

import useMenuDescription from '@/describers/MenuDescriber'
import { computed, ref } from 'vue'
import NgAdminMenu from '@/07-Shared/ui/NgAdminMenu.vue'
import usePolitic from '@/use/usePolitic'
import { useRouter } from 'vue-router'
import Finder from '@/07-Shared/ui/Finder.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { Menu, PhoneFilled, Sunny, Moon } from '@element-plus/icons-vue'
import { useDark, useToggle } from '@vueuse/core'
import useColors from '@/07-Shared/model/useColors'


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftSideBar',
  setup(__props) {

const { accountMenuDescriptor, phone } = useMenuDescription()
const bottomMenu = computed(() => accountMenuDescriptor.value.filter((_, index) => index !== 0))
const { isAdmin, isPartner } = usePolitic()
const router = useRouter()
const invoiceVideo = ref(null)
const isDark = useDark()
const toggleTheme = useToggle(isDark)
const { colors } = useColors()
// const open = () => {
//   invoiceVideo.value.openVideoModal()
// }

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  (_unref(isDark))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
                        _createElementVNode("img", {
                          class: "mr-2",
                          src: _imports_0,
                          alt: "logo",
                          width: "32"
                        }, null, -1),
                        _createElementVNode("img", {
                          src: _imports_1,
                          class: "logo_name",
                          alt: "logo",
                          width: "100"
                        }, null, -1)
                      ])))
                    : (_openBlock(), _createElementBlock("img", _hoisted_5))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              icon: _unref(isDark) ? _unref(Moon) : _unref(Sunny),
              size: "small",
              type: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleTheme)()))
            }, null, 8, ["icon"])
          ]),
          (_unref(isAdmin) || _unref(isPartner))
            ? (_openBlock(), _createBlock(Finder, { key: 0 }))
            : _createCommentVNode("", true),
          (_unref(isAdmin))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_el_popover, {
                  placement: "right",
                  width: 200,
                  trigger: "hover"
                }, {
                  reference: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_el_icon, {
                        color: _unref(isDark) ? _unref(colors).dark.ng_purple : _unref(colors).light.ng_purple,
                        class: "mr-2",
                        size: 18
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Menu))
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Меню админа", -1))
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(NgAdminMenu)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex items-center clickable cursor-point mb-2", 
          _unref(router).currentRoute.value.name === 'account home' ? 'bg-purple font-color--white font-weight--semi' : ''
        ]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(accountMenuDescriptor)[0].clickAction && _unref(accountMenuDescriptor)[0].clickAction(...args)))
        }, [
          _createVNode(_component_el_icon, {
            size: 18,
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(accountMenuDescriptor)[0].icon)))
            ]),
            _: 1
          }),
          _createElementVNode("p", null, _toDisplayString(_unref(accountMenuDescriptor)[0].title), 1)
        ], 2),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "el-divider-purple mb-4" }, null, -1))
      ]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("div", {
          class: _normalizeClass(_unref(isAdmin) || _unref(isPartner) ? 'left-menu-admin' : 'left-menu-slot')
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: `tel:+${_unref(phone)}`
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_icon, {
                size: 18,
                class: "mr-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(PhoneFilled))
                ]),
                _: 1
              }),
              _createElementVNode("p", null, _toDisplayString(_unref(phone)), 1)
            ])
          ], 8, _hoisted_9)
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "el-divider-purple mb-4" }, null, -1)),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bottomMenu.value, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.title,
              onClick: item.clickAction,
              class: _normalizeClass(["flex items-center cursor-point clickable mb-4", 
            _unref(router).currentRoute.value.name === 'customer show' && index === 0
              ? 'bg-purple font-color--white font-weight--semi'
              : ''
          ])
            }, [
              _createVNode(_component_el_icon, {
                size: 18,
                class: "mr-2"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                ]),
                _: 2
              }, 1024),
              _createElementVNode("p", null, _toDisplayString(item.title), 1)
            ], 10, _hoisted_11))
          }), 128))
        ])
      ])
    ]),
    _createVNode(NgInvoiceVideo, {
      ref_key: "invoiceVideo",
      ref: invoiceVideo,
      "show-button": false,
      "video-link": 'https://www.youtube.com/embed/o3S17yaUvTI?enablejsapi=1'
    }, null, 512)
  ], 64))
}
}

})