<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import PaymentPage from '@/04-Widgets/GoodsSelection/PaymentPage.vue'

interface IProps {
  id: string | number
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const invoiceRepository = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()

function init() {
  return invoiceRepository.getInvoice(props.id).then((invoice) => {
    initAsync(invoice)
  })
}
init()
</script>

<template>
  <payment-page v-if="!!invoiceStore.invoice" :invoice="invoiceStore.invoice" />
</template>

<style scoped lang="sass"></style>
