<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { usePDRepository } from '@/entityes/invoice/personal_data/pd.repository'
import useWindowWidth from '@/use/useWindowWidth'
import usePolitic from '@/use/usePolitic'
import useGtagManager from '@/use/useGtagManager'
import { usePDStore } from '@/entityes/invoice/personal_data/pd.store'
import { IPersonalData } from '@/entityes/invoice/personal_data/pd.type'
import { ElNotification, ElMessageBox } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
const PersonalDataCard = defineAsyncComponent(() => import('./PersonalDataCard'))
const BankAccount = defineAsyncComponent(() => import('@/components/Invoice/Declaration/BankAccount'))

interface IProps {
  id: number | string
}
const props = defineProps<IProps>()
const pdStore = usePDStore()
const { getPersonalData, getCurrentPersonalDataCard, createPersonalData, removePersonalDataCard } = usePDRepository()
const { isAdmin } = usePolitic()
const { event } = useGtagManager()
const noPersonalData = ref(false)
const hasCurrentCard = ref(false)
const { screenLessValue } = useWindowWidth()
const invoiceStore = useInvoiceStore()
const declarationStore = useDeclarationStore()
const emptyPersonalData = {
  id: 0,
  title: 'Новая форма',
  name: '',
  last_name: '',
  patronymic: '',
  tax_inspection_code: '',
  phone: '',
  inn: '',
  birth_date: '',
  birth_place: '',
  passport: '',
  passport_issued: '',
  passport_issued_date: '',
  oktmo: '',
}
const currentTitle = computed(() => pdStore?.currentPD?.title)
const selectCard = ref(currentTitle.value || '')
const loading = ref<boolean>(false)

// получение списка карточек с сервера
function getAllPersonalData() {
  return new Promise((resolve, reject) => {
    getPersonalData(props.id).then(() => {
      getCurrentPersonalDataCard(props.id)
        .then((res) => {
          hasCurrentCard.value = true
          selectCard.value = currentTitle.value
          resolve(res)
        })
        .catch((err) => {
          hasCurrentCard.value = false
          selectCard.value = ''
          reject(err)
        })
    })
  })
}
// Выбрать карточку из списка
function tookPersonalDataItem(id: number | string) {
  pdStore.currentPD = pdStore.pds?.personal_datas.find((i) => i.id === id)
}
// открепление карточки от инвойса
function deleteCurrentCard() {
  removePersonalDataCard(props.id, pdStore?.currentPD?.id).then(() => {
    getAllPersonalData()
  })
}
// сообщение об удалении ПД из инвойса
function showMsgToDeleteCard() {
  if (hasCurrentCard.value && noPersonalData.value) {
    const msg =
      'Вы уже сформировали декларацию с персональными данными. Вы хотите сформировать эту декларацию без персональных данных?'
    ElMessageBox.confirm(msg, 'Warning', {
      confirmButtonText: 'да',
      cancelButtonText: 'нет',
      type: 'warning',
    })
      .then(() => {
        deleteCurrentCard()
        ElNotification({
          title: `Успешно`,
          message: noPersonalData.value
            ? 'декларация сформирована без персональных данных'
            : 'персональные данные привязаны к декларации',
          type: 'success',
        })
        noPersonalData.value = false
      })
      .catch(() => {})
  }
}
// создание карточки
function sendPersonalData(val: IPersonalData) {
  const body = noPersonalData.value ? emptyPersonalData : val
  if (noPersonalData.value) {
  }
  createPersonalData(props.id, body)
    .then(() => {
      getAllPersonalData()
      ElNotification({
        title: noPersonalData.value ? 'Декларация сформирована' : `Персональные данные`,
        message: noPersonalData.value ? 'без персональных данных' : 'успешно привязаны к декларации',
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `во время привязки персональных данных к декларации. Попробуйте еще раз. ${err}`,
        type: 'error',
      })
    })
}

const hasDeclaration = computed(() => !!(invoiceStore.invoice && invoiceStore.invoice.goods.Declaration))

watch(noPersonalData, showMsgToDeleteCard)
// если admin отправлять событие внутреннего траффика
if (isAdmin?.value) {
  event('internal_traffic')
}

function init() {
  loading.value = true
  useInvoiceRepository()
    .getInvoice(props.id)
    .then((res) => {
      useAsyncInvoiceReload()
        .initAsync(res)
        .then(() => {
          if (hasDeclaration.value) {
            useDeclarationRepository().getDeclaration(res?.goods.Declaration)
          }
          getAllPersonalData()
            .catch((err) => {
              console.log(err)
            })
            .finally(() => (loading.value = false))
        })
    })
}
init()
</script>

<template>
  <div class="container-list">
    <h1 class="mb-2">Персональные данные</h1>
    <p class="mb-4">
      Заполните персональные данные, чтобы сформировать документы для загрузки в личный кабинет налоговой.
    </p>
    <clear-loader v-if="loading" />
    <div v-else>
      <div class="mb-8">
        <div class="mb-4">
          <el-checkbox class="mb-4" v-model="noPersonalData" label="Сформировать документы без персональных данных" />
          <div class="p-8 step-box" v-if="noPersonalData">
            <p class="mb-4 font-bold">но тогда вам нужно будет:</p>
            <ul style="list-style-position: inside; list-style-type: disc">
              <li>скачать программу декларация на сайте ФНС</li>
              <li>загрузить в нее проект декларации без личных данных (файл .dcx)</li>
              <li>внести персональные данные</li>
              <li>выгрузить готовую декларацию</li>
            </ul>
          </div>
        </div>
        <el-button
          v-if="noPersonalData"
          @click="sendPersonalData(emptyPersonalData)"
          type="success"
          class="button-main"
          :icon="UploadFilled"
        >
          Сохранить
        </el-button>
        <div class="step-box" v-if="!noPersonalData">
          <div class="p-4 flex bg-gray">
            <div v-if="pdStore.pds?.personal_datas.length > 0">
              <h4 class="mb-4">Выбор сохраненных персональных данных</h4>
              <el-select v-model="selectCard" @change="tookPersonalDataItem" placeholder="Выберите форму">
                <el-option
                  v-for="item in pdStore.pds?.personal_datas"
                  :key="item.id"
                  :value="item.id"
                  :label="item.title"
                />
              </el-select>
            </div>
          </div>
          <component
            :is="PersonalDataCard"
            :item="pdStore.currentPD"
            @send-data-event="sendPersonalData"
            :key="pdStore.currentPD?.id"
            :is-loading="getPersonalData.indicator.value === true"
          />
        </div>
      </div>
      <div v-if="declarationStore?.declaration && isAdmin && hasDeclaration">
        <BankAccount :good-id="declarationStore?.declaration?.id" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
