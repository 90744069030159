<script lang="ts" setup>
import { IAdminFileInfo } from '@/entityes/invoice/file/file.type'
import useTypeWriter from '@/plugins/useTypeWriter'
import { toRefs } from 'vue'

interface IProps {
  file: IAdminFileInfo
}
const props = defineProps<IProps>()
const { file } = toRefs(props)
const { renderString } = useTypeWriter('загрузка...')
</script>

<template>
  <el-tag
    effect="plain"
    class="cursor-point min-h-[60px] card-hover"
    :type="file.is_active ? 'success' : 'danger'"
    size="small"
  >
    <div v-if="file.isLoading" class="flex items-center">
      <el-icon class="el-icon-loading font-weight--extra mr-2" style="vertical-align: middle">
        <Loading />
      </el-icon>
      <p>{{ renderString }}</p>
    </div>
    <div style="font-size: 12px; font-weight: 400" v-else>
      <div class="flex items-center justify-between mb-4">
        <p>{{ file.type.split('::')[1] }}</p>
        <p class="font-bold">{{ file.created_at.split('T')[0] }} {{ file.created_at.split('T')[1].split('.')[0] }}</p>
      </div>
      <p>{{ file.name }}</p>
    </div>
  </el-tag>
</template>

<style scoped lang="sass"></style>
