import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "p-2 bg-gray" }
const _hoisted_2 = {
  class: "p-2 border",
  style: {"font-size":"12px","height":"660px"}
}
const _hoisted_3 = {
  key: 2,
  class: "p-2"
}

import { ref, watch } from 'vue'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { useTChatNotification } from '@/06-Entities/AdminInvoiceRemote/model/socket/useTChatNotification'

interface IEmits {
  (e: 'new', value: boolean): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TBankChat',
  emits: ["new"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const scrollBar = ref<HTMLElement | null>(null)
const { loading, messages, schema, showSidebar, invoiceId, sendMessage, newMsgReceived } =
  useTChatNotification(scrollBar)
watch(newMsgReceived, (value) => {
  emit('new', value)
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showSidebar.value = !_unref(showSidebar))),
      type: "primary",
      size: "small"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("Открыть чат")
      ])),
      _: 1
    }),
    _createVNode(RightSideBar, {
      width: "50%",
      style: {"overflow-y":"auto"},
      title: `Чат по заказу ${_unref(invoiceId)}`,
      modelValue: _unref(showSidebar),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(showSidebar) ? (showSidebar).value = $event : null))
    }, {
      default: _withCtx(() => [
        (_unref(loading) && _unref(messages).length === 0)
          ? (_openBlock(), _createBlock(_component_el_skeleton, {
              key: 0,
              class: "h-28 p-2",
              rows: 5,
              animated: ""
            }))
          : _createCommentVNode("", true),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_scrollbar, {
            ref_key: "scrollBar",
            ref: scrollBar,
            height: "660px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(messages), (msg) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: msg.id,
                    class: _normalizeClass(["flex mb-1 leading-5", msg.author_type === 'Client' ? '' : 'justify-end'])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["p-2 border w-3/6", msg.author_type === 'Client' ? 'bg-purple-light' : 'bg-green'])
                    }, _toDisplayString(msg.text), 3)
                  ], 2))
                }), 128))
              ])
            ]),
            _: 1
          }, 512)
        ])), [
          [_vShow, _unref(messages).length > 0],
          [_directive_loading, _unref(loading)]
        ]),
        (!_unref(loading) && _unref(messages).length === 0)
          ? (_openBlock(), _createBlock(_component_el_empty, {
              key: 1,
              description: "В этом чате пока нет сообщений"
            }))
          : _createCommentVNode("", true),
        (!_unref(loading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_el_form, {
                onSubmit: _withModifiers(_unref(sendMessage), ["prevent"]),
                "validation-schema": _unref(schema),
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, {
                    class: "flex",
                    gutter: 6
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { lg: 14 }, {
                        default: _withCtx(() => [
                          _createVNode(FormItem, {
                            name: "text",
                            label: ''
                          }, {
                            default: _withCtx(({ value, onBlur, onInput }) => [
                              _createVNode(_component_el_input, {
                                "model-value": value,
                                onBlur: onBlur,
                                onInput: onInput,
                                placeholder: "Напишите сообщение",
                                type: "textarea",
                                rows: 4,
                                class: "p-1"
                              }, null, 8, ["model-value", "onBlur", "onInput"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { lg: 4 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            disabled: _unref(loading),
                            loading: _unref(loading),
                            type: "primary",
                            "native-type": "submit"
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Отправить")
                            ])),
                            _: 1
                          }, 8, ["disabled", "loading"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit", "validation-schema"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ], 64))
}
}

})