<script lang="ts" setup>
import { computed, ref } from 'vue'
import { WarningFilled, Message, UserFilled, CircleCheckFilled } from '@element-plus/icons-vue'
import { usePDRepository } from '@/entityes/invoice/personal_data/pd.repository'
import useColors from '@/07-Shared/model/useColors'
interface IProps {
  additional: boolean
  invoiceId: string | number
  goodId: string | number
}
const props = defineProps<IProps>()
const { getCurrentPersonalDataCard } = usePDRepository()
const hasAddIncomes = ref(false)
const hasDeductions = ref(false)
const hasPersonalData = ref(false)
const completed = computed(() => {
  if (props.additional) {
    if (hasAddIncomes.value && hasDeductions.value && hasPersonalData.value) {
      return true
    }
  }
  if (!props.additional) {
    if (hasPersonalData.value) return true
  }
  return false
})
const { colors, isDark } = useColors()
getCurrentPersonalDataCard(props.invoiceId)
  .then(() => {
    hasPersonalData.value = true
  })
  .catch(() => {
    hasPersonalData.value = false
  })
</script>

<template>
  <div class="bg-gray mb-4 p-4 border">
    <p class="mb-4" v-if="!completed">
      <el-icon class="mr-2" :color="isDark ? colors.dark.ng_red : colors.light.ng_error" style="vertical-align: middle">
        <WarningFilled />
      </el-icon>
      <span class="font-color--red font-weight--semi font-color--purple" style="vertical-align: middle">Внимание!</span>
    </p>
    <div v-if="additional">
      <p v-if="(!hasAddIncomes && !hasDeductions) || !hasPersonalData" class="mb-4">
        Для завершения формирования декларации:
      </p>
      <div v-if="!hasAddIncomes && !hasDeductions">
        <p class="mb-4">
          1. Заполните Дополнительные доходы и Вычеты - для этого перейдите в соответствующие разделы, кликнув на них в
          меню слева.
        </p>
        <p class="mb-4">
          Либо нажмите
          <router-link :to="{ name: 'add_incomes', params: { good_id: goodId } }">Дополнительные доходы</router-link>
          или
          <router-link :to="{ name: 'deductions', params: { good_id: goodId } }">Вычеты</router-link>.
        </p>
      </div>
      <div v-if="!hasPersonalData">
        <p class="mb-4">
          2. Заполните Персональные данные, для этого перейдите в соответствующий раздел, кликнув на него в меню слева.
        </p>
        <p>
          Перейти к разделу
          <router-link :to="{ name: 'personal_data', params: { good_id: goodId } }">Персональные данные</router-link>
        </p>
      </div>
    </div>
    <div v-if="!additional">
      <div v-if="!hasPersonalData">
        <p class="mb-4">Для завершения формирования декларации заполните персональные данные в меню слева.</p>
        <p class="mb-4">Или поставьте там галочку “без персональных данных”.</p>
        <p>
          Перейти в раздел
          <router-link class="font-color--purple" :to="{ name: 'personal_data' }">Персональные данные</router-link>
        </p>
      </div>
    </div>
    <div v-if="completed">
      <div class="flex items-center mb-4">
        <CircleCheckFilled
          style="font-weight: 700; width: 1em; height: 1em"
          :color="isDark ? colors.dark.ng_green : colors.light.ng_green"
          class="mr-2"
        />
        <p>Вы заполнили все необходимые разделы</p>
      </div>
      <p class="mb-4">
        <el-icon
          :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple"
          style="vertical-align: middle"
          class="mr-2"
        >
          <Message />
        </el-icon>
        <span style="vertical-align: middle">Мы проверим ваши документы и пришлем письмо с результатом.</span>
      </p>
      <p>
        <el-icon
          :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple"
          style="vertical-align: middle"
          class="mr-2"
        >
          <UserFilled />
        </el-icon>
        <span style="vertical-align: middle">Обычно это занимает 3-7 дней.</span>
      </p>
    </div>
  </div>
</template>

<style scoped></style>
