<script lang="ts" setup>
import useTextFilters from '@/plugins/TextFilters'
import { FileFromServer } from '../file.type'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  files: FileFromServer[]
}

const props = defineProps<IProps>()
const { subStringFileName } = useTextFilters()
const { colors, isDark } = useColors()
interface IEmits {
  (e: 'deleteFile', index: number): void
}

const emit = defineEmits<IEmits>()
</script>

<template>
  <div v-for="file in props.files" :key="'file_' + file" class="mb-4">
    <div class="flex items-center justify-between pl-6">
      <div class="flex items-center justify-between">
        <el-icon :color="isDark ? colors.dark.ng_red : colors.light.ng_error" size="large" class="mr-2">
          <CircleCloseFilled />
        </el-icon>
        <span class="text-strike">{{ file.name }}</span>
      </div>
      <img
        class="button--text-hover delete-icon"
        @click="emit('deleteFile', file?.id)"
        src="@/assets/delete_icon.svg"
        alt="close_icon"
        width="18"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
