import { computed } from 'vue'
import { useRouter } from 'vue-router'
import useTextFilters from '../plugins/TextFilters'
import usePolitic from '../use/usePolitic'
import { UserFilled, Tickets, CaretLeft, Discount, Avatar } from '@element-plus/icons-vue'
// import TaxPlanIndex from '@/views/account/TaxPlanList/TaxPlanIndex.vue'
import { useUserRepository } from '@/entityes/user/user.repository'
import { useUserStore } from '@/entityes/user/userStore'
import { useServiceStore } from '@/pinia/serviceStore'

export default function useMenuDescription() {
  const userStore = useUserStore()
  const serviceStore = useServiceStore()
  const router = useRouter()
  const isLoginRule = computed(() => userStore.isAuth)
  const isNotLoginRule = computed(() => !isLoginRule.value)
  const { sendRequestChangePassword } = useUserRepository()
  const { subStringFileName } = useTextFilters()
  const user = computed(() => userStore.user)
  const clipEmail = computed(() => subStringFileName(user.value?.email || '', 30))
  const screenLessValue = computed(() => {
    return window.innerWidth < 1200
  })
  const { isAdmin } = usePolitic()
  const phone = '7(495)414-28-98'

  const accountMenu = [
    // {
    //   title: user.value ? clipEmail?.value : '',
    //   icon: UserFilled,
    //   clickAction: () => {},
    //   show_rule: [screenLessValue],
    // },
    {
      title: 'Список заказов',
      icon: Tickets,
      clickAction: () => {
        redirectToHome()
      },
      show_rule: [],
    },
    {
      title: 'Мой профиль',
      icon: Avatar,
      clickAction: () => {
        redirectToUserShow()
      },
      show_rule: [],
    },
    {
      title: 'Страница Промокодов',
      icon: Discount,
      clickAction: () => {
        redirectToPromocode()
      },
      show_rule: [isAdmin, screenLessValue],
    },
    {
      title: 'Профили',
      icon: UserFilled,
      clickAction: () => {
        redirectToProfiles()
      },
      show_rule: [isAdmin, screenLessValue],
    },
    {
      title: 'Выход',
      icon: CaretLeft,
      clickAction: () => {
        signOutAndCloseMenu()
      },
      show_rule: [],
    },
    // {
    //   title: 'Налоговое Планирование',
    //   icon: TaxPlanIndex,
    //   clickAction: () => {
    //     redirectToTaxPlan()
    //   },
    //   show_rule: [isAdmin, screenLessValue],
    // },
  ]

  const accountMenuDescriptor = computed(() =>
    accountMenu.filter((item) => {
      return item?.show_rule.reduce((acc, elem) => acc && elem.value, <boolean>true)
    })
  )

  const close = () => {
    serviceStore.settingOpen = false
  }
  const toAuth = () => {
    router.push({ name: 'authorization' })
    close()
  }
  const signOutAndCloseMenu = () => {
    userStore.signOut()
    router.push({
      name: 'authorization',
    })
    close()
  }
  const changePass = () => {
    if (userStore.user) sendRequestChangePassword({ email: userStore.user?.email })
    close()
  }
  const redirectToHome = () => {
    router.push({ name: 'account home' })
    close()
  }
  const redirectToPromocode = () => {
    router.push({ name: 'promo index' })
    close()
  }
  const redirectToProfiles = () => {
    router.push({ name: 'profiles' })
    close()
  }
  const redirectToTaxPlan = () => {
    router.push({ name: 'tax plan index' })
    close()
  }
  const redirectToUserShow = () => {
    router.push({
      name: 'customer show',
      params: { id: user?.value?.user_id },
    })
    close()
  }
  return {
    accountMenuDescriptor,
    signOutAndCloseMenu,
    changePass,
    redirectToHome,
    close,
    toAuth,
    isLoginRule,
    isNotLoginRule,
    phone,
    // clipEmail,
  }
}
