<script setup lang="ts">
import { ref, watch, toRef } from 'vue'
import { ElNotification } from 'element-plus'
import { SetUp } from '@element-plus/icons-vue'
import useTextFilters from '@/plugins/TextFilters'
import useWindowWidth from '@/use/useWindowWidth'

interface IProps {
  tariff: string
  hasBucket: boolean
  personalPrice: number
}
interface IEmits {
  (e: 'on-change-tariff', value: string): void
}

const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const currentTariff = toRef(props.tariff || 'base')
const minTariff = ref(false)
const baseTariff = ref(true)
const maxTariff = ref(false)
const showTariffs = ref(false)
const { numberSeparator } = useTextFilters()
const { screenLessValue } = useWindowWidth()

const tariffDescription = [
  {
    tag: 'minimal',
    name: 'Минимальный',
    description: `
      <ul style="list-style-position: outside; list-style-type: disc; line-height: 22px">
        <li class="mb-2">Самостоятельное оформление заказа. Автоматическое формирование документов на основании отчётов брокеров.</li>
        <li class="mb-2">Только полностью самостоятельное внесение дополнительных доходов и вычетов в программе «Декларация» после завершения заказа.</li>
        <li>Нет помощи во время оформления заказа, технической поддержки и сопровождения.</li>
      </ul>
    `,
    model: minTariff,
    class: 'tariff-box-min',
    show: !props.hasBucket,
  },
  {
    tag: 'base',
    name: 'Базовый',
    description: `
      <ul style="list-style-position: outside; list-style-type: disc; line-height: 22px">
        <li class="mb-4">Автоматическое формирование документов на основании отчётов брокеров.</li>
        <li class="mb-4">Возможность дозаказать внесение дополнительных доходов и вычетов в декларацию согласно тарифу.</li>
        <li>Дополнительная ручная проверка документов бухгалтером и сопровождение до окончания камеральной проверки.</li>
      </ul>
    `,
    model: baseTariff,
    class: 'tariff-box-base',
    show: true,
  },
  {
    tag: 'personal',
    name: 'Персональный',
    description: `
      <ul style="list-style-position: outside; list-style-type: disc; line-height: 22px">
        <li class="mb-4">Полностью оформим заказ сами – просто пришлите нам документы удобным вам способом.</li>
        <li>Приоритетная ускоренная подготовка заказа вне очереди и полное сопровождение до окончания камеральной проверки.</li>
      </ul>
    `,
    model: maxTariff,
    class: 'tariff-box-max',
    show: true,
  },
]

function openTariffs() {
  showTariffs.value = true
}
function closeTariffs() {
  emit('on-change-tariff', currentTariff.value)
  showTariffs.value = false
}

watch(currentTariff, () => {
  emit('on-change-tariff', currentTariff.value)
  ElNotification({
    message: `выбран тариф ${tariffDescription.find((i) => i.tag === currentTariff.value)?.name}`,
    type: 'success',
  })
})

function mergeTariff() {
  minTariff.value = props.tariff === 'minimal'
  baseTariff.value = props.tariff === 'base'
  maxTariff.value = props.tariff === 'personal'
}
const selectTariff = (tariff: string) => {
  minTariff.value = tariff === 'minimal'
  baseTariff.value = tariff === 'base'
  maxTariff.value = tariff === 'personal'
  currentTariff.value = tariff
  closeTariffs()
}
openTariffs()
mergeTariff()
</script>

<template>
  <div class="mb-2">
    <div class="flex items-center justify-between">
      <h3 class="mb-2">Тариф</h3>
      <el-tooltip placement="left" content="Изменить тариф">
        <el-button class="mb-2" @click="openTariffs" :icon="SetUp" link type="primary" size="small">Изменить</el-button>
      </el-tooltip>
    </div>
    <div class="flex items-center justify-between">
      <p>{{ tariffDescription.find((i) => i.tag === currentTariff)?.name }}</p>
      <p class="font-weight--extra" v-if="currentTariff === 'personal'">
        ₽ {{ numberSeparator(props.personalPrice, ' ') }}
      </p>
    </div>
  </div>
  <el-dialog
    title="Тарифы"
    v-model="showTariffs"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
    width="80%"
  >
    <div class="grid gap-4" :class="screenLessValue(992) ? 'grid-flow-row grid-cols-1' : 'grid-flow-col grid-rows-1'">
      <div v-for="item in tariffDescription" :key="item.tag">
        <div v-if="item.show" class="tariff-box" :class="currentTariff === item.tag ? item.class : ''">
          <div class="flex items-center mb-4">
            <el-checkbox @change="selectTariff(item.tag)" v-model="item.model.value" :key="currentTariff" />
            <h3 class="ml-2">{{ item.name }}</h3>
          </div>
          <div class="ml-2" style="line-height: 18px" v-html="item.description" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="sass"></style>
