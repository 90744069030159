import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-8" }
const _hoisted_2 = {
  class: "mb-4 text-center",
  style: {"word-break":"break-word","hyphens":"auto"}
}
const _hoisted_3 = { class: "mb-4 flex items-center" }
const _hoisted_4 = { class: "mb-4 flex" }
const _hoisted_5 = { class: "mb-4 flex" }
const _hoisted_6 = { class: "flex items-center" }

import { computed, onBeforeMount, ref } from 'vue'
import useGtagManager from '@/use/useGtagManager'
import { ElNotification } from 'element-plus'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useUserStore } from '@/entityes/user/userStore'
import useColors from '@/07-Shared/model/useColors'
import { SuccessFilled, Message, DocumentCopy } from '@element-plus/icons-vue'

interface IProps {
  invoice: IInvoice
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NGThankUPage',
  props: {
    invoice: {}
  },
  setup(__props: any) {

const props = __props
const thankUPage = computed(() => props.invoice?.thank_you_page)
const userStore = useUserStore()
const payedStatus = computed(() => props.invoice?.status.invoice_status === 'payed')
const { dropThankUPage } = useInvoiceRepository()
const visible = ref(false)
const way = computed(() => Object.keys(props.invoice?.pre_selected_add_incomes).length > 0)

const { event } = useGtagManager()
const { colors, isDark } = useColors()
const openModal = () => {
  visible.value = true
}
const closeModal = () => {
  visible.value = false
}
const chooseYourWay = () => {
  if (userStore.user?.role === 'customer' || userStore.user?.role === 'affiliate') {
    event('purchase', {
      transaction_id: props.invoice.id,
      value: props.invoice.outSum,
      currency: 'RUB',
      goods: [...Object.keys(props.invoice?.goods)],
      promocode: Object.keys(props.invoice).includes('promo'),
    })
  }
  dropThankUPage(props.invoice.id, { has_additional: way.value })
    .then((res) => {
      console.log(res)
      closeModal()
      ElNotification({
        title: 'Спасибо',
        message: 'Можете продолжить оформление документов',
        type: 'success',
      })
    })
    .catch((err) => {
      console.log(err)
      ElNotification({
        title: 'Ошибка',
        message: 'Ошибка выполнения запроса, попробуйте еще раз',
        type: 'error',
      })
    })
}
onBeforeMount(() => {
  if (thankUPage.value && payedStatus.value) openModal()
})

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: visible.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "lock-scroll": true,
    "show-close": false,
    center: "",
    "align-center": true,
    width: "60%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "mb-8" }, "Спасибо за оплату!", -1)),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_icon, {
                size: 20,
                class: "mr-2",
                color: _unref(isDark) ? _unref(colors).dark.ng_purple : _unref(colors).light.ng_purple
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(SuccessFilled))
                ]),
                _: 1
              }, 8, ["color"]),
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "Ваш платеж успешно принят.", -1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_icon, {
                size: 20,
                class: "mr-2",
                color: _unref(isDark) ? _unref(colors).dark.ng_purple : _unref(colors).light.ng_purple
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Message))
                ]),
                _: 1
              }, 8, ["color"]),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "В ближайшее время вы получите письмо о ходе выполнения заказа.", -1))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_icon, {
                size: 20,
                class: "mr-2",
                color: _unref(isDark) ? _unref(colors).dark.ng_purple : _unref(colors).light.ng_purple
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DocumentCopy))
                ]),
                _: 1
              }, 8, ["color"]),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mb-4" }, "Возможно, мы попросим дополнительные документы для успешного формирования заказа.", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_button, {
              onClick: chooseYourWay,
              type: "success",
              class: "button-main"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Продолжить ")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})