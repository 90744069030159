<script lang="ts" setup>
import { ElMessage, ElNotification } from 'element-plus'
import useCopyToClipboard from '@/07-Shared/model/useCopyToClipboard'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { GoodsFilled, CopyDocument, CaretRight } from '@element-plus/icons-vue'
import { useRequestService } from '@/use/useRequestService'

export interface IProps {
  id: string | number
  isAdmin: boolean
  tinkoffLink?: string
  isTinkoffPremium: boolean
}
export interface IEmits {
  (e: 'send', value: any): void
  (e: 'get-link', value: any): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const invoiceStore = useInvoiceStore()
const { skipPayment } = useInvoiceRepository()
const { copyValue } = useCopyToClipboard()
const { isProgress } = useRequestService()

const freeFinishRequest = () => {
  return new Promise((resolve, reject) => {
    skipPayment(props.id)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function copyRequest(link: string) {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      ElNotification({
        title: `Ссылка`,
        message: 'скопирована в буфер обмена',
        type: 'success',
      })
    })
    .catch(() => {
      ElNotification({
        title: `Ошибка`,
        message: 'копирования в буфер обмена',
        type: 'error',
      })
    })
}
function finishPayment() {
  emit('send', {
    method: freeFinishRequest,
  })
}

function getLink() {
  emit('get-link', {
    method: copyRequest,
  })
}

function select() {
  emit('send', {
    method: () => {
      ElMessage({
        message: 'Товары выбраны',
        // closable: true,
      })
    },
  })
}
</script>

<template>
  <div class="flex justify-center items-center p-4 bg-gray border">
    <el-button-group>
      <el-tooltip class="item" effect="dark" content="Сохранить товары в корзине" placement="top-start">
        <el-button :icon="GoodsFilled" plain @click="select"> Товары </el-button>
      </el-tooltip>
      <el-tooltip
        v-if="isTinkoffPremium"
        class="item"
        effect="dark"
        content="Скопировать ссылку на оплату"
        placement="top-start"
      >
        <el-button :icon="CopyDocument" plain @click="copyValue(tinkoffLink || '')"> Ссылка TKassa </el-button>
      </el-tooltip>
      <el-tooltip v-else class="item" effect="dark" content="Скопировать ссылку на оплату" placement="top-start">
        <el-button :icon="CopyDocument" plain @click="getLink"> Ссылка {{ isAdmin ? '' : 'на оплату' }} </el-button>
      </el-tooltip>
      <el-tooltip v-if="isAdmin" class="item" effect="dark" content="Пройти оплату" placement="top-start">
        <el-button :icon="CaretRight" :loading="isProgress" type="primary" @click="finishPayment">
          {{ invoiceStore?.invoice?.async ? 'Рассчитывается' : 'Далее' }}
        </el-button>
      </el-tooltip>
    </el-button-group>
  </div>
</template>

<style lang="sass" scoped></style>
