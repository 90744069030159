import { useRequestService } from '@/use/useRequestService'
import { FetchError } from 'ofetch'
import { IDeclaration, IMissingsList, IShortsList, ITransferList } from './declaration.type'
import { useDeclarationStore } from './declaration.store'
import { ref } from 'vue'
import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'

export function useDeclarationRepository() {
  const { fetch } = useRequestService()
  const declarationStore = useDeclarationStore()
  function getDeclaration(id: string | number) {
    getDeclaration.indicator.value = true
    return new Promise<IDeclaration>((resolve, reject) => {
      fetch<IDeclaration>(`/declarations/${id}`)
        .then((res) => {
          declarationStore.declaration = res
          getDeclaration.indicator.value = false
          resolve(res)
        })
        .catch((err: FetchError) => {
          console.error(err)
          getDeclaration.indicator.value = false
          reject(err)
        })
    })
  }
  getDeclaration.indicator = ref(false)

  function loadResultFilesForDeclaration(id: string | number, body: PreProcessingFiles) {
    return fetch(`admin/invoices/${id}/result_file_upload`, {
      method: 'patch',
      body: {
        result_files: body,
      },
    })
  }

  function getTransfers(declarationId: string | number, page: number) {
    return new Promise((resolve, reject) => {
      fetch<ITransferList>(`declarations/${declarationId}/transfers?page=${page}`)
        .then((res) => {
          declarationStore.tms.transfers = res?.transfers
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  function resolveTransfers(declarationId: string | number, body: any) {
    return fetch(`declarations/${declarationId}/transfers/resolve`, {
      method: 'patch',
      body,
    })
  }

  function getUnclosedShorts(declarationId: string | number, page: number) {
    return new Promise((resolve, reject) => {
      fetch<IShortsList>(`declarations/${declarationId}/unclosed_shorts?page=${page}`)
        .then((res) => {
          declarationStore.tms.unclosed_shorts = res?.unclosed_shorts
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  function resolveUnclosedShorts(declarationId: string | number, body: any) {
    return fetch(`declarations/${declarationId}/unclosed_shorts/resolve`, {
      method: 'patch',
      body,
    })
  }

  function getMissings(declarationId: string | number, page: number) {
    return new Promise((resolve, reject) => {
      fetch<IMissingsList>(`declarations/${declarationId}/missings?page=${page}`)
        .then((res) => {
          declarationStore.tms.missings = res?.missings
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function resolveMissings(declarationId: string | number, body: any) {
    return fetch(`declarations/${declarationId}/missings/resolve`, {
      method: 'patch',
      body,
    })
  }

  function recountDeclarationForCalcIncomes(declarationId: string | number) {
    return fetch(`/admin/declarations/${declarationId}/rerender`, {
      method: 'patch',
    })
  }

  return {
    getDeclaration,
    loadResultFilesForDeclaration,
    getTransfers,
    resolveTransfers,
    getUnclosedShorts,
    resolveUnclosedShorts,
    getMissings,
    resolveMissings,
    recountDeclarationForCalcIncomes,
  }
}
