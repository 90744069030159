import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "container-list" }

import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { ElNotification } from 'element-plus'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import CurrencyDescriber from '@/describers/CurrencyDescriber'
interface IProps {
  id: string | number
  good_id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TMS',
  props: {
    id: {},
    good_id: {}
  },
  setup(__props: any) {

const Transfers = defineAsyncComponent(() => import('@/05-Features/Transfers/ui/Transfers.vue'))
const UnclosedShorts = defineAsyncComponent(() => import('@/05-Features/UnclosedShorts/ui/UnclosedShorts.vue'))
const Missings = defineAsyncComponent(() => import('../../05-Features/Missings/ui/Missings.vue'))

const props = __props
const invoiceRepository = useInvoiceRepository()
const declarationStore = useDeclarationStore()
const { getDeclaration, getTransfers, getUnclosedShorts, getMissings } = useDeclarationRepository()
const declarationActions = computed(() => declarationStore.declaration && declarationStore.declaration.actions)
const cPage = ref(1)
const tPages = ref(1)
const pageToChange = ref(0)
const changePage = (val: number) => {
  cPage.value = val
  init()
}
const totalLoading = {
  transferLoading: ref(false),
  missingLoading: ref(false),
  shortLoading: ref(false),
}
const TmsDescriber = [
  {
    name: 'Transfer',
    getMethod: () => {
      totalLoading.transferLoading.value = true
      getTransfers(props.good_id, 1).finally(() => {
        totalLoading.transferLoading.value = false
      })
    },
    resolveName: 'Transfer_resolved',
    component: Transfers,
    show: ref(false),
  },
  {
    name: 'Missing',
    getMethod: () => {
      totalLoading.missingLoading.value = true
      getMissings(props.good_id, 1).finally(() => {
        totalLoading.missingLoading.value = false
      })
    },
    resolveName: 'Missing_resolved',
    component: Missings,
    show: ref(false),
  },
  {
    name: 'Short',
    getMethod: () => {
      totalLoading.shortLoading.value = true
      getUnclosedShorts(props.good_id, 1).finally(() => {
        totalLoading.shortLoading.value = false
      })
    },
    resolveName: 'Short_resolved',
    component: UnclosedShorts,
    show: ref(false),
  },
]
const haveBaseRequest = ref(false)
const isLoading = computed(
  () => {
    return Object.values(totalLoading).some((loading) => loading.value)
  }
  // Object.keys(totalLoading).reduce((acc, key) => {
  //   return acc && totalLoading[key].value
  // }, false)
)
const getAllTMS = (val: string[] | null) => {
  haveBaseRequest.value = false
  TmsDescriber.forEach((item) => {
    if (val?.includes(item.name) && !haveBaseRequest.value) {
      haveBaseRequest.value = true
      item.getMethod()
      item.show.value = true
    }
    if (val?.includes(item.resolveName)) {
      item.getMethod()
      item.show.value = true
    }
  })
}
const showComponent = computed(() => TmsDescriber.filter((item) => item.show.value))
const renderComponent = computed(() => {
  return (
    declarationStore.tms?.transfers?.length > 0 ||
    declarationStore.tms?.missings?.length > 0 ||
    declarationStore.tms?.unclosed_shorts?.length > 0
  )
})
function init() {
  invoiceRepository.getInvoice(props.id).then(() => {
    getDeclaration(props.good_id)
      .then(() => {
        getAllTMS(declarationActions.value)
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `Получения декларации ${err}`,
        })
      })
  })
}
watch(pageToChange, () => {
  init()
})
init()

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "mb-4" }, "Дефицит данных", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mb-8" }, " В вашем отчете отсутствуют данные по некоторым позициям. Загрузите файлы брокера с этой информацией или внесите цифры вручную. ", -1)),
    _createVNode(_component_el_pagination, {
      class: "margin-v-s",
      "hide-on-single-page": tPages.value === 1,
      "current-page": cPage.value,
      total: tPages.value,
      layout: "prev, pager, next",
      "page-size": 1,
      onCurrentChange: changePage,
      background: ""
    }, null, 8, ["hide-on-single-page", "current-page", "total"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(ClearLoader, { key: 0 }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(showComponent.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.name
          }, [
            (renderComponent.value)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                  key: 0,
                  onSave: init,
                  currency: _unref(CurrencyDescriber)
                }, null, 40, ["currency"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
  ]))
}
}

})