<script setup lang="ts">
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { computed, UnwrapNestedRefs } from 'vue'
import usePropertyDeductionDescriber from '@/04-Widgets/DeductionWidget/model/usePropertyDeductionDescriber'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'remove'): void
}
const emit = defineEmits<IEmits>()
const { property_type, agreement_type } = usePropertyDeductionDescriber()
const property = computed(
  () => property_type.find((i: any) => i.type === props.item.item?.other_attributes?.property_type)?.label || '-'
)
const agreement = computed(
  () => agreement_type.find((i: any) => i.type === props.item.item?.other_attributes?.agreement_type)?.label || '-'
)
</script>

<template>
  <el-row align="middle" :gutter="10" v-if="props.item">
    <el-col :lg="4">
      <h3 class="font-color--gray mb-2">Приобретение</h3>
      <p>{{ agreement || '-' }}</p>
    </el-col>
    <el-col :lg="7">
      <h3 class="font-color--gray mb-2">Наименование</h3>
      <p>{{ property }}</p>
    </el-col>
    <el-col :lg="3">
      <h3 class="font-color--gray mb-2">Дата</h3>
      <p>{{ props.item.item?.other_attributes?.date || '-' }}</p>
    </el-col>
    <el-col :lg="3">
      <h3 class="font-color--gray mb-2">Сумма</h3>
      <p>{{ props.item.item?.sum || '-' }}</p>
    </el-col>
    <el-col :lg="3">
      <div class="flex items-center justify-end">
        <p class="font-bold">
          <span v-if="props.item?.valueFromServer && !props.item.fieldWasEdited" class="font-color--green"
            >Вычет сохранен</span
          >
          <span v-else class="font-color--red">Вычет не сохранен</span>
        </p>
      </div>
    </el-col>
    <el-col :lg="4">
      <div class="flex items-center justify-end">
        <edit-save-card-button-group @toggle="item.toggleEdit" @remove="$emit('remove')" />
      </div>
    </el-col>
  </el-row>
</template>

<style scoped lang="sass"></style>
