import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

import { UploadFilled } from '@element-plus/icons-vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { ElNotification } from 'element-plus'
import { ref } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { IPaymentBody } from '@/04-Widgets/GoodsSelection/PaymentPage.vue'

interface IProps {
  promo: string | undefined
  invoiceId: string | number
  goodBody: IPaymentBody
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PromocodeForm',
  props: {
    promo: {},
    invoiceId: {},
    goodBody: {}
  },
  setup(__props: any) {

const props = __props
const { goodSelect, getInvoice, addPromocode } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()
const loading = ref(false)

const schema = toTypedSchema(
  yup.object({
    promocode: yup.string(),
  })
)
const { handleSubmit, values } = useForm({
  validationSchema: schema,
  initialValues: {
    promocode: props.promo || '',
  },
})

const sendPromocode = handleSubmit((promo) => {
  loading.value = true
  goodSelect(props.invoiceId, props.goodBody).then(() => {
    addPromocode(props.invoiceId, { ...promo })
      .then(() => {
        ElNotification({
          title: `Промокод`,
          message: 'успешно активирован',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          title: `Ошибка`,
          message: `при применении промокода`,
          type: 'error',
        })
      })
      .finally(() => {
        getInvoice(props.invoiceId).then((invoice) => {
          initAsync(invoice)
        })
        loading.value = false
      })
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    onSubmit: _withModifiers(_unref(sendPromocode), ["prevent"]),
    "validation-schema": _unref(schema)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FormItem, {
          name: "promocode",
          label: ''
        }, {
          default: _withCtx(({ value, onBlur, onInput }) => [
            _createVNode(_component_el_input, {
              "model-value": value,
              type: "text",
              name: "promocode",
              onBlur: onBlur,
              onInput: onInput,
              placeholder: "Промокод"
            }, null, 8, ["model-value", "onBlur", "onInput"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tooltip, {
          effect: "dark",
          content: "Активировать промокод",
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              style: {"margin-left":"-32px","z-index":"1000","width":"30px"},
              type: "primary",
              icon: _unref(UploadFilled),
              loading: loading.value,
              disabled: _unref(values).promocode === '',
              "native-type": "submit"
            }, null, 8, ["icon", "loading", "disabled"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema"]))
}
}

})