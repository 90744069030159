<script setup lang="ts">
import { useRouter } from 'vue-router'
import usePolitic from '@/use/usePolitic'
import { StarFilled, Discount, Histogram, UserFilled, Memo } from '@element-plus/icons-vue'

const router = useRouter()
const { isAdmin } = usePolitic()

const settingsMenuDescriptor = [
  {
    title: 'Промокоды',
    icon: Discount,
    clickAction: () => {
      router.push({
        name: 'promo index',
      })
    },
  },
  // {
  //   title: 'Брокеры',
  //   icon: StarFilled,
  //   clickAction: () => {
  //     router.push({
  //       name: 'brokers index',
  //     })
  //   },
  // },
  {
    title: 'Оксм',
    icon: Memo,
    clickAction: () => {
      router.push({
        name: 'oksm index',
      })
    },
  },
  // {
  //   title: 'Профили',
  //   icon: UserFilled,
  //   clickAction: () => {
  //     router.push({
  //       name: 'profiles',
  //     })
  //   },
  // },
  {
    title: 'Нал план',
    icon: Histogram,
    clickAction: () => {
      router.push({
        name: 'tax plan index',
      })
    },
  },
]
</script>

<template>
  <div v-if="isAdmin">
    <div
      v-for="item in settingsMenuDescriptor"
      :key="item.title"
      @click="item.clickAction"
      class="clickable font-weight--semi cursor-point"
    >
      <div class="flex items-center">
        <el-icon size="small" class="mr-2">
          <component :is="item.icon" />
        </el-icon>
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
