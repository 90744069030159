import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "line-height" }
const _hoisted_2 = { class: "flex items-center my-4" }

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { computed } from 'vue'
import { WarningFilled } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceErrWarning',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const errors = computed<string[]>(() => {
  if (invoiceStore.invoice) {
    return invoiceStore.invoice.warning_and_error.errors
  }
  return []
})
const { colors, isDark } = useColors()

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors.value, (_item, key) => {
    return (_openBlock(), _createElementBlock("div", {
      key: key,
      class: "err-box mb-4 p-4 border"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_icon, {
            color: _unref(isDark) ? _unref(colors).dark.ng_red : _unref(colors).light.ng_error,
            size: "large",
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(WarningFilled))
            ]),
            _: 1
          }, 8, ["color"]),
          _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Возникла ошибка", -1))
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "my-4" }, "Проблема в загруженных файлах.", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "my-4" }, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Повторно скачайте отчеты брокера, строго следуя инструкции."),
            _createElementVNode("li", null, "Удалите загруженные файлы."),
            _createElementVNode("li", null, "Загрузите новые отчеты брокера в этот заказ.")
          ])
        ], -1))
      ])
    ]))
  }), 128))
}
}

})