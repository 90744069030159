import { useRequestService } from '@/use/useRequestService'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'

export default function useIssuersRepository() {
  const { fetch } = useRequestService()
  const toStore = useTradeOperationsStore()

  function getIssuers(declarationId: string | number, page: number, search: string, sortQuery: string) {
    return new Promise((resolve, reject) => {
      fetch(`/declarations/${declarationId}/show_issuers?ticker=${search}&page=${page}&diff=${sortQuery}`)
        .then((res: any) => {
          toStore.issuers = res.issuers
          toStore.issuersPagination = {
            total_pages: res.total_pages,
            current_page: res.current_page,
            per_page: res.per_page,
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  return {
    getIssuers,
  }
}
