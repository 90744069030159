import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/close_icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "ng_right_sidebar"
}
const _hoisted_2 = { class: "bg-gray p-4 flex" }

interface IProps {
  title?: string
  width?: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'RightSideBar',
  props: /*@__PURE__*/_mergeModels({
    title: {},
    width: {}
  }, {
    "modelValue": { type: Boolean, ...{ default: false, required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


const model = _useModel<boolean>(__props, "modelValue")
function closeSidebar() {
  model.value = false
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "slide-fade" }, {
    default: _withCtx(() => [
      (model.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              style: _normalizeStyle(_ctx.width ? { width: _ctx.width } : { width: '70%' }),
              onClick: closeSidebar
            }, null, 4),
            _createElementVNode("div", {
              class: "ng_right_sidebar--main sidebar-area",
              style: _normalizeStyle(_ctx.width ? { width: _ctx.width } : { width: '30%' })
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  class: "button--text-hover mr-8",
                  width: "20",
                  onClick: closeSidebar,
                  src: _imports_0,
                  alt: "close_icon"
                }),
                _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
              ]),
              _renderSlot(_ctx.$slots, "default")
            ], 4)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})