import { useRequestService } from '@/use/useRequestService'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'
import EmptyIncomeObjects from '@/describers/EmptyIncomeObjects'
import {
  ICalcExpenseList,
  ICalcIncomeList,
  IIncomeCodes,
  IIncomeOkvs,
} from '@/entityes/invoice/trade_operations/trade_operations.type'
export default function useTradeOperationsRepository() {
  const { emptyIncome, emptyExpense, incomesEditField, expensesEditField } = EmptyIncomeObjects()
  const { fetch } = useRequestService()
  const tradeOperationsStore = useTradeOperationsStore()
  function calculatedIncomesInit(
    invoiceId: string | number,
    page: number,
    search: string,
    filterQuery: string,
    sortQuery: string
  ) {
    return new Promise((resolve, reject) => {
      Promise.all([
        getCalcIncomes(page, search, filterQuery, sortQuery, invoiceId),
        getCodes(invoiceId),
        getOkvs(invoiceId),
      ])
        .then((res: any) => {
          tradeOperationsStore.calculatedIncomes = res[0].calculated_incomes
          tradeOperationsStore.incomesPagination = {
            total_pages: res[0].total_pages,
            current_page: res[0].current_page,
            per_page: res[0].per_page,
          }
          tradeOperationsStore.incomeCodes = res[1].codes
          tradeOperationsStore.incomeOkvs = res[2].okvs
          resolve(res)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }
  function getCalcIncomes(
    page: number,
    search: string,
    filterQuery: string,
    sortQuery: string,
    invoiceId: string | number
  ) {
    return fetch<ICalcIncomeList>(
      `/admin/invoices/${invoiceId}/calculated_incomes?q[ticker_description_cont]=${search}&page=${page}${
        filterQuery || ''
      }${sortQuery || ''}`
    )
  }
  function getCodes(invoiceId: string | number) {
    return fetch<IIncomeCodes>(`/admin/invoices/${invoiceId}/calculated_incomes_codes`)
  }
  function getOkvs(invoiceId: string | number) {
    return fetch<IIncomeOkvs>(`/admin/invoices/${invoiceId}/calculated_incomes_okvs`)
  }
  function updateCalcIncome(row: any) {
    return fetch(`/admin/calculated_incomes/${row.id}`, {
      method: 'patch',
      body: incomesEditField.reduce(
        (acc: any, key: string) => ({
          ...acc,
          [key]: row[key],
        }),
        {}
      ),
    })
  }
  function destroyCalcIncome(incomeId: number | string) {
    return fetch(`/admin/calculated_incomes/${incomeId}`, {
      method: 'delete',
    })
  }
  function createIncome(declarationId: string | number) {
    emptyIncome.declaration_id = declarationId
    return fetch('/admin/calculated_incomes', {
      method: 'post',
      body: emptyIncome,
    })
  }
  function deleteSelectedRows(body: string[]) {
    return fetch('/admin/calculated_incomes/bulk_delete', {
      method: 'delete',
      body: { delete_ids: body },
    })
  }
  function requestExpenses(incomeId: number | string) {
    return new Promise((resolve, reject) => {
      fetch<ICalcExpenseList>(`/admin/calculated_incomes/${incomeId}/calculated_expenses`)
        .then((res: any) => {
          tradeOperationsStore.expenses = res.calculated_expenses
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function updateExpense(row: any) {
    return fetch(`/admin/calculated_expenses/${row.id}`, {
      method: 'patch',
      body: expensesEditField.reduce(
        (acc, key) => ({
          ...acc,
          [key]: row[key],
        }),
        {}
      ),
    })
  }
  function destroyExpense(expenseId: number | string) {
    return fetch(`/admin/calculated_expenses/${expenseId}`, {
      method: 'delete',
    })
  }
  function createExpense(incomeId: string | number) {
    emptyExpense.calculated_income_id = incomeId
    return fetch('/admin/calculated_expenses', {
      method: 'post',
      body: emptyExpense,
    })
  }
  function deleteSelectedExpenses(expensesArray: any[]) {
    return fetch('/admin/calculated_expenses/bulk_delete', {
      method: 'delete',
      body: expensesArray,
    })
  }
  return {
    calculatedIncomesInit,
    updateCalcIncome,
    destroyCalcIncome,
    createIncome,
    deleteSelectedRows,
    requestExpenses,
    updateExpense,
    destroyExpense,
    createExpense,
    deleteSelectedExpenses,
  }
}
