import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }

import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { UnwrapNestedRefs, watch } from 'vue'
import { string as str, object as obj, bool } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { cloneDeep } from 'lodash'
import { setTouchedHelper } from '../lib/setTouchedHelper'
import FormItem from '@/07-Shared/ui/FormItem.vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Unknown',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

setTouchedHelper(props.item)
const schema = obj({
  rus: bool().default(true),
  physical_person: bool().default(true),
  currency: str().default('RUB'),
  amount: str().default('0'),
  other_attributes: obj({
    message: str().required('Это поле обязательно для заполнения'),
    phone: str().required('Это поле обязательно для заполнения'),
  }),
})
const validationSchema = toTypedSchema(schema)
const { values, validate } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign({
    ...props.item.item,
  }),
})

async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { class: "mb-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { lg: 17 }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Напишите нам сообщение", -1)),
            _createVNode(FormItem, { name: "other_attributes.message" }, {
              default: _withCtx(({ field, value }) => [
                _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, {
                  type: "textarea",
                  class: "textarea-crunch",
                  placeholder: "Опишите вашу ситуацию",
                  autosize: { minRows: 2, maxRows: 4 }
                }), null, 16, ["model-value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { lg: 1 }),
        _createVNode(_component_el_col, { lg: 6 }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Номер телефона", -1)),
            _createVNode(FormItem, { name: "other_attributes.phone" }, {
              default: _withCtx(({ field, value }) => [
                _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "89998887766" }), null, 16, ["model-value"]), [
                  [_directive_mask, ['8##########']]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})