import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pl-4"
}
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = {
  key: 1,
  class: "p-4"
}
const _hoisted_4 = { class: "mb-4" }

import { computed, ref } from 'vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import usePolitic from '@/use/usePolitic'
import { useGetResultFilesRequest } from '@/05-Features/ResultFiles/api/getResultFiles.request'
import { ElNotification } from 'element-plus'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'
import { IFile } from '@/entityes/invoice/file/file.type'
import useColors from '@/07-Shared/model/useColors'
import { WarningFilled, SuccessFilled } from '@element-plus/icons-vue'

interface IProps {
  goodName: string
  goodId: string | number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GoodsResultFiles',
  props: {
    goodName: {},
    goodId: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const { getDeclarationResultFiles, getCfrResulFiles, getTaxPlanResultFiles } = useGetResultFilesRequest()
const { isAdmin } = usePolitic()
const timer = ref<number | null>(null)
const resultFiles = ref<IFile[]>([])
const resultsBlocked = computed(() => invoiceStore?.invoice?.status?.blocked)
const methodsForGoods = {
  Declaration: getDeclarationResultFiles,
  Cfr: getCfrResulFiles,
  TaxPlan: getTaxPlanResultFiles,
}
const { colors, isDark } = useColors()
if (props.goodName !== 'Cfr') {
  methodsForGoods[props.goodName as 'Cfr' | 'Declaration' | 'TaxPlan'](props.goodId)
    .then((res) => {
      if (res?.length > 0) {
        resultFiles.value.splice(0, resultFiles.value.length, ...res)
      } else {
        timer.value = setTimeout(() => {
          methodsForGoods[props.goodName as 'Cfr' | 'Declaration' | 'TaxPlan'](props.goodId)
        }, 5000)
      }
    })
    .catch((err: any) => {
      ElNotification({
        title: `Ошибка`,
        message: `получения файлов ${err}`,
        type: 'error',
      })
    })
}

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (resultFiles.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(resultFiles.value, (file) => {
              return (_openBlock(), _createElementBlock("div", {
                key: file?.id
              }, [
                _withDirectives(_createVNode(DownloadFileCard, {
                  file: file,
                  "is-result": ""
                }, null, 8, ["file"]), [
                  [_directive_loading, methodsForGoods[props.goodName as 'Cfr' | 'Declaration' | 'TaxPlan'].indicator.value === true]
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (resultsBlocked.value && !_unref(isAdmin))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, [
            _createVNode(_component_el_icon, {
              color: _unref(isDark) ? _unref(colors).dark.ng_red : _unref(colors).light.ng_error
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(WarningFilled))
              ]),
              _: 1
            }, 8, ["color"]),
            _cache[0] || (_cache[0] = _createTextVNode(" Скачивание итоговых документов заблокировано, так как заказ находится на стадии проверки. "))
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_el_icon, {
              color: _unref(isDark) ? _unref(colors).dark.ng_green : _unref(colors).light.ng_green
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(SuccessFilled))
              ]),
              _: 1
            }, 8, ["color"]),
            _cache[1] || (_cache[1] = _createTextVNode(" Однако вы можете посмотреть предварительный расчет. "))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})