<script lang="ts" setup>
interface IProps {
  title?: string
  width?: string
}
defineProps<IProps>()
const model = defineModel<boolean>({ default: false, required: true })
function closeSidebar() {
  model.value = false
}
</script>

<template>
  <transition name="slide-fade">
    <div class="ng_right_sidebar" v-if="model">
      <div :style="width ? { width: width } : { width: '70%' }" @click="closeSidebar"></div>
      <div class="ng_right_sidebar--main sidebar-area" :style="width ? { width: width } : { width: '30%' }">
        <div class="bg-gray p-4 flex">
          <img
            class="button--text-hover mr-8"
            width="20"
            @click="closeSidebar"
            src="@/assets/close_icon.svg"
            alt="close_icon"
          />
          <h3>{{ title }}</h3>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style lang="sass" scoped></style>
