import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4"
}

import incomesView from '@/views/admin/IncomesView.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { ref } from 'vue'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { ElNotification } from 'element-plus'
import { useGetResultFilesRequest } from '@/05-Features/ResultFiles/api/getResultFiles.request'

interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TradeOperations',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const declarationStore = useDeclarationStore()
const { getInvoice } = useInvoiceRepository()
const { getDeclaration, recountDeclarationForCalcIncomes } = useDeclarationRepository()
const { getDeclarationResultFiles } = useGetResultFilesRequest()
const { initAsync } = useAsyncInvoiceReload()
const showRecalcButton = ref(false)

function init() {
  getInvoice(props.id).then((invoice: IInvoice) => {
    initAsync(invoice).then(() => {
      getDeclaration(invoice.goods.Declaration).then(() => {
        getDeclarationResultFiles(invoice.goods.Declaration)
          .then((files) => {
            showRecalcButton.value = files.length > 0
          })
          .catch((err) => {
            ElNotification({
              title: 'Ошибка',
              message: `получения информации об итоговых файлах ${err}`,
            })
          })
      })
    })
  })
}
function recalculateDeclaration() {
  recountDeclarationForCalcIncomes(declarationStore.declaration.id)
    .then(() => {
      init()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `пересчета декларации на основании таблицы сделок ${err}`,
        type: 'error',
      })
    })
}
init()

return (_ctx: any,_cache: any) => {
  return (!!_unref(invoiceStore).invoice && !!_unref(declarationStore).declaration)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(incomesView, {
          id: _unref(invoiceStore).invoice.id,
          "declaration-id": _unref(declarationStore).declaration.id,
          "show-recalc-button": showRecalcButton.value,
          onRecalculate: recalculateDeclaration
        }, null, 8, ["id", "declaration-id", "show-recalc-button"])
      ]))
    : _createCommentVNode("", true)
}
}

})