import { ICommonExpense } from '@/entityes/invoice/trade_operations/trade_operations.type'

export default function EmptyIncomeObjects() {
  const incomesEditField: string[] = [
    'currency_sum',
    'date',
    'income_code',
    'oksm_country',
    'okv_country',
    'price',
    'qty',
    'rub_sum',
    'tax_currency_paid',
    'tax_date',
    'ticker_description',
  ] as const
  const expensesEditField: string[] = ['currency_sum', 'date', 'code', 'qty', 'okv_country', 'rub_sum']
  const emptyExpense: any = {
    calculated_income_id: '',
    code: 201,
    comment: '',
    currency_sum: 0,
    date: '01.01.2021',
    okv_country: '840/USD',
    price: 0,
    qty: 0,
    rate: '',
    rub_sum: 0,
    xlsx_description: '',
  }
  const commonExpenseEditField: string[] = [
    'broker',
    'comment',
    'currency',
    'date',
    'description',
    'paid_sum',
    'rate',
    'rub_sum',
  ]
  const emptyIncome: any = {
    declaration_id: '',
    broker: 'nujen broker',
    date: '01.01.2021',
    price: 0,
    qty: 0,
    currency_sum: 0,
    okv_country: '840/USD',
    income_rate: 0,
    income_code: 1010,
    income_description: '',
    tax_date: '',
    tax_withhold_percent: 0,
    tax_currency_paid: 0,
    tax_rate: 0,
    tax_rub_paid: 0,
    tax_to_pay_percent: 0,
    tax_to_pay_rub: 0,
    oksm_country: 0,
    kod_vid_doh: '',
    raw_string: '',
    source_file_name: '',
    rub_sum: 0,
    currency_repricing: 0,
    xlsx_description: '',
  }
  const emptyCommonExpense: Omit<ICommonExpense, 'id'> = {
    accounted_code: [],
    broker: 'someBroker',
    comment: 'some comment',
    currency: 'USD',
    date: '2021-11-05',
    description: 'some description',
    paid_sum: 0,
    rate: 0,
    rub_sum: 0,
    static_expense_id: null,
  } as const

  return {
    incomesEditField,
    expensesEditField,
    emptyExpense,
    commonExpenseEditField,
    emptyIncome,
    emptyCommonExpense,
  }
}
