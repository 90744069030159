<script lang="ts" setup>
import useTextFilters from '@/plugins/TextFilters'
import useColors from '@/07-Shared/model/useColors'
interface IProps {
  files: string[]
}

const props = defineProps<IProps>()
const { subStringFileName } = useTextFilters()
interface IEmits {
  (e: 'deleteLoadedFile', index: number): void
}

const emit = defineEmits<IEmits>()
const { colors, isDark } = useColors()
</script>

<template>
  <div v-for="(name, index) in props.files" :key="name" class="mb-4">
    <div class="flex items-center justify-between pl-6">
      <div class="flex items-center">
        <el-icon :color="isDark ? colors.dark.ng_green : colors.light.ng_green" size="large" class="mr-2">
          <CirclePlusFilled />
        </el-icon>
        <span class="">{{ subStringFileName(name, 30) }}</span>
      </div>
      <img
        class="button--text-hover delete-icon"
        @click="emit('deleteLoadedFile', index)"
        src="@/assets/delete_icon.svg"
        alt="close_icon"
        width="20"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
