import { CheckAdminRoleMiddleware } from '@/router/middlewares/CheckAdminRoleMiddleware'
import { isAuth } from '@/router/middlewares/isAuth'
const oksmIndex = () => import('@/components/Admin/Oksm/OksmIndex.vue')

export default [
  {
    path: '/oksm',
    name: 'oksm index',
    component: oksmIndex,
    meta: {
      middleware: [isAuth, CheckAdminRoleMiddleware],
    },
  },
]
