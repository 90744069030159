import { useRequestService } from '@/use/useRequestService'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'
import { ICommonExpenseList } from '@/entityes/invoice/trade_operations/trade_operations.type'
import EmptyIncomeObjects from '@/describers/EmptyIncomeObjects'

export default function useCommonExpensesRepository() {
  const { fetch } = useRequestService()
  const toStore = useTradeOperationsStore()
  const { emptyCommonExpense, commonExpenseEditField } = EmptyIncomeObjects()

  function getCommonExpenses(declarationId: string | number, page: number, search: string, sortQuery: string) {
    return new Promise((resolve, reject) => {
      fetch<ICommonExpenseList>(
        `/admin/declarations/${declarationId}/common_expenses?q[description_cont]=${search}&page=${page}${sortQuery}`
      )
        .then((res) => {
          toStore.commonExpenses = res.common_expenses
          toStore.commonExpensesPagination = {
            total_pages: res.total_pages,
            current_page: res.current_page,
            per_page: res.per_page,
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function createCommonExpense(declarationId: string | number) {
    return fetch(`/admin/declarations/${declarationId}/common_expenses`, {
      method: 'post',
      body: emptyCommonExpense,
    })
  }
  function destroyCommonExpense(commonExpenseId: string | number) {
    return fetch(`/admin/common_expenses/${commonExpenseId}`, {
      method: 'delete',
    })
  }
  function updateCommonExpense(commonExpense: any) {
    const body = commonExpenseEditField.reduce(
      (acc, key) => ({
        ...acc,
        [key]: commonExpense[key],
      }),
      {}
    )
    return fetch(`/admin/common_expenses/${commonExpense.id}`, {
      method: 'patch',
      body,
    })
  }
  function deleteSelectedCommonExpenses(declarationId: string | number, commonExpensesIds: string[]) {
    return fetch(`/admin/declarations/${declarationId}/common_expenses/bulk_delete`, {
      method: 'delete',
      body: commonExpensesIds,
    })
  }
  return {
    getCommonExpenses,
    createCommonExpense,
    destroyCommonExpense,
    updateCommonExpense,
    deleteSelectedCommonExpenses,
  }
}
