<script setup lang="ts">
import { IInvoice, IPriceObject } from '@/entityes/invoice/invoice.type'
import { computed, reactive, ref, watch } from 'vue'

interface IProps {
  isAdmin: boolean
  years: number[]
  yearToCalc: number
}
interface IEmits {
  (e: 'select-year', value: number): void
  (e: 'select-country', value: string): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const yearToCalc = ref(props.yearToCalc || 2024)
const countryToCalc = ref('ru')
const countryList = ['ru', 'uk', 'kz', 'se', 'eu']

watch(yearToCalc, (val) => {
  emit('select-year', val)
})
watch(countryToCalc, (val) => {
  emit('select-country', val)
})
</script>

<template>
  <div>
    <div>
      <p class="font-weight--extra text-start mb-1">выберите отчетный год</p>
      <el-select v-model="yearToCalc" placeholder="Выберите год" class="mb-2 min-w-[100px]">
        <el-option v-for="year in props.years" :key="year" :label="year" :value="year" />
      </el-select>
    </div>
    <div v-if="props.isAdmin">
      <p class="font-weight--extra text-start mb-1">выберите страну расчета</p>
      <el-select v-model="countryToCalc" class="mb-2 min-w-[100px]">
        <el-option v-for="i in countryList" :key="i" :label="i" :value="i" />
      </el-select>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
