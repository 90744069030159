import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import PreAddIncomesDeduction from '@/04-Widgets/PreAddIncome/PreAddIncomesDeduction.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'


export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeDeductionCountruction',
  setup(__props) {

const invoiceStore = useInvoiceStore()

return (_ctx: any,_cache: any) => {
  return (!!_unref(invoiceStore).invoice)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(PreAddIncomesDeduction, {
          id: _unref(invoiceStore).invoice.id
        }, null, 8, ["id"])
      ]))
    : _createCommentVNode("", true)
}
}

})