import ndflIcon from '@/assets/pre_add_incomes_icons/two_ndfl_files.svg'
import { IncomeDescriber } from '@/04-Widgets/AdditionelIncome/model/IncomeDescriber'
import { DeductionDescriber } from '@/04-Widgets/DeductionWidget/model/DeductionDescriber'
import { SocialRefundsDescriber } from '@/04-Widgets/DeductionWidget/model/SocialRefundsDescriber'
import { LiveInsureContractType } from '@/04-Widgets/DeductionWidget/model/LiveInsureContractType'
import { IAddItem } from '@/06-Entities/IncomeAndRefund/model/IAddItem.type'
import { generatorId } from '@/07-Shared/lib/generatorId'
import materialLosses from '@/assets/pre_add_incomes_icons/material_losses.svg'
import propertySaleIcon from '@/assets/pre_add_incomes_icons/property_sale.svg'

const propertyDeductionItems: IAddItem[] = [
  {
    name: 'Проценты по ипотеке',
    tag: 'property_deduction_mortgage',
    icon: propertySaleIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Покупка или строительство жилой недвижимости',
    tag: 'property_deduction_build',
    icon: propertySaleIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
]
const incomeDescriber: IAddItem[] = IncomeDescriber.filter((i) => i.meta?.show_pre)
const refundDescriber: IAddItem[] = [...DeductionDescriber, ...propertyDeductionItems]
  .filter((i) => i.meta?.show_pre)
  .reduce((acc, item) => {
    const max =
      item.tag === 'iis' ||
      item.tag === 'property_deduction' ||
      item.tag === 'property_deduction_build' ||
      item.tag === 'property_deduction_mortgage'
        ? 1
        : undefined
    acc.push({ ...item, max })
    return acc
  }, <IAddItem[]>[])
const socialDescriber: IAddItem[] = SocialRefundsDescriber()
  .tagsList.filter((i) => i.meta?.show_pre)
  .reduce((acc, item) => {
    acc.push({
      tag: item.tag,
      name: item.label,
      icon: item.icon,
      max: 1,
    })
    return acc
  }, <IAddItem[]>[])

const liveInsure: IAddItem[] = LiveInsureContractType.reduce((acc, item) => {
  acc.push({
    tag: item.tag,
    name: item.title,
    icon: item.icon,
    max: 1,
  })
  return acc
}, <IAddItem[]>[])

const investmentRefunds: IAddItem[] = refundDescriber.reduce((acc: any, item: any, index: any) => {
  if (item.tag === 'iis' || item.tag === 'material_losses') acc.push(item)
  if (index === refundDescriber.length - 1) {
    acc.push({
      name: 'Сальдирование счетов',
      tag: 'two_ndfl_files',
      icon: ndflIcon,
      meta: {
        onlyOne: true,
        show_pre: true,
      },
    })
  }
  return acc
}, <IAddItem[]>[])

const propertyRefunds = refundDescriber.reduce((acc: IAddItem[], item: IAddItem) => {
  if (item.tag === 'property_deduction_build' || item.tag === 'property_deduction_mortgage') acc.push(item)
  return acc
}, <IAddItem[]>[])

const incomes = {
  title: 'Дополнительные доходы',
  items: [...incomeDescriber.filter((i) => i.tag !== 'two_ndfl_files')] as (IAddItem & { max: number })[],
}
const refunds = {
  title: 'Вычеты',
  items: [
    {
      title: 'Инвестиционные вычеты',
      icon: investmentRefunds.find((i: any) => i.tag === 'material_losses')?.icon || materialLosses,
      refunds: [...investmentRefunds],
      key: generatorId(),
    },
    {
      title: 'Имущественные вычеты',
      icon: propertyRefunds.find((i: any) => i.tag === 'property_deduction_build')?.icon || materialLosses,
      refunds: [...propertyRefunds],
      key: generatorId(),
    },
    {
      title: 'Социальные вычеты',
      icon: socialDescriber.find((i) => i.tag === 'med')?.icon || materialLosses,
      refunds: [...socialDescriber, ...liveInsure],
      key: generatorId(),
    },
  ],
}

// charity должно быть в конце списка вычетов
const indexCharity = refunds.items[2].refunds.findIndex((item) => item.tag === 'charity')
refunds.items[2].refunds.push(refunds.items[2].refunds.splice(indexCharity, 1)[0])

const incomesDeductionsDescribers: IAddItem[] = [
  ...incomes.items,
  ...refunds.items.reduce((acc, item) => {
    acc.push(...item.refunds)
    return acc
  }, <IAddItem[]>[]),
]

export default function AddIncomesRefundsDescriber() {
  return {
    incomesDeductionsDescribers,
    incomes,
    refunds,
  }
}
