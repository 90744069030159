import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "flex-box flex-box--center" }
const _hoisted_5 = { class: "flex-box flex-box--center" }
const _hoisted_6 = { key: 3 }

import { ref } from 'vue'

export interface IProps {
  setupValue?: string[] | number[]
  searchLabel?: string
  element?: string
}
export interface IEmits {
  (e: 'changeSearchEvent', value: string): void
  (e: 'searchEvent', value: string | number): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CalculatedIncomeTableFilter',
  props: {
    setupValue: {},
    searchLabel: {},
    element: {}
  },
  emits: ["changeSearchEvent", "searchEvent"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const model = ref('')
const searchString = ref('')
function filter(value: string) {
  if (value) {
    const str = `&q[${props.searchLabel}_eq]=${value}`
    emit('searchEvent', str)
  } else {
    emit('searchEvent', '')
  }
}
function sort(value: string) {
  const str = `&q[s]=${props.searchLabel} ${value}`
  emit('searchEvent', str)
}
function search(value: string) {
  if (value === '') {
    emit('searchEvent', '')
  } else {
    const str = `&q[${props.searchLabel}_cont]=${value}`
    emit('searchEvent', str)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.element === 'filter')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_select, {
            modelValue: model.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
            filterable: "",
            onChange: filter,
            size: "small"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                value: "",
                label: "Очистить"
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.setupValue, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item,
                  label: item,
                  value: `${item}`
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.element === 'date_filter')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_date_picker, {
            modelValue: model.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
            type: "datetime",
            format: "YYYY/MM/DD",
            onChange: filter,
            size: "small",
            style: {"max-width":"150px"}
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.element === 'sort')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_icon, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (sort('asc'))),
              size: "large",
              class: "button--text-hover"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CaretTop)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_icon, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (sort('desc'))),
              size: "large",
              class: "button--text-hover"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CaretBottom)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.element === 'search')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_el_input, {
            modelValue: searchString.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((searchString).value = $event)),
            onInput: search,
            class: "mr-2",
            size: "small"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})