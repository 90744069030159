<script setup lang="ts">
import incomesView from '@/views/admin/IncomesView.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { ref } from 'vue'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { ElNotification } from 'element-plus'
import { useGetResultFilesRequest } from '@/05-Features/ResultFiles/api/getResultFiles.request'

interface IProps {
  id: string | number
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const declarationStore = useDeclarationStore()
const { getInvoice } = useInvoiceRepository()
const { getDeclaration, recountDeclarationForCalcIncomes } = useDeclarationRepository()
const { getDeclarationResultFiles } = useGetResultFilesRequest()
const { initAsync } = useAsyncInvoiceReload()
const showRecalcButton = ref(false)

function init() {
  getInvoice(props.id).then((invoice: IInvoice) => {
    initAsync(invoice).then(() => {
      getDeclaration(invoice.goods.Declaration).then(() => {
        getDeclarationResultFiles(invoice.goods.Declaration)
          .then((files) => {
            showRecalcButton.value = files.length > 0
          })
          .catch((err) => {
            ElNotification({
              title: 'Ошибка',
              message: `получения информации об итоговых файлах ${err}`,
            })
          })
      })
    })
  })
}
function recalculateDeclaration() {
  recountDeclarationForCalcIncomes(declarationStore.declaration.id)
    .then(() => {
      init()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `пересчета декларации на основании таблицы сделок ${err}`,
        type: 'error',
      })
    })
}
init()
</script>

<template>
  <div v-if="!!invoiceStore.invoice && !!declarationStore.declaration" class="p-4">
    <incomes-view
      :id="invoiceStore.invoice.id"
      :declaration-id="declarationStore.declaration.id"
      :show-recalc-button="showRecalcButton"
      @recalculate="recalculateDeclaration"
    />
  </div>
</template>

<style scoped lang="sass"></style>
