import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cfr-table mb-8" }
const _hoisted_2 = { class: "mr-4" }

interface IProps {
  countryCode?: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CfrOnlineZero',
  props: {
    countryCode: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "mb-4 font-bold" }, "Данные о движении денежных средств", -1)),
    _createElementVNode("table", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("tr", null, [
        _createElementVNode("th", null, "Название"),
        _createElementVNode("th", null, "Значение")
      ], -1)),
      _createElementVNode("tr", null, [
        _cache[0] || (_cache[0] = _createElementVNode("td", { class: "font-weight--semi" }, "Валюта", -1)),
        _createElementVNode("td", null, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(props.countryCode || ''), 1)
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("tr", null, [
        _createElementVNode("td", { class: "font-weight--semi" }, "Остаток денежных средств на счете на начало отчетного периода"),
        _createElementVNode("td", null, [
          _createElementVNode("span", { class: "mr-4" }, "0")
        ])
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("tr", null, [
        _createElementVNode("td", { class: "font-weight--semi" }, "Зачислено денежных средств за отчетный период - всего"),
        _createElementVNode("td", null, [
          _createElementVNode("span", { class: "mr-4" }, "0")
        ])
      ], -1)),
      _cache[4] || (_cache[4] = _createElementVNode("tr", null, [
        _createElementVNode("td", { class: "font-weight--semi" }, "Списано денежных средств за отчетный период - всего"),
        _createElementVNode("td", null, [
          _createElementVNode("span", { class: "mr-4" }, "0")
        ])
      ], -1)),
      _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
        _createElementVNode("td", { class: "font-weight--semi" }, "Остаток денежных средств на счете на конец отчетного периода"),
        _createElementVNode("td", null, [
          _createElementVNode("span", { class: "mr-4" }, "0")
        ])
      ], -1))
    ])
  ], 64))
}
}

})