<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'

const { screenLessValue } = useWindowWidth()
</script>

<template>
  <h1 class="mb-4">Дополнительные доходы</h1>
  <p class="mb-2">Укажите дополнительные доходы за рубежом или в РФ.</p>
  <p class="mb-2">
    К дополнительным доходам могут относиться дивиденды, доходы от сдачи имущества в аренду или от его продажи и т.д.
  </p>
  <p class="margin-v-s">Если у вас нет дополнительных доходов, пропустите этот шаг.</p>
</template>

<style lang="sass" scoped></style>
