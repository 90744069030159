const Profile = () => import('@/views/account/Profile.vue')

export default [
  {
    path: 'profiles',
    name: 'profiles',
    props: true,
    component: Profile,
  },
]
