import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "mb-8 flex items-center justify-between" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_9 = { class: "font-color--red" }
const _hoisted_10 = { key: 1 }

import { useUserStore } from '@/entityes/user/userStore'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
// import { useProfilesRepository } from '@/entityes/profiles/profiles.repository'
import { IUser } from '@/entityes/user/user.type'
import { useProfilesStore } from '@/entityes/profiles/profiles.store'
import { useTaxPlanRepository } from '@/entityes/invoice/tax_plan/tax_plan.repository'
import { useTaxPlanStore } from '@/entityes/invoice/tax_plan/tax_plan.store'
import { ITaxPlan } from '@/entityes/invoice/tax_plan/tax_plan.type'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, Loading } from '@element-plus/icons-vue'
import useWindowWidth from '@/use/useWindowWidth'
import TaxPlanCard from '@/07-Shared/ui/TaxPlanCard.vue'
import useTypeWriter from '@/plugins/useTypeWriter'


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxPlanIndex',
  setup(__props) {

const userStore = useUserStore()
// const profilesStore = useProfilesStore()
const taxPlanStore = useTaxPlanStore()
// const { getUserProfiles } = useProfilesRepository()
const { getTaxPlanList, createNewTaxPlan } = useTaxPlanRepository()
const router = useRouter()
const user = computed(() => userStore.user as IUser)
const { screenLessValue } = useWindowWidth()
const { renderString } = useTypeWriter(['расчет...', 'минуточку...', 'еще чуть...:)'])

function createTaxPlans() {
  createNewTaxPlan(userStore.user.active_profile_id, { name: 'Налоговое планирование' })
    .then((res) => {
      ElNotification({
        title: `Успешно`,
        message: `создан таксплан ${res.id}`,
        type: 'success',
      })
      router.push({
        name: 'tax plan show',
        params: {
          id: res.id,
        },
      })
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `создания таксплана. ${err}`,
        type: 'error',
      })
    })
}
function moveToTaxPlan(taxPlan: ITaxPlan) {
  router.push({ name: 'tax plan show', params: { id: taxPlan.id } })
}
getTaxPlanList(userStore.user.active_profile_id).catch((err) => {
  ElNotification({
    title: `Ошибка`,
    message: `получения списка такспланов. ${err}`,
    type: 'error',
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(screenLessValue)(767))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!!_unref(userStore).user.active_profile_id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxPlanStore).taxPlans, (plan) => {
                  return (_openBlock(), _createBlock(TaxPlanCard, {
                    key: plan.id,
                    plan: plan,
                    profile_id: user.value.active_profile_id,
                    class: "margin-v-xs"
                  }, null, 8, ["plan", "profile_id"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (!!_unref(userStore).user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-bold mr-2" }, "Активный профиль:", -1)),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(userStore).user?.active_profile_id), 1)
                  ]),
                  _createVNode(_component_el_button, {
                    icon: _unref(CirclePlusFilled),
                    type: "primary",
                    onClick: createTaxPlans
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Создать ")
                    ])),
                    _: 1
                  }, 8, ["icon"])
                ]),
                _createElementVNode("div", null, [
                  (_unref(taxPlanStore).taxPlans)
                    ? (_openBlock(), _createBlock(_component_el_table, {
                        key: 0,
                        data: _unref(taxPlanStore).taxPlans,
                        onRowClick: moveToTaxPlan,
                        style: {"max-width":"1200px","cursor":"pointer"},
                        border: "",
                        flexible: "",
                        "highlight-current-row": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_table_column, {
                            prop: "id",
                            label: "Номер таксплана"
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "invoice_id",
                            label: "Номер инвойса"
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "name",
                            label: "Название"
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "start_date",
                            label: "Дата старта"
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "finish_date",
                            label: "Дата финиша"
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "async",
                            label: "Расчет"
                          }, {
                            default: _withCtx((scope) => [
                              (scope.row.async)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createVNode(_component_el_icon, {
                                      class: "el-icon-loading margin-h--right-6",
                                      color: "#FF9A9D"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Loading))
                                      ]),
                                      _: 1
                                    }),
                                    _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(renderString)), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[2] || (_cache[2] = [
                                    _createElementVNode("p", { class: "font-color--green" }, "Посчитан", -1)
                                  ])))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["data"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})