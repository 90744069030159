import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import AccountRouter from './AccountRouter'
import { middlewarePipeline } from './middlewares/middelwarePipeline'
import AdminRouter from '@/router/AdminRouter'
import { checkPasswordTokenMiddleware } from './middlewares/checkPasswordTokenMiddleware'
const Authorization = () => import('../03-Pages/Auth/Authorization.vue')
const Registration = () => import('../03-Pages/Reg/Registration.vue')
const ChangePassword = () => import('../04-Widgets/Auth/ui/ChangePassword.vue')
const ExanteRegistration = () => import('@/04-Widgets/Reg/ui/ExanteRegistration.vue')
const AccountView = () => import('../views/account/AccountView.vue')
const ChangePasswordLogout = () => import('../04-Widgets/Auth/ui/ChangePasswordLogout.vue')
const AdminView = () => import('@/views/admin/AdminView.vue')

const routes: RouteRecordRaw[] = [
  {
    name: 'Home',
    path: '/',
    redirect: '/account',
  },
  {
    path: '/account',
    component: AccountView,
    children: [...AccountRouter],
  },
  {
    path: '/admin',
    component: AdminView,
    children: [...AdminRouter],
  },
  {
    name: 'authorization',
    path: '/authorization',
    component: Authorization,
  },
  {
    name: 'registration',
    path: '/registration',
    component: Registration,
  },
  {
    name: 'exanteRegistration',
    path: '/registration_exante',
    component: ExanteRegistration,
  },
  {
    name: 'change_password',
    path: '/change_password/:token',
    component: ChangePassword,
    props: true,
    meta: {
      middleware: [checkPasswordTokenMiddleware],
    },
  },
  {
    name: 'change_password_logout',
    path: '/change_password_logout',
    component: ChangePasswordLogout,
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

export {}
declare module 'vue-router' {
  interface RouteMeta {
    middleware?: NavigationGuardWithThis<any>[]
  }
}
router.beforeEach(async (to, from) => {
  if (to.meta.middleware) {
    return await middlewarePipeline(...to.meta.middleware)(to, from, () => {})
  }
})

export default router
