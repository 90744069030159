<script lang="ts" setup>
import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { cloneDeep } from 'lodash'
import { useForm } from 'vee-validate'
import { UnwrapNestedRefs, watch } from 'vue'
import { object, string, number } from 'yup'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}
const emit = defineEmits<IEmits>()

const schema = object({
  currency: string().default('RUB'),
  other_attributes: object({
    cb: number().required().default(0),
    pfi: number().required().default(0),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, errorBag } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign({}, { ...props.item.item }),
})
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)
</script>
<template>
  <div class="p-4">
    <p class="font-semibold mb-4">Задекларированные убытки за предыдущие налоговые периоды</p>
    <div class="flex items-center mb-4">
      <div class="mr-4">
        <p class="font-semibold mb-1">Убытки по операциям с ЦБ (в руб.)</p>
        <form-item name="other_attributes.cb" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" />
        </form-item>
      </div>
      <div>
        <p class="font-semibold mb-1">Убытки по операциям с ПФИ (в руб.)</p>
        <form-item name="other_attributes.pfi" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" />
        </form-item>
      </div>
    </div>
  </div>
</template>
<style lang="sass"></style>
