<script setup lang="ts">
import { IGood } from '@/describers/GoodsDescriber'
import { ref } from 'vue'
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  good: IGood
  year: string | number
}
const props = defineProps<IProps>()
const addedToBasket = ref(props.good.inBasket)
const { numberSeparator } = useTextFilters()
</script>

<template>
  <div class="good-card mb-4 card-hover">
    <div class="bg-gray border--top p-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <div class="mr-4">
            <el-checkbox v-model="addedToBasket" />
          </div>
          <div class="flex justify-between">
            <h3 class="mr-3">{{ props.good.title }} {{ year }}</h3>
          </div>
        </div>
        <div>
          <h3 v-if="props.good.name !== 'tax_plan'">₽ {{ numberSeparator(props.good.basePrice.value, ' ') }}</h3>
        </div>
      </div>
      <div class="flex">
        <p class="w-[86%]">{{ props.good.description }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
