<script lang="ts" setup>
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { watch, UnwrapNestedRefs } from 'vue'
import { setTouchedHelper } from '../lib/setTouchedHelper'
import { getFields } from '../model/formFields'
import { object as obj, string as str } from 'yup'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { cloneDeep } from 'lodash'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  // TODO need typing
  helpDescriber?: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()

setTouchedHelper(props.item)
const { mainFields, otherAttributesField } = getFields(props.yearToCalc)

const schema = obj({
  ...mainFields,
  other_attributes: obj({
    ...otherAttributesField,
    amount_of_expenses: str().checkNumberString(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, errorBag } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign(
    {
      rus: true,
      physical_person: true,
      currency: 'RUB',
    },
    {
      ...props.item.item,
    }
  ),
})
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="px-4 pt-4 mb-4">
    <h4>Доход получен</h4>
  </div>
  <el-row :gutter="20" class="px-4 mb-4">
    <el-col :sm="8" :lg="6" :xl="5">
      <div class="flex items-center mb-1">
        <p class="font-semibold mr-1">Сумма дохода</p>
        <question-popover>
          <div v-html="helpDescriber?.amount" />
        </question-popover>
      </div>
      <form-item name="amount" v-slot="{ field, value }">
        <el-input :model-value="value" v-bind="field" />
      </form-item>
    </el-col>
    <el-col :sm="8" :lg="6" :xl="5">
      <div>
        <div class="flex mb-1 items-center">
          <p class="font-semibold mr-1">Дата получения дохода</p>
          <question-popover>
            <div v-html="helpDescriber?.date" />
          </question-popover>
        </div>
        <form-item name="date" v-slot="{ field, value }">
          <el-date-picker
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            :model-value="value"
            v-bind="field"
          />
        </form-item>
      </div>
    </el-col>
    <el-col :sm="8" :lg="6" :xl="5">
      <div class="flex items-center mb-1">
        <p class="font-semibold mr-1">Сумма расходов на покупку</p>
        <question-popover>
          <div v-html="helpDescriber?.amount_of_expenses" />
        </question-popover>
      </div>
      <form-item name="other_attributes.amount_of_expenses" v-slot="{ field, value }">
        <el-input :model-value="value" v-bind="field" />
      </form-item>
    </el-col>
  </el-row>
  <div>
    <div class="px-4 mb-4">
      <div class="flex items-center mb-4">
        <h4>Другая сторона договора</h4>
      </div>
      <div class="mb-4" v-if="values.rus">
        <form-item name="physical_person" v-slot="{ field, value }">
          <el-radio-group v-bind="field" :model-value="value">
            <el-radio :value="false">Юридическое лицо</el-radio>
            <el-radio :value="true">Физическое лицо</el-radio>
          </el-radio-group>
        </form-item>
      </div>
      <div class="mb-4">
        <div v-if="values.rus">
          <div v-if="values.physical_person">
            <p class="font-semibold mb-1">ФИО</p>
            <div class="flex">
              <form-item name="other_attributes.name" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" />
              </form-item>
            </div>
          </div>
          <div class="flex items-center flex-wrap" v-else>
            <div class="mr-4">
              <p class="font-semibold mb-1">Наименование организации</p>
              <form-item name="other_attributes.name_organization" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" />
              </form-item>
            </div>
            <div class="mr-4">
              <p class="font-semibold mb-1">ИНН</p>
              <form-item name="other_attributes.inn" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" placeholder="123456789012" v-mask="['############']" />
              </form-item>
            </div>
            <div class="mr-4">
              <h4 class="font-semibold mb-1">КПП</h4>
              <form-item name="other_attributes.kpp" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" placeholder="123456789" v-mask="['#########']" />
              </form-item>
            </div>
            <div>
              <h4 class="font-semibold mb-1">ОКТМО</h4>
              <form-item name="other_attributes.oktmo" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" placeholder="12345678" v-mask="['########']" />
              </form-item>
            </div>
          </div>
        </div>
        <div class="flex items-center flex-wrap" v-else>
          <div>
            <h4 class="font-semibold mb-1">Наименование</h4>
            <form-item name="other_attributes.name_income" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
