import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center mx-auto my-auto max-w-7xl" }
const _hoisted_2 = {
  key: 0,
  class: "dark-guru-loader m-12"
}
const _hoisted_3 = ["src"]

import useColors from '@/07-Shared/model/useColors'
import loaderLight from '@/assets/loader.gif'
const msg = 'Магические операции, подождите пожалуйста'

export default /*@__PURE__*/_defineComponent({
  __name: 'ClearLoader',
  setup(__props) {

const { isDark } = useColors()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isDark))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _unref(loaderLight),
          alt: "load_gif",
          width: "200"
        }, null, 8, _hoisted_3)),
    _createElementVNode("h4", null, _toDisplayString(msg))
  ]))
}
}

})