<script lang="ts" setup>
import CalculatedIncomeTableFilter from '@/components/Admin/CalculatedIncomeTableFilter.vue'
import useIssuersRepository from '@/entityes/invoice/trade_operations/issuers.repository'
import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  declarationId: string | number
  issuers: any
  tPages: number
  cPage: number
}
const props = defineProps<IProps>()
const sort = ref('')
const search = ref('')
const loading = ref(false)
const { getIssuers } = useIssuersRepository()
const { colors, isDark } = useColors()

function changePage(page: number) {
  loading.value = true
  getIssuers(props.declarationId, page, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка эмитентов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tickerFilter() {
  loading.value = true
  getIssuers(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка эмитентов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function sortEvent(val) {
  loading.value = true
  sort.value = val
  getIssuers(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения эмитентов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tableRowClassName(row: any, rowIndex: number) {
  if (row.diff) {
    console.log(row.diff)
  }
  return 'warning-row'
}
</script>

<template>
  <div class="flex justify-between items-center mb-4">
    <el-pagination
      :current-page="props.cPage"
      :total="props.tPages"
      layout="prev, pager, next"
      :page-size="1"
      @current-change="changePage"
      background
      size="small"
    />
    <el-input v-model="search" placeholder="Поиск по эмитентам" style="width: 300px" @input="tickerFilter" />
  </div>
  <el-table
    v-loading="loading"
    :data="props.issuers"
    class="mb-4"
    table-layout="auto"
    :class="loading ? 'table-height-loading' : ''"
    stripe
    border
  >
    <el-table-column label="ticker" fixed width="200">
      <template #default="scope">
        <div class="flex items-center">
          <p class="mr-2" :class="Math.abs(scope.row.diff) > 10 ? 'table-alert-diff' : ''">
            {{ scope.row.ticker }}
          </p>
          <el-icon :color="isDark ? colors.dark.ng_red : colors.light.ng_error" v-if="Math.abs(scope.row.diff) > 10">
            <WarningFilled />
          </el-icon>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="brokers" width="200">
      <template #default="scope">
        <p>{{ scope.row.brokers }}</p>
      </template>
    </el-table-column>
    <el-table-column label="income_currency" width="200">
      <template #default="scope">
        <p>{{ scope.row.income_currency }}</p>
      </template>
    </el-table-column>
    <el-table-column label="expense_currency" width="200">
      <template #default="scope">
        <p>{{ scope.row.expense_currency }}</p>
      </template>
    </el-table-column>
    <el-table-column label="expense_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.expense_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="currency" width="200">
      <template #default="scope">
        <p>{{ scope.row.currency }}</p>
      </template>
    </el-table-column>
    <el-table-column label="income_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.income_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="result_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.result_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="realized_pl" width="200">
      <template #default="scope">
        <p>{{ scope.row.realized_pl }}</p>
      </template>
    </el-table-column>
    <el-table-column label="tax_sum_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.tax_sum_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="total_expenses_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.total_expenses_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="asset_return_without_inflation_after_tax_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.asset_return_without_inflation_after_tax_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="asset_return_without_inflation_with_expenses_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.asset_return_without_inflation_with_expenses_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="asset_return_without_inflation_without_expenses_currency" width="230">
      <template #default="scope">
        <p>
          {{ scope.row.asset_return_without_inflation_without_expenses_currency }}
        </p>
      </template>
    </el-table-column>
    <el-table-column label="asset_return_without_inflation_without_expenses_rub" width="220">
      <template #default="scope">
        <p>
          {{ scope.row.asset_return_without_inflation_without_expenses_rub }}
        </p>
      </template>
    </el-table-column>
    <el-table-column label="effective_tax_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.effective_tax_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="implemented_currency" width="200">
      <template #default="scope">
        <p>{{ scope.row.implemented_currency }}</p>
      </template>
    </el-table-column>
    <el-table-column label="implemented_with_revaluation_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.implemented_with_revaluation_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="implemented_without_revaluation_rub" width="200">
      <template #default="scope">
        <p>{{ scope.row.implemented_without_revaluation_rub }}</p>
      </template>
    </el-table-column>
    <el-table-column label="diff" fixed width="100">
      <template #header>
        <div class="flex items-center justify-between">
          <p>diff</p>
          <calculated-income-table-filter search-label="diff" @search-event="sortEvent" element="sort" />
        </div>
      </template>
      <template #default="scope">
        <div class="flex items-center">
          <p class="mr-2" :class="Math.abs(scope.row.diff) > 10 ? 'table-alert-diff' : ''">
            {{ scope.row.diff }}
          </p>
          <el-icon :color="isDark ? colors.dark.ng_red : colors.light.ng_error" v-if="Math.abs(scope.row.diff) > 10">
            <WarningFilled />
          </el-icon>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    :current-page="props.cPage"
    :total="props.tPages"
    layout="prev, pager, next"
    :page-size="1"
    @current-change="changePage"
    background
    size="small"
    class="mb-4"
  />
</template>

<style scoped lang="sass">
.table-height-loading
  max-height: 700px !important
.table-alert-diff
  @apply dark:text-dark-red text-light-red
</style>
