import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { IInvoice, IPriceObject } from '@/entityes/invoice/invoice.type'
import { computed, reactive, ref, watch } from 'vue'

interface IProps {
  isAdmin: boolean
  years: number[]
  yearToCalc: number
}
interface IEmits {
  (e: 'select-year', value: number): void
  (e: 'select-country', value: string): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'YearSelect',
  props: {
    isAdmin: { type: Boolean },
    years: {},
    yearToCalc: {}
  },
  emits: ["select-year", "select-country"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const yearToCalc = ref(props.yearToCalc || 2024)
const countryToCalc = ref('ru')
const countryList = ['ru', 'uk', 'kz', 'se', 'eu']

watch(yearToCalc, (val) => {
  emit('select-year', val)
})
watch(countryToCalc, (val) => {
  emit('select-country', val)
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-weight--extra text-start mb-1" }, "выберите отчетный год", -1)),
      _createVNode(_component_el_select, {
        modelValue: yearToCalc.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((yearToCalc).value = $event)),
        placeholder: "Выберите год",
        class: "mb-2 min-w-[100px]"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.years, (year) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: year,
              label: year,
              value: year
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (props.isAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-weight--extra text-start mb-1" }, "выберите страну расчета", -1)),
          _createVNode(_component_el_select, {
            modelValue: countryToCalc.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((countryToCalc).value = $event)),
            class: "mb-2 min-w-[100px]"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(countryList, (i) => {
                return _createVNode(_component_el_option, {
                  key: i,
                  label: i,
                  value: i
                }, null, 8, ["label", "value"])
              }), 64))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})