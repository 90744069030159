<script lang="ts" setup>
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { computed, ref, watch } from 'vue'
import { useDeductionCollection } from '../model/useDeductionCollection'
import usePolitic from '@/use/usePolitic'
import useWindowWidth from '@/use/useWindowWidth'
import MessageBoxSelect from '@/07-Shared/ui/MessageBoxSelect.vue'
import { ElMessageBox, ElNotification } from 'element-plus'
import { ValidationAddError } from '@/components/Invoice/Declaration/support/Errors'
import AddSaveButtonGroup from '@/04-Widgets/AdditionelIncome/ui/AddSaveButtonGroup.vue'
import onBoardingDescriber from '@/describers/OnBoardingDescriber'
import { Deduction } from '@/06-Entities/IncomeAndRefund'
import { LimitDeductiontInfo } from '@/05-Features/LimitDeductionInfo'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'

interface IProps {
  invoiceId: string | number
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const goodId = computed(() => invoiceStore.invoice?.goods.Declaration)
const ItemsCollection = useDeductionCollection()
function getItemsFromServer() {
  if (!invoiceStore.invoice || !goodId.value) return
  ItemsCollection.getFromServer(goodId.value)
}
const politics = usePolitic()
const { screenLessValue } = useWindowWidth()

const messageBox = ref<InstanceType<typeof MessageBoxSelect> | null>(null)
function show() {
  messageBox.value?.show()
}

function sendToServer() {
  if (!goodId.value) return
  ItemsCollection.setTouch()
  ItemsCollection.sentToServer(goodId.value)
    .then(() => {
      ElNotification({
        title: 'Дополнительные доходы',
        message: 'успешно сохранены',
      })
      useInvoiceRepository()
        .getInvoice(props.invoiceId)
        .then((res) => {
          useAsyncInvoiceReload()
            .initAsync(res)
            .then(() => {
              getItemsFromServer()
            })
        })
    })
    .catch((err) => {
      if (err instanceof ValidationAddError) {
        ElMessageBox.alert(
          'Вы заполнили не все поля или некорректно ввели данные. Проверьте введенную информацию и повторите попытку.',
          'Ошибка',
          {
            confirmButtonText: 'Понятно',
            type: 'error',
          }
        )
      } else {
        console.error(err)
      }
    })
}
function deleteUnsaveGroup(targetName: string) {
  const msg = `Вы хотите удалить все несохраненные ситуации ${
    ItemsCollection.describers.find((item) => item.tag === targetName)?.name
  }?
      Внесенные данные вернуть будет невозможно.`
  ElMessageBox.confirm(msg, {
    confirmButtonText: 'Да, хочу удалить',
    cancelButtonText: 'Нет, не хочу удалять',
    type: 'warning',
  }).then(() => {
    // wantSet.value = true
    ItemsCollection.deleteUnsaveBySituation(targetName)
  })
}
function deleteGroup(targetName: string) {
  const msg = `Вы хотите удалить все ситуации ${
    ItemsCollection.describers.find((item) => item.tag === targetName)?.name
  }`
  ElMessageBox.confirm(msg, {
    confirmButtonText: 'Да, хочу удалить',
    cancelButtonText: 'Нет, не хочу удалять',
    type: 'warning',
  }).then(() => {
    // wantSet.value = true
    ItemsCollection.deleteBySituation(targetName)
  })
}
watch(
  goodId,
  () => {
    useInvoiceRepository()
      .getInvoice(props.invoiceId)
      .then((res) => {
        useAsyncInvoiceReload()
          .initAsync(res)
          .then(() => {
            getItemsFromServer()
          })
      })
  },
  { immediate: true }
)
const situationList = computed(() =>
  ItemsCollection.availableSituation.value.filter((item) => item.tag !== 'standard_refund')
)

const onBoardingFields = onBoardingDescriber().onBoardingIncomeFields
</script>

<template>
  <div>
    <div class="container-list">
      <component :is="ItemsCollection.meta.headerComponent" />
      <div class="flex items-center my-4">
        <AddSaveButtonGroup
          :want-save="ItemsCollection.edited.value"
          page="Deduction"
          :has-items="ItemsCollection.collection.length > 0"
          @add="show"
          @save="sendToServer"
          @skip="sendToServer"
        />
      </div>
      <LimitDeductiontInfo
        :items="ItemsCollection.collection"
        :yearToCalc="invoiceStore.invoice?.year_to_calc || 2023"
      />
      <el-tabs v-model="ItemsCollection.activeSituation.value">
        <el-tab-pane
          v-for="item in ItemsCollection.showItemDescibersWithHaveElements.value"
          :key="item.name"
          :label="item.name"
          :name="item.tag"
        >
          <div class="flex flex-col items-center justify-between my-4">
            <el-button-group
              v-if="!['standard_refund', 'two_ndfl_files', 'material_losses', 'two_ndfl_source'].includes(item.tag)"
              class="w-full"
            >
              <el-tooltip class="item" effect="dark" :content="`Добавить ситуацию ${item.name}`" placement="top-start">
                <el-button type="primary" :icon="CirclePlusFilled" @click="ItemsCollection.createNew(item.tag)">
                  Добавить
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="`Удалить вcе ситуации несохраненные из ${item.name}`"
                placement="top-start"
                v-if="
                  ItemsCollection.haveUnsaveInActiveSituation.value && politics.isAdmin.value && !screenLessValue(767)
                "
              >
                <el-button plain :icon="RemoveFilled" @click="deleteUnsaveGroup(item.tag)">
                  Удалить все несохраненные
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="`Удалить вcе ситуации из ${item.name}`"
                placement="top-start"
              >
                <el-button :icon="RemoveFilled" type="danger" @click="deleteGroup(item.tag)"> Удалить все </el-button>
              </el-tooltip>
            </el-button-group>
            <div v-for="item in ItemsCollection.itemsForActiveSituation.value" :key="item.localUniqueId" class="w-full">
              <div class="step-box--small my-2">
                <div :class="item.fieldWasEdited ? 'bg-gray' : ''" class="p-4">
                  <component
                    :is="item.getActiveComponent?.cardComponent"
                    :item="item"
                    :key="'item_card_' + item.localUniqueId"
                    @remove="
                      () => {
                        ItemsCollection.deleteById(item.localUniqueId)
                      }
                    "
                    @toggle-edit="
                      () => {
                        item.toggleEdit()
                      }
                    "
                    v-if="Object.keys(item).length > 0"
                  />
                </div>
                <div v-if="item.isEdit">
                  <div>
                    <div class="divider my-2" />
                    <component
                      :is="item.getActiveComponent?.component"
                      :key="'item#' + item.localUniqueId"
                      :item="item"
                      @update-item="
                        (value: Deduction) => {
                          item.onUpdateEvent(value)
                          ItemsCollection.checkLimit(invoiceStore.invoice?.year_to_calc || 2024)
                        }
                      "
                      @delete="() => ItemsCollection.deleteById(item.localUniqueId)"
                      :admin="politics.isAdmin"
                      :good-id="goodId"
                      :year-to-calc="invoiceStore.invoice?.year_to_calc || 2023"
                      :help-describer="item.getActiveComponent?.tag && onBoardingFields[item.getActiveComponent.tag]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <MessageBoxSelect
      ref="messageBox"
      title="Добавление ситуации"
      :value="situationList"
      value-tag="tag"
      label-tag="name"
      @select="ItemsCollection.createNew"
    />
  </div>
</template>

<style lang="sass"></style>
