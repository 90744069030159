import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_2 = {
  key: 1,
  style: {"font-size":"12px","font-weight":"400"}
}
const _hoisted_3 = { class: "flex items-center justify-between mb-4" }
const _hoisted_4 = { class: "font-bold" }

import { IAdminFileInfo } from '@/entityes/invoice/file/file.type'
import useTypeWriter from '@/plugins/useTypeWriter'
import { toRefs } from 'vue'

interface IProps {
  file: IAdminFileInfo
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminRenderFiles',
  props: {
    file: {}
  },
  setup(__props: any) {

const props = __props
const { file } = toRefs(props)
const { renderString } = useTypeWriter('загрузка...')

return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createBlock(_component_el_tag, {
    effect: "plain",
    class: "cursor-point min-h-[60px] card-hover",
    type: _unref(file).is_active ? 'success' : 'danger',
    size: "small"
  }, {
    default: _withCtx(() => [
      (_unref(file).isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_icon, {
              class: "el-icon-loading font-weight--extra mr-2",
              style: {"vertical-align":"middle"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Loading)
              ]),
              _: 1
            }),
            _createElementVNode("p", null, _toDisplayString(_unref(renderString)), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_unref(file).type.split('::')[1]), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(file).created_at.split('T')[0]) + " " + _toDisplayString(_unref(file).created_at.split('T')[1].split('.')[0]), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_unref(file).name), 1)
          ]))
    ]),
    _: 1
  }, 8, ["type"]))
}
}

})