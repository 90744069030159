import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = {
  key: 0,
  class: "font-weight--extra"
}
const _hoisted_5 = { class: "flex items-center mb-4" }
const _hoisted_6 = { class: "ml-2" }
const _hoisted_7 = ["innerHTML"]

import { ref, watch, toRef } from 'vue'
import { ElNotification } from 'element-plus'
import { SetUp } from '@element-plus/icons-vue'
import useTextFilters from '@/plugins/TextFilters'
import useWindowWidth from '@/use/useWindowWidth'

interface IProps {
  tariff: string
  hasBucket: boolean
  personalPrice: number
}
interface IEmits {
  (e: 'on-change-tariff', value: string): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Tariffs',
  props: {
    tariff: {},
    hasBucket: { type: Boolean },
    personalPrice: {}
  },
  emits: ["on-change-tariff"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const currentTariff = toRef(props.tariff || 'base')
const minTariff = ref(false)
const baseTariff = ref(true)
const maxTariff = ref(false)
const showTariffs = ref(false)
const { numberSeparator } = useTextFilters()
const { screenLessValue } = useWindowWidth()

const tariffDescription = [
  {
    tag: 'minimal',
    name: 'Минимальный',
    description: `
      <ul style="list-style-position: outside; list-style-type: disc; line-height: 22px">
        <li class="mb-2">Самостоятельное оформление заказа. Автоматическое формирование документов на основании отчётов брокеров.</li>
        <li class="mb-2">Только полностью самостоятельное внесение дополнительных доходов и вычетов в программе «Декларация» после завершения заказа.</li>
        <li>Нет помощи во время оформления заказа, технической поддержки и сопровождения.</li>
      </ul>
    `,
    model: minTariff,
    class: 'tariff-box-min',
    show: !props.hasBucket,
  },
  {
    tag: 'base',
    name: 'Базовый',
    description: `
      <ul style="list-style-position: outside; list-style-type: disc; line-height: 22px">
        <li class="mb-4">Автоматическое формирование документов на основании отчётов брокеров.</li>
        <li class="mb-4">Возможность дозаказать внесение дополнительных доходов и вычетов в декларацию согласно тарифу.</li>
        <li>Дополнительная ручная проверка документов бухгалтером и сопровождение до окончания камеральной проверки.</li>
      </ul>
    `,
    model: baseTariff,
    class: 'tariff-box-base',
    show: true,
  },
  {
    tag: 'personal',
    name: 'Персональный',
    description: `
      <ul style="list-style-position: outside; list-style-type: disc; line-height: 22px">
        <li class="mb-4">Полностью оформим заказ сами – просто пришлите нам документы удобным вам способом.</li>
        <li>Приоритетная ускоренная подготовка заказа вне очереди и полное сопровождение до окончания камеральной проверки.</li>
      </ul>
    `,
    model: maxTariff,
    class: 'tariff-box-max',
    show: true,
  },
]

function openTariffs() {
  showTariffs.value = true
}
function closeTariffs() {
  emit('on-change-tariff', currentTariff.value)
  showTariffs.value = false
}

watch(currentTariff, () => {
  emit('on-change-tariff', currentTariff.value)
  ElNotification({
    message: `выбран тариф ${tariffDescription.find((i) => i.tag === currentTariff.value)?.name}`,
    type: 'success',
  })
})

function mergeTariff() {
  minTariff.value = props.tariff === 'minimal'
  baseTariff.value = props.tariff === 'base'
  maxTariff.value = props.tariff === 'personal'
}
const selectTariff = (tariff: string) => {
  minTariff.value = tariff === 'minimal'
  baseTariff.value = tariff === 'base'
  maxTariff.value = tariff === 'personal'
  currentTariff.value = tariff
  closeTariffs()
}
openTariffs()
mergeTariff()

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "mb-2" }, "Тариф", -1)),
        _createVNode(_component_el_tooltip, {
          placement: "left",
          content: "Изменить тариф"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "mb-2",
              onClick: openTariffs,
              icon: _unref(SetUp),
              link: "",
              type: "primary",
              size: "small"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Изменить")
              ])),
              _: 1
            }, 8, ["icon"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(tariffDescription.find((i) => i.tag === currentTariff.value)?.name), 1),
        (currentTariff.value === 'personal')
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, " ₽ " + _toDisplayString(_unref(numberSeparator)(props.personalPrice, ' ')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: "Тарифы",
      modelValue: showTariffs.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showTariffs).value = $event)),
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false,
      "append-to-body": "",
      width: "80%"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["grid gap-4", _unref(screenLessValue)(992) ? 'grid-flow-row grid-cols-1' : 'grid-flow-col grid-rows-1'])
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tariffDescription, (item) => {
            return _createElementVNode("div", {
              key: item.tag
            }, [
              (item.show)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["tariff-box", currentTariff.value === item.tag ? item.class : ''])
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(), _createBlock(_component_el_checkbox, {
                        onChange: ($event: any) => (selectTariff(item.tag)),
                        modelValue: item.model.value,
                        "onUpdate:modelValue": ($event: any) => ((item.model.value) = $event),
                        key: currentTariff.value
                      }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])),
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(item.name), 1)
                    ]),
                    _createElementVNode("div", {
                      class: "ml-2",
                      style: {"line-height":"18px"},
                      innerHTML: item.description
                    }, null, 8, _hoisted_7)
                  ], 2))
                : _createCommentVNode("", true)
            ])
          }), 64))
        ], 2)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})