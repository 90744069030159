import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-4" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "font-weight--extra" }

import { computed, toRef } from 'vue'
import LoaderDescriber from '@/06-Entities/File/model/LoaderDescriber'
import useTextFilters from '@/plugins/TextFilters'
import { useRouter } from 'vue-router'
import { BrokerFileType } from '@/entityes/invoice/file/file.type'
import useColors from '@/07-Shared/model/useColors'
import { SetUp } from '@element-plus/icons-vue'

// import { Files } from '@element-plus/icons-vue'

interface IProps {
  brokerFiles: Partial<Record<BrokerFileType, { id: number; name: string }[]>>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ShowOnlyLoadedFiles',
  props: {
    brokerFiles: {}
  },
  setup(__props: any) {

const props = __props
const { subStringFileName } = useTextFilters()
const loaders = LoaderDescriber().describers
const router = useRouter()
const { colors, isDark } = useColors()
const filesInInvoice: any = toRef(props.brokerFiles)

const onlyLoadedFiles = computed(() => {
  return Object.keys(filesInInvoice.value).reduce((acc, key) => {
    if (filesInInvoice.value[key].length > 0) {
      const loader: any = loaders.find((i) => i.config.serverTag === key)
      if (!!loader) {
        loader.loadedFiles = filesInInvoice.value[key]
      }
      acc.push(loader)
    }
    return acc
  }, <any>[])
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, [
        _createElementVNode("h4", { class: "dark:text-dark-purple text-light-purple" }, "Загруженные отчеты")
      ], -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_el_tooltip, {
          placement: "left",
          content: "Изменить файлы брокеров"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              size: "small",
              icon: _unref(SetUp),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'file_in_invoice' }))),
              link: "",
              type: "primary"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" изменить ")
              ])),
              _: 1
            }, 8, ["icon"])
          ]),
          _: 1
        })
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(onlyLoadedFiles.value, (loader, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mb-4"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: loader.config.logo,
            class: "mr-2",
            alt: "broker-icon",
            width: 24
          }, null, 8, _hoisted_3),
          _createElementVNode("p", _hoisted_4, _toDisplayString(loader.config.title), 1)
        ])
      ]))
    }), 128))
  ]))
}
}

})