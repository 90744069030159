<script lang="ts" setup>
import { reactive, ref } from 'vue'
import CalculatedIncomeTableFilter from '@/components/Admin/CalculatedIncomeTableFilter.vue'
import CalculatedExpenses from '@/04-Widgets/TradeOperations/ui/CalculatedExpenses.vue'
import {
  ICalcIncome,
  ICalcIncomeList,
  IIncomeCodes,
  IIncomeOkvs,
} from '@/entityes/invoice/trade_operations/trade_operations.type'
import {
  CirclePlusFilled,
  Operation,
  EditPen,
  Remove,
  CircleCheckFilled,
  InfoFilled,
  Document,
} from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import useTradeOperationsRepository from '@/entityes/invoice/trade_operations/trade_operation.repository'

interface IProps {
  id: string | number
  declarationId: string | number
  calculatedIncomes: ICalcIncomeList
  incomeCodes: IIncomeCodes
  okvs: IIncomeOkvs
  tPages: number
  cPage: number
}

const props = defineProps<IProps>()
const incomesToDelete = reactive<ICalcIncome[]>([])
const currentEditingRow = ref<any>(undefined)
const calculatedExpenses = ref<InstanceType<typeof CalculatedExpenses> | null>(null)
const filter = ref('')
const sort = ref('')
const search = ref('')
const loading = ref(false)
const { calculatedIncomesInit, updateCalcIncome, destroyCalcIncome, createIncome, deleteSelectedRows } =
  useTradeOperationsRepository()

const chooseToBulkDelete = (val: ICalcIncome[]) => {
  incomesToDelete.splice(0, incomesToDelete.length, ...val.map((i) => i.id))
}
const filterEvent = (val: string) => {
  loading.value = true
  filter.value = val
  calculatedIncomesInit(props.id, 1, search.value, filter.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса фильтрации ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function openIncomeEditor(val: Record<string, string>) {
  if (currentEditingRow.value) currentEditingRow.value.showEditor = false
  val.showEditor = true
  currentEditingRow.value = val
}
function closeIncomeEditor() {
  if (currentEditingRow.value === undefined) return
  currentEditingRow.value.showEditor = false
  currentEditingRow.value = undefined
}
function sortEvent(val: string) {
  loading.value = true
  sort.value = val
  calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `получения списка доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function deleteSelectedIncomes() {
  loading.value = true
  deleteSelectedRows(incomesToDelete)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'удалены сделки',
        type: 'success',
      })
      calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
        .catch((err) => {
          ElNotification({
            title: 'Ошибка',
            message: `получения списка доходов ${err}`,
            type: 'error',
          })
        })
        .finally(() => (loading.value = false))
    })
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `удаления сделок ${err}`,
        type: 'error',
      })
    })
  // emit('delete-incomes', incomesToDelete)
}
function showExpensesDialog(id: string | number, ticker: string) {
  calculatedExpenses.value.openDialog(id, ticker)
}
function create() {
  loading.value = true
  createIncome(props.declarationId)
    .then(() => {
      calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
        .finally(() => (loading.value = false))
        .then(() => {
          ElNotification({
            title: 'Успешно',
            message: `создан новый доход`,
            type: 'success',
          })
        })
    })
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `создания дохода | ${err}`,
        type: 'error',
      })
    })
}
function update(income: ICalcIncome) {
  loading.value = true
  updateCalcIncome(income).then(() => {
    ElNotification({
      title: 'Успешно',
      message: `обновлена сделка ${income.ticker_description}`,
      type: 'success',
    })
    calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `запроса фильтрации ${err}`,
          type: 'error',
        })
      })
      .finally(() => (loading.value = false))
  })
}
function destroy(row: ICalcIncome) {
  loading.value = true
  destroyCalcIncome(row.id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `удален доход ${row.id}`,
        type: 'success',
      })
      calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value).finally(
        () => (loading.value = false)
      )
    })
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `удаления дохода ${row.id} | ${err}`,
        type: 'error',
      })
    })
}
function changePage(page: number | string) {
  loading.value = true
  calculatedIncomesInit(props.id, page, search.value, filter.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tickerFilter(ticker: string) {
  loading.value = true
  search.value = ticker
  calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса поиска ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
</script>

<template>
  <div v-loading="loading">
    <div class="flex items-center justify-between mb-4">
      <el-pagination
        :current-page="props.cPage"
        :total="props.tPages"
        layout="prev, pager, next"
        :page-size="1"
        @current-change="changePage"
        background
        size="small"
      />
      <el-input v-model="search" placeholder="Поиск по доходам" style="width: 300px" @input="tickerFilter" />
    </div>
    <div>
      <el-table
        :data="props.calculatedIncomes"
        class="mb-4"
        table-layout="auto"
        stripe
        border
        @keyup.esc="closeIncomeEditor"
        @keyup.enter="update(currentEditingRow)"
        tabindex="-1"
        @selection-change="chooseToBulkDelete"
        @cell-click="openIncomeEditor"
        v-loading="loading"
        :class="loading ? 'table-height-loading' : ''"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column fixed label="ticker" width="140">
          <template #header>
            <div class="flex items-center justify-between">
              <p>ticker</p>
              <calculated-income-table-filter
                search-label="ticker_description"
                @search-event="sortEvent"
                element="sort"
              />
            </div>
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.ticker_description" />
            <p class="font-weight--extra" v-else>
              {{ scope.row.ticker_description }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="140">
          <template #header>
            <div class="flex items-center justify-between">
              <p>currency sum</p>
              <calculated-income-table-filter search-label="currency_sum" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.currency_sum" />
            <p v-else>{{ scope.row.currency_sum }}</p>
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template #header>
            <div class="flex items-center justify-between">
              <p>date</p>
              <calculated-income-table-filter search-label="date" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-date-picker
              v-if="scope.row.showEditor"
              v-model="scope.row.date"
              type="datetime"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="YYYY-MM-DD"
              size="small"
              style="width: 160px !important"
            />
            <div v-else>
              <p>
                <span class="font-weight--semi">{{ scope.row?.date?.split('T')[0] }}</span>
                {{ scope.row?.date?.split('T')[1]?.split('+')[0]?.split('.')[0] }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="income code" width="120">
          <template #header>
            <div>income code</div>
            <calculated-income-table-filter
              search-label="income_code"
              :setup-value="incomeCodes"
              @search-event="filterEvent"
              element="filter"
            />
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.income_code" />
            <p v-else>{{ scope.row.income_code }}</p>
          </template>
        </el-table-column>
        <el-table-column label="oksm_country" width="126">
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.oksm_country" />
            <p v-else>{{ scope.row.oksm_country }}</p>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template #header>
            <p>okv country</p>
            <calculated-income-table-filter
              search-label="okv_country"
              :setup-value="okvs"
              @search-event="filterEvent"
              element="filter"
            />
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.okv_country" />
            <p v-else>{{ scope.row.okv_country }}</p>
          </template>
        </el-table-column>
        <el-table-column label="price" width="140">
          <template #header>
            <div class="flex items-center justify-between">
              <p>price</p>
              <calculated-income-table-filter search-label="price" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.price" />
            <p v-else>{{ scope.row.price }}</p>
          </template>
        </el-table-column>
        <el-table-column label="qty" width="100">
          <template #header>
            <div class="flex items-center justify-between">
              <p>qty</p>
              <calculated-income-table-filter search-label="qty" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.qty" />
            <p v-else>{{ scope.row.qty }}</p>
          </template>
        </el-table-column>
        <el-table-column label="rub_sum" width="140">
          <template #header>
            <div class="flex items-center justify-between">
              <p>rub_sum</p>
              <calculated-income-table-filter search-label="rub_sum" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.rub_sum" />
            <p v-else>{{ scope.row.rub_sum }}</p>
          </template>
        </el-table-column>
        <el-table-column label="tax_curr_paid" width="140">
          <template #header>
            <div class="flex items-center justify-between">
              <p>tax_curr_paid</p>
              <calculated-income-table-filter search-label="tax_curr_paid" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-input size="small" v-if="scope.row.showEditor" v-model="scope.row.tax_currency_paid" />
            <p v-else>{{ scope.row.tax_currency_paid }}</p>
          </template>
        </el-table-column>
        <el-table-column label="tax_date" width="180">
          <template #header>
            <div class="flex items-center justify-between">
              <p>tax_date</p>
              <calculated-income-table-filter search-label="tax_date" @search-event="sortEvent" element="sort" />
            </div>
          </template>
          <template #default="scope">
            <el-date-picker
              v-if="scope.row.showEditor"
              v-model="scope.row.tax_date"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="YYYY-MM-DD"
              size="small"
              style="width: 160px !important"
            />
            <p v-else>
              <span class="font-weight--semi">{{ scope.row?.date?.split('T')[0] }}</span>
              {{ scope.row?.date?.split('T')[1]?.split('+')[0]?.split('.')[0] }}
            </p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="40">
          <template #header>
            <div class="flex items-center">
              <el-tooltip
                v-if="incomesToDelete.length > 0"
                class="box-item"
                effect="dark"
                content="Удалить все"
                placement="left"
              >
                <el-button @click="deleteSelectedIncomes" type="danger" size="small" :icon="Remove" />
              </el-tooltip>
              <el-tooltip class="box-item" effect="dark" content="Создать доход" placement="left" v-else>
                <el-button :icon="CirclePlusFilled" type="primary" @click="create" />
              </el-tooltip>
            </div>
          </template>
          <template #default="scope">
            <el-dropdown>
              <el-button type="info" :icon="Operation" size="small" />
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="showExpensesDialog(scope.row.id, scope.row.ticker_description)"
                    :disabled="scope.row.showEditor"
                    class="p-0 py-2"
                  >
                    <el-tooltip disabled class="box-item" effect="dark" content="Расходы" placement="top">
                      <el-button
                        type="primary"
                        size="small"
                        text
                        :disabled="scope.row.showEditor"
                        :icon="Document"
                        class="w-full"
                      >
                        Расходы
                      </el-button>
                    </el-tooltip>
                  </el-dropdown-item>
                  <el-dropdown-item class="p-0 py-2">
                    <el-popover placement="left" title="Инфо о доходе" :width="300" trigger="hover">
                      <template #reference>
                        <el-button
                          :icon="InfoFilled"
                          :disabled="scope.row.showEditor"
                          text
                          size="small"
                          type="primary"
                          class="w-full"
                        >
                          Доп информация
                        </el-button>
                      </template>
                      <template #default>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">id:</p>
                          <p class="font-weight--extra">{{ scope.row.id }}</p>
                        </div>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">broker:</p>
                          <p class="font-weight--extra">{{ scope.row.broker }}</p>
                        </div>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">currency_repricing:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.currency_repricing }}
                          </p>
                        </div>
                        <div class="mb-2">
                          <p class="mr-2 font-bold font-color--purple">source_file_name:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.source_file_name }}
                          </p>
                        </div>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">tax_rate:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.tax_rate }}
                          </p>
                        </div>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">tax_rub_paid:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.tax_rub_paid }}
                          </p>
                        </div>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">tax_to_pay %:</p>
                          <p class="font-weight--extra margin-h--left-s">
                            {{ scope.row.tax_to_pay_percent }}
                          </p>
                        </div>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">tax_withhold %:</p>
                          <p class="font-weight--extra margin-h--left-s">
                            {{ scope.row.tax_withhold_percent }}
                          </p>
                        </div>
                      </template>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item class="p-0 py-2">
                    <el-button
                      :icon="EditPen"
                      text
                      size="small"
                      type="primary"
                      :disabled="scope.row.showEditor"
                      class="w-full"
                      @click="openIncomeEditor"
                    >
                      Редактировать
                    </el-button>
                    <el-button
                      text
                      :icon="CircleCheckFilled"
                      class="w-full"
                      @click="update(scope.row)"
                      size="small"
                      type="success"
                      v-if="scope.row.showEditor"
                    >
                      Сохранить
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.showEditor" @click="closeIncomeEditor" class="p-0 py-2">
                    <el-button class="w-full" type="danger" text :icon="Remove" size="small"> Отменить </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item class="px-0 py-2" @click="destroy(scope.row)" :disabled="scope.row.showEditor">
                    <el-button
                      :disabled="scope.row.showEditor"
                      text
                      :icon="Remove"
                      type="danger"
                      size="small"
                      class="w-full"
                    >
                      Удалить
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="props.tPages === 1"
        :current-page="props.cPage"
        :total="props.tPages"
        layout="prev, pager, next"
        :page-size="1"
        @current-change="changePage"
        background
        size="small"
      />
      <calculated-expenses ref="calculatedExpenses" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.table-height-loading
  max-height: 700px !important
</style>
