import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import PaymentPage from '@/04-Widgets/GoodsSelection/PaymentPage.vue'

interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoodSelectionPage',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const invoiceRepository = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()

function init() {
  return invoiceRepository.getInvoice(props.id).then((invoice) => {
    initAsync(invoice)
  })
}
init()

return (_ctx: any,_cache: any) => {
  return (!!_unref(invoiceStore).invoice)
    ? (_openBlock(), _createBlock(PaymentPage, {
        key: 0,
        invoice: _unref(invoiceStore).invoice
      }, null, 8, ["invoice"]))
    : _createCommentVNode("", true)
}
}

})