import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { CirclePlusFilled, UploadFilled } from '@element-plus/icons-vue'

interface IProps {
  wantSave: boolean
  isLoading?: boolean
  page: 'Deduction' | 'AddIncomes'
  hasItems: boolean
}

interface IEmits {
  (e: 'add'): void
  (e: 'save'): void
  (e: 'skip'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddSaveButtonGroup',
  props: {
    wantSave: { type: Boolean },
    isLoading: { type: Boolean },
    page: {},
    hasItems: { type: Boolean }
  },
  emits: ["add", "save", "skip"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createBlock(_component_el_button_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        icon: _unref(CirclePlusFilled),
        type: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add'))),
        class: "button-main",
        loading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.page === 'Deduction' ? 'Добавить вычет' : 'Добавить доход'), 1)
        ]),
        _: 1
      }, 8, ["icon", "loading"]),
      (_ctx.wantSave)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            class: "item",
            effect: "dark",
            content: _ctx.page === 'Deduction' ? 'Сохранить вычеты' : 'Сохранить дополнительные доходы',
            placement: "top-start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                icon: _unref(UploadFilled),
                type: "success",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('save'))),
                class: "button-main",
                loading: _ctx.isLoading
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Сохранить ")
                ])),
                _: 1
              }, 8, ["icon", "loading"])
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})