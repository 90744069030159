<script setup lang="ts">
import useMenuDescription from '@/describers/MenuDescriber'
import { computed, ref } from 'vue'
import NgAdminMenu from '@/07-Shared/ui/NgAdminMenu.vue'
import usePolitic from '@/use/usePolitic'
import { useRouter } from 'vue-router'
import Finder from '@/07-Shared/ui/Finder.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { Menu, PhoneFilled, Sunny, Moon } from '@element-plus/icons-vue'
import { useDark, useToggle } from '@vueuse/core'
import useColors from '@/07-Shared/model/useColors'

const { accountMenuDescriptor, phone } = useMenuDescription()
const bottomMenu = computed(() => accountMenuDescriptor.value.filter((_, index) => index !== 0))
const { isAdmin, isPartner } = usePolitic()
const router = useRouter()
const invoiceVideo = ref(null)
const isDark = useDark()
const toggleTheme = useToggle(isDark)
const { colors } = useColors()
// const open = () => {
//   invoiceVideo.value.openVideoModal()
// }
</script>

<template>
  <div class="left-menu-wrapper bg-gray p-2">
    <div>
      <div class="mb-4 flex flex-wrap justify-between flex-col">
        <div class="flex items-center justify-between mb-4">
          <router-link to="/">
            <div>
              <div v-if="isDark" class="flex items-center">
                <img class="mr-2" src="../../assets/guru_logo.svg" alt="logo" width="32" />
                <img src="../../assets/guru_name.svg" class="logo_name" alt="logo" width="100" />
              </div>
              <img v-else src="../../assets/new_logo.svg" alt="logo" width="150" />
            </div>
          </router-link>
          <el-button :icon="isDark ? Moon : Sunny" size="small" type="primary" @click="toggleTheme()" />
        </div>
        <Finder v-if="isAdmin || isPartner" />
        <div v-if="isAdmin" class="cursor-point clickable">
          <el-popover placement="right" :width="200" trigger="hover">
            <template #reference>
              <div class="flex items-center">
                <el-icon :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple" class="mr-2" :size="18">
                  <Menu />
                </el-icon>
                <p>Меню админа</p>
              </div>
            </template>
            <NgAdminMenu />
          </el-popover>
        </div>
      </div>
      <div
        class="flex items-center clickable cursor-point mb-2"
        @click="accountMenuDescriptor[0].clickAction"
        :class="
          router.currentRoute.value.name === 'account home' ? 'bg-purple font-color--white font-weight--semi' : ''
        "
      >
        <el-icon :size="18" class="mr-2">
          <component :is="accountMenuDescriptor[0].icon" />
        </el-icon>
        <p>
          {{ accountMenuDescriptor[0].title }}
        </p>
      </div>
      <div class="el-divider-purple mb-4" />
    </div>
    <slot>
      <div :class="isAdmin || isPartner ? 'left-menu-admin' : 'left-menu-slot'"></div>
    </slot>
    <div class="bg-gray">
      <div>
        <a :href="`tel:+${phone}`">
          <div class="mb-4 clickable flex items-center">
            <el-icon :size="18" class="mr-2">
              <PhoneFilled />
            </el-icon>
            <p>{{ phone }}</p>
          </div>
        </a>
      </div>
      <div class="el-divider-purple mb-4" />
      <div>
        <div
          v-for="(item, index) in bottomMenu"
          :key="item.title"
          @click="item.clickAction"
          class="flex items-center cursor-point clickable mb-4"
          :class="
            router.currentRoute.value.name === 'customer show' && index === 0
              ? 'bg-purple font-color--white font-weight--semi'
              : ''
          "
        >
          <el-icon :size="18" class="mr-2">
            <component :is="item.icon" />
          </el-icon>
          <p>
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <NgInvoiceVideo
    ref="invoiceVideo"
    :show-button="false"
    :video-link="'https://www.youtube.com/embed/o3S17yaUvTI?enablejsapi=1'"
  />
</template>

<style scoped lang="sass"></style>
