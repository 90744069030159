<script setup lang="ts">
import { useStepper } from '@/entityes/invoice/useStepper'
import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { computed, watch, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import LeftSideBar from '@/07-Shared/ui/LeftSideBar.vue'
import InvoiceSidebarMenu from '@/components/Invoice/InvoiceSidebarMenu.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import InvoiceErrWarning from '@/07-Shared/ui/InvoiceErrWarning.vue'
import AdminInvoiceRemote from '@/05-Features/AdminInvoiceRemote/ui/AdminInvoiceRemote.vue'
import usePolitic from '@/use/usePolitic'
import { useWs } from '@/socket/ws'
import useNotificationsChannel from '@/socket/channels/useNotificatioinsChannel'
import MobileMenu from '@/07-Shared/ui/MobileMenu.vue'
import NGThankUPage from '@/components/NGThankUPage.vue'
import useWindowWidth from '@/use/useWindowWidth'

const invoiceRepository = useInvoiceRepository()
const stepper = useStepper()
const { initAsync, hasTimer } = useAsyncInvoiceReload()
const { isAdmin } = usePolitic()
const { screenLessValue } = useWindowWidth()

function init(id: string | string[]) {
  invoiceRepository
    .getInvoice(id as string)
    .then((inv) => {
      return initAsync(inv)
    })
    .then((res) => {
      const haveTms = res.tms
        ? stepper.checkingForTms(
            Object.entries(res.tms)
              .filter((item) => item[1])
              .map((item) => item[0] as DeclarationAction)
          )
        : false
      stepper.selectStep(res.status.invoice_status, haveTms)
    })
}

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
watch(invoiceId, init, { immediate: true })
const invoiceStore = useInvoiceStore()

const ws = useWs()
function socketInit() {
  ws.open()
  useNotificationsChannel(ws)
}

socketInit()
onUnmounted(() => {
  ws.close()
})
</script>

<template>
  <div class="container">
    <el-row :gutter="10">
      <el-col :xs="24" :md="24" :lg="4">
        <mobile-menu v-if="screenLessValue(1201)">
          <InvoiceSidebarMenu
            :invoice-id="invoiceId"
            :show-loader="invoiceRepository.getInvoice.indicator.value"
            :invoice-payed="invoiceStore.invoice?.status.invoice_status === 'payed'"
          />
        </mobile-menu>
        <LeftSideBar v-else>
          <InvoiceSidebarMenu
            :invoice-id="invoiceId"
            :show-loader="invoiceRepository.getInvoice.indicator.value"
            :invoice-payed="invoiceStore.invoice?.status.invoice_status === 'payed'"
          />
        </LeftSideBar>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="20" class="py-4">
        <admin-invoice-remote class="mb-8" v-if="!!invoiceStore.invoice && isAdmin" :key="invoiceStore.invoice?.id" />
        <!--      <main-banner />-->
        <InvoiceErrWarning />
        <ClearLoader v-show="hasTimer || invoiceStore.invoice?.status.async" />
        <RouterView v-show="!(hasTimer || invoiceStore.invoice?.status.async)" />
      </el-col>
    </el-row>
    <div v-if="!!invoiceStore.invoice">
      <n-g-thank-u-page :invoice="invoiceStore.invoice" />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
