<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceInfo } from '@/use/AdminInfoTabs/useInvoiceInfo'
import BrokerAccountInfo from './BrokerAccountInfo.vue'
import { computed } from 'vue'
import { Loading } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'

const invoiceStore = useInvoiceStore()
const { info } = useInvoiceInfo(invoiceStore.invoice!)
const asyncInvoice = computed(() => invoiceStore.invoice?.status.async)
const amountOperations = computed(() => invoiceStore.invoice?.amount_operations?.Declaration)
const brokerAccounts = computed(() => invoiceStore.invoice?.broker_accounts)
const { colors, isDark } = useColors()
</script>

<template>
  <el-row>
    <el-col
      v-for="item in info"
      :key="item.title"
      :xs="24"
      :sm="item.adaptive.sm"
      :md="item.adaptive.md"
      :lg="item.adaptive.lg"
    >
      <h4>{{ item.title }}</h4>
      <div v-if="item.title === 'Статус'" class="flex items-center">
        <el-icon
          :color="isDark ? colors.dark.ng_red : colors.light.ng_error"
          class="is-loading mr-2"
          size="18"
          v-if="asyncInvoice"
          ><Loading
        /></el-icon>
        <p
          class="font-weight--extra"
          :class="asyncInvoice ? 'dark:text-dark-red text-light-red' : 'dark:text-dark-green text-light-green'"
        >
          {{ asyncInvoice ? 'раcчeт' : 'посчитан' }}
        </p>
      </div>
      <div v-if="item.title === 'Сложность'">
        <p :class="item.value.value ? 'dark:text-dark-red text-light-red' : 'dark:text-dark-green text-light-green'">
          {{ item.value.value ? 'сложный' : 'простой' }}
        </p>
      </div>
      <div v-if="item.title !== 'Сложность' && item.title !== 'Статус'">
        <p>{{ item.value.value }}</p>
      </div>
    </el-col>
    <el-col :xs="24" :sm="4" :md="3" :lg="3">
      <h4>Кол-во операций</h4>
      <div v-for="(operations, years) in amountOperations" :key="years">
        <span>{{ years }} год: </span>
        {{ operations }}
      </div>
    </el-col>
    <el-col :xs="24" :sm="3" :md="3" :lg="3">
      <h4>Номера счетов</h4>
      <broker-account-info :broker-account="brokerAccounts" />
    </el-col>
  </el-row>
</template>

<style lang="sass" scoped></style>
