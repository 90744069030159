import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-8" }
const _hoisted_2 = {
  class: "flex items-center cursor-point",
  style: {"width":"200px"}
}

import useColors from '@/07-Shared/model/useColors'


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderPreview',
  setup(__props) {

const { colors, isDark } = useColors()

return (_ctx: any,_cache: any) => {
  const _component_QuestionFilled = _resolveComponent("QuestionFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createStaticVNode("<h1 class=\"mb-4\">Налоговые вычеты</h1><p class=\"mb-4\">Налоговые вычеты — это сумма, которая уменьшает размер дохода, с которого уплачивается налог.</p><p class=\"mb-4\">Либо это возврат части ранее уплаченного налога на доходы физического лица,</p><p class=\"mb-4\">например, в связи с покупкой квартиры, расходами на лечение, обучение и т.д.</p><p class=\"mb-4 font-weight--semi\"> Чтобы получить налоговые вычеты (социальные, ИИС по типу А, имущественные) необходимо иметь Доходы по Основной налоговой базе (по заработной плате, от продажи имущества и/или сдачи имущества в аренду и т.п.). </p><p class=\"mb-4 font-weight--semi\">Если таких доходов нет, то вычеты получить нельзя.</p>", 6)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_popover, {
        placement: "right",
        trigger: "hover",
        width: 300
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_icon, {
              color: _unref(isDark) ? _unref(colors).dark.ng_green : _unref(colors).light.ng_purple,
              class: "mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_QuestionFilled)
              ]),
              _: 1
            }, 8, ["color"]),
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-color--green font-weight--semi" }, "Как получить вычеты", -1))
          ])
        ]),
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { style: {"word-break":"break-word"} }, [
            _createElementVNode("p", { class: "mb-4" }, "Загрузить справку о доходах (ранее 2-НДФЛ) на странице Дополнительные доходы;"),
            _createElementVNode("p", { class: "mb-4" }, "Либо заполнить на странице Дополнительные доходы данные по ситуациям:"),
            _createElementVNode("p", null, " Аренда недвижимого имущества, Продажа транспортного средства, Продажа недвижимого имущества, Переуступка права требования по договору долевого участия. ")
          ], -1)
        ])),
        _: 1
      })
    ])
  ], 64))
}
}

})