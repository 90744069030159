import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
  ICalcExpenseList,
  ICalcIncomeList,
  IIncomeCodes,
  IIncomeOkvs,
} from '@/entityes/invoice/trade_operations/trade_operations.type'
import { PaginationList } from '@/types/paginationList'

export const useTradeOperationsStore = defineStore('trade operations store', () => {
  const calculatedIncomes = ref<ICalcIncomeList | undefined>(undefined)
  const incomeCodes = ref<IIncomeCodes | undefined>(undefined)
  const incomeOkvs = ref<IIncomeOkvs | undefined>(undefined)
  const incomesPagination = ref<PaginationList | undefined>(undefined)
  const expenses = ref<ICalcExpenseList | undefined>(undefined)

  const commonExpenses = ref<any>(undefined)
  const commonExpensesPagination = ref<PaginationList | undefined>(undefined)

  const issuers = ref(undefined)
  const issuersPagination = ref<PaginationList | undefined>(undefined)

  return {
    calculatedIncomes,
    incomeCodes,
    incomeOkvs,
    expenses,
    commonExpenses,
    incomesPagination,
    commonExpensesPagination,
    issuers,
    issuersPagination,
  }
})
