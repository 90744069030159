import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "font-weight--extra" }
const _hoisted_4 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_5 = {
  key: 1,
  class: "font-color--red"
}

import { computed, UnwrapNestedRefs } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
}
interface IEmits {
  (e: 'toggle'): void
  (e: 'remove'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TwoNdflCard',
  props: {
    item: {}
  },
  emits: ["toggle", "remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const { screenLessValue } = useWindowWidth()

const loadedFileSize = computed<number>(() => {
  return (
    (props.item.item?.two_ndfl_files?.length || 0) +
    (props.item.item?.other_attributes?.two_ndfl_files?.files?.length || 0)
  )
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 8,
        md: 8,
        lg: 12,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--deep-gray mb-4" }, "Загруженные справки", -1)),
          (loadedFileSize.value > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.item.two_ndfl_files, (value, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: "mr-2"
                  }, _toDisplayString(value?.name), 1))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
                _createElementVNode("p", null, "Нет загруженных справок", -1)
              ])))
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 8,
        md: 8,
        lg: 8,
        class: "mb-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, [
            (_ctx.item?.valueFromServer && !_ctx.item.fieldWasEdited)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Доход сохранен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, "Доход не сохранен"))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: { offset: 0, span: 24 },
        md: { offset: 0, span: 24 },
        lg: { offset: 1, span: 3 },
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _createVNode(EditSaveCardButtonGroup, {
            onToggle: _ctx.item.toggleEdit,
            onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove')))
          }, null, 8, ["onToggle"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})