export function useBrokerName() {
  const brokerNames: Record<string, string> = {
    interactive_brokers_files: 'Interactive Brokers',
    freedom_finance_files: 'Freedom Finance',
    freedom_finance_global_files: 'FF Global',
    tinkoff_files: 'Tinkoff',
    julius_bar_files: 'Julius Baer',
    guru_files: 'Guru Files',
  }
  return {
    brokerNames,
  }
}
