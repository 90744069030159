<script lang="ts" setup>
import { ref } from 'vue'
import useTradeOperationsRepository from '@/entityes/invoice/trade_operations/trade_operation.repository'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, Operation, EditPen, Remove, CircleCheckFilled, Document } from '@element-plus/icons-vue'
import { ICalcExpense } from '@/entityes/invoice/trade_operations/trade_operations.type'

const dialogIsOpen = ref(false)
const incomeId = ref<string | number>('')
const currentTicker = ref('')
const expensesToDelete = ref<Record<string, string | number>[]>([])
const { requestExpenses, updateExpense, destroyExpense, createExpense, deleteSelectedExpenses } =
  useTradeOperationsRepository()
const toStore = useTradeOperationsStore()
const loading = ref(false)
const currentEditingRow = ref<any>(undefined)

function openDialog(id: number | string, ticker: string) {
  dialogIsOpen.value = true
  incomeId.value = id
  currentTicker.value = ticker
  loading.value = true
  requestExpenses(id)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function update(expense: ICalcExpense) {
  loading.value = true
  updateExpense(expense)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `обновлен расход ${expense.id}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `обновления расхода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function destroy(expense: ICalcExpense) {
  destroyExpense(expense.id)
    .then(() => {
      requestExpenses(incomeId.value)
    })
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `удален расход ${expense.id}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления расхода ${expense.id} | ${err}`,
        type: 'error',
      })
    })
}
function addExpense() {
  loading.value = true
  createExpense(incomeId.value)
    .then(() => {
      requestExpenses(incomeId.value).then(() => {
        ElNotification({
          title: 'Успешно',
          message: `создан расход`,
          type: 'success',
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `создания расхода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function chooseToBulkDelete(val) {
  expensesToDelete.value.splice(0, expensesToDelete.value.length, ...val.map((i) => i.id))
}
function bulkDeleteExpenses() {
  loading.value = true
  deleteSelectedExpenses(expensesToDelete.value)
    .then(() => {
      requestExpenses(incomeId.value)
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function openIncomeEditor(val: Record<string, string>) {
  if (currentEditingRow.value) currentEditingRow.value.showEditor = false
  val.showEditor = true
  currentEditingRow.value = val
}
function closeIncomeEditor() {
  if (currentEditingRow.value === undefined) return
  currentEditingRow.value.showEditor = false
  currentEditingRow.value = undefined
}
defineExpose({ openDialog })
</script>

<template>
  <el-dialog :close-on-press-escape="false" v-model="dialogIsOpen" width="70%">
    <h2 class="mb-8">
      Расходы <span class="font-color--purple font-weight--extra margin-h--left-s">{{ currentTicker }}</span>
    </h2>
    <div class="mb-8" v-if="!!toStore.expenses">
      <el-table
        :data="toStore.expenses"
        class="mb-8"
        max-height="800"
        table-layout="auto"
        stripe
        border
        tabindex="-1"
        @selection-change="chooseToBulkDelete"
        @cell-click="openIncomeEditor"
        @keyup.esc="closeIncomeEditor"
        @keyup.enter="update(currentEditingRow)"
        v-loading="loading"
        :class="loading ? 'table-height-loading' : ''"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column fixed width="200" label="description">
          <template #default="scope">
            <el-input v-if="scope.row.showEditor" v-model="scope.row.xlsx_description" size="small" />
            <p v-else>{{ scope.row.xlsx_description }}</p>
          </template>
        </el-table-column>
        <el-table-column label="date" width="180">
          <template #default="scope">
            <el-date-picker
              v-if="scope.row.showEditor"
              v-model="scope.row.date"
              type="datetime"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="YYYY-MM-DD"
              size="small"
            />
            <div v-else>
              <p class="font-weight--semi">{{ scope.row?.date?.split('T')[0] }}</p>
              <p>{{ scope.row?.date?.split('T')[1]?.split('+')[0]?.split('.')[0] }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="expense code" width="120">
          <template #default="scope">
            <el-input v-if="scope.row.showEditor" v-model="scope.row.code" size="small" />
            <p v-else>{{ scope.row.code }}</p>
          </template>
        </el-table-column>
        <el-table-column label="qty" width="120">
          <template #default="scope">
            <el-input v-if="scope.row.showEditor" v-model="scope.row.qty" size="small" />
            <p v-else>{{ scope.row.qty }}</p>
          </template>
        </el-table-column>
        <el-table-column label="currency_sum" width="140" prop="currency_sum">
          <template #default="scope">
            <el-input v-if="scope.row.showEditor" v-model="scope.row.currency_sum" size="small" />
            <p v-else>{{ scope.row.currency_sum }}</p>
          </template>
        </el-table-column>
        <el-table-column label="okv_country" width="140">
          <template #default="scope">
            <el-input v-if="scope.row.showEditor" v-model="scope.row.okv_country" size="small" />
            <p v-else>{{ scope.row.okv_country }}</p>
          </template>
        </el-table-column>
        <el-table-column label="rub_sum" width="140">
          <template #default="scope">
            <el-input v-if="scope.row.showEditor" v-model="scope.row.rub_sum" size="small" />
            <p v-else>{{ scope.row.rub_sum }}</p>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template #header>
            <div class="flex items-center">
              <el-tooltip
                v-if="expensesToDelete.length > 0"
                class="box-item"
                effect="dark"
                content="Удалить все"
                placement="left"
              >
                <el-button :loading="loading" type="danger" @click="bulkDeleteExpenses" :icon="Remove" size="small" />
              </el-tooltip>
              <el-tooltip class="box-item" effect="dark" content="Создать расход" placement="left" v-else>
                <el-button :icon="CirclePlusFilled" :loading="loading" type="primary" @click="addExpense" />
              </el-tooltip>
            </div>
          </template>
          <template #default="scope">
            <el-dropdown>
              <el-button type="info" :icon="Operation" size="small" />
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item class="p-0 py-2" :disabled="scope.row.showEditor">
                    <el-popover
                      :disabled="scope.row.showEditor"
                      placement="left"
                      title="Инфо о расходе"
                      :width="300"
                      trigger="hover"
                    >
                      <template #reference>
                        <el-button
                          :disabled="scope.row.showEditor"
                          text
                          class="w-full"
                          :icon="Document"
                          size="small"
                          type="primary"
                        >
                          Доп информация
                        </el-button>
                      </template>
                      <template #default>
                        <div class="flex items-center mb-2">
                          <p class="mr-2 font-bold font-color--purple">id:</p>
                          <p class="font-weight--extra">{{ scope.row.id }}</p>
                        </div>
                        <div class="flex items-center">
                          <p class="mr-2 font-bold font-color--purple">rate:</p>
                          <p class="font-weight--extra">{{ scope.row.rate }}</p>
                        </div>
                        <div>
                          <p class="mr-2 font-bold font-color--purple">source_file_name:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.source_file_name }}
                          </p>
                        </div>
                        <div class="flex items-center">
                          <p class="mr-2 font-bold font-color--purple">created_at:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.created_at }}
                          </p>
                        </div>
                        <div class="flex items-center">
                          <p class="mr-2 font-bold font-color--purple">updated_at:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.updated_at }}
                          </p>
                        </div>
                        <div class="flex items-center">
                          <p class="mr-2 font-bold font-color--purple">calculated_income_id:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.calculated_income_id }}
                          </p>
                        </div>
                        <div class="flex items-center">
                          <p class="mr-2 font-bold font-color--purple">first_calculation:</p>
                          <p class="font-weight--extra">
                            {{ scope.row.first_calculation }}
                          </p>
                        </div>
                      </template>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item class="p-0 py-2" :disabled="scope.row.showEditor" @click="openIncomeEditor">
                    <el-button
                      :disabled="scope.row.showEditor"
                      text
                      size="small"
                      type="primary"
                      :icon="EditPen"
                      class="w-full"
                    >
                      Редактировать
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item class="p-0 py-2" v-if="scope.row.showEditor" @click="update(scope.row)">
                    <el-button text class="w-full" :icon="CircleCheckFilled" size="small" type="success">
                      Сохранить
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item class="p-0 py-2" v-if="scope.row.showEditor" @click="scope.row.showEditor = false">
                    <el-button text class="w-full" :icon="Remove" size="small" type="danger"> Отменить </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item class="p-0 py-2" @click="destroy(scope.row, incomeId)">
                    <el-button
                      :disabled="scope.row.showEditor"
                      text
                      class="w-full"
                      type="danger"
                      size="small"
                      :icon="Remove"
                    >
                      Удалить
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<style scoped lang="sass"></style>
