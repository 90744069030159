import { useDark } from '@vueuse/core'

export default function useColors() {
  const isDark = useDark()

  const colors = {
    dark: {
      ng_dark: '#101828',
      ng_purple: '#7459CB',
      ng_green: '#54B471',
      ng_red: '#BB468A',
      ng_dark_gray: '#151c28',
      ng_white: '#909399',
      ng_orange: '#E6A23C',
      ng_light_gray: '#98a2b3',
    },
    light: {
      ng_purple: '#4b60cecc',
      ng_black: '#021C1A',
      ng_white: '#FBFFFF',
      ng_error: '#FF9A9D',
      ng_green: '#43d2cc',
      ng_orange: '#E6A23C',
      ng_gray: '#F6F9FF',
      ng_deep_gray: '#909399',
    },
  }

  return {
    colors,
    isDark,
  }
}
