<script setup lang="ts">
import { UploadFilled } from '@element-plus/icons-vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { ElNotification } from 'element-plus'
import { ref } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { IPaymentBody } from '@/04-Widgets/GoodsSelection/PaymentPage.vue'

interface IProps {
  promo: string | undefined
  invoiceId: string | number
  goodBody: IPaymentBody
}
const props = defineProps<IProps>()
const { goodSelect, getInvoice, addPromocode } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()
const loading = ref(false)

const schema = toTypedSchema(
  yup.object({
    promocode: yup.string(),
  })
)
const { handleSubmit, values } = useForm({
  validationSchema: schema,
  initialValues: {
    promocode: props.promo || '',
  },
})

const sendPromocode = handleSubmit((promo) => {
  loading.value = true
  goodSelect(props.invoiceId, props.goodBody).then(() => {
    addPromocode(props.invoiceId, { ...promo })
      .then(() => {
        ElNotification({
          title: `Промокод`,
          message: 'успешно активирован',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          title: `Ошибка`,
          message: `при применении промокода`,
          type: 'error',
        })
      })
      .finally(() => {
        getInvoice(props.invoiceId).then((invoice) => {
          initAsync(invoice)
        })
        loading.value = false
      })
  })
})
</script>

<template>
  <el-form @submit.prevent="sendPromocode" :validation-schema="schema">
    <div class="flex">
      <FormItem name="promocode" v-slot="{ value, onBlur, onInput }" :label="''">
        <el-input
          :model-value="value"
          type="text"
          name="promocode"
          @blur="onBlur"
          @input="onInput"
          placeholder="Промокод"
        />
      </FormItem>
      <el-tooltip effect="dark" content="Активировать промокод" placement="top">
        <el-button
          style="margin-left: -32px; z-index: 1000; width: 30px"
          type="primary"
          :icon="UploadFilled"
          :loading="loading"
          :disabled="values.promocode === ''"
          native-type="submit"
        />
      </el-tooltip>
    </div>
  </el-form>
</template>

<style scoped lang="sass"></style>
