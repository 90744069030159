export default function useTextFilters() {
  const subStringFileName = (text: string, len = 10) => {
    if (text.length < len) return text
    // long_name.ext
    const a = text.split('.') // a[0] long_name ,  a[1] ext
    const b = a[0][a[0].length - 1] // e
    a[0] = a[0]
      .split('')
      .splice(0, len - a[1].length - 5)
      .join('')
    a[0] = `${a[0]}...${b}`
    return a.join('.')
  }

  function emailShortener(email: string): string {
    const [localPart, domain] = email.split('@')

    if (localPart.length > 15) {
      return `${localPart.slice(0, 15)}...@${domain}`
    }

    return email
  }

  const subStringText = (string: string, len = 5) => {
    const array = string?.split(' ')
    return array.splice(0, len).join(' ') + ' ...'
  }

  const priceFormat = (text: string) => {
    const a = text.split('.')
    if (a.length === 2) {
      if (a[1].length === 2) return text
      if (a[1].length === 1) return text + '0'
      a[1] = a[1].split('').splice(2).join('')
      return a.join('.')
    }
    return `${text}.00`
  }

  const concatHostUrl = (text: string) => `${text}`

  // для сайта ФНС (отделяет точками только 3 последних символа)
  const thousandSeparatorFNS = (str: string) => {
    const parts = (str + '').split('.')
    const main = parts[0]
    let output = ''
    let i = main.length - 1

    while (i >= 0) {
      output = main.charAt(i) + output
      if (main.length - i === 3 && i > 0) {
        if (main[0] === '-' && main.length === 4) {
          output = '0.' + output
        } else {
          output = '.' + output
        }
      }
      if (main.length <= 3 && i === 0) {
        switch (main.length) {
          case 3:
            if (main[0] === '-') {
              output = '-0.' + output.replace('-', '0')
            } else {
              output = '0.' + output
            }
            break
          case 2:
            if (main[0] === '-') {
              output = '-0.0' + output.replace('-', '0')
            } else {
              output = '0.0' + output
            }
            break
          case 1:
            output = '0.00' + output
            break
        }
      }
      --i
    }

    if (parts.length > 1) {
      output += '.' + parts[1]
    }
    return output
  }

  /**
   * сепаратор здорового человека
   *
   * @param data {number}
   * @param separator {string} ' ', '/' etc.
   */
  const numberSeparator = (data: number, separator: string) => {
    const parts = (data + '').split('.')
    const main = parts[0]
    let output = ''
    let i = main.length - 1

    while (i >= 0) {
      output = main.charAt(i) + output
      if ((main.length - i) % 3 === 0 && i > 0) {
        output = separator + output
      }
      --i
    }

    if (parts.length > 1) {
      if (Math.abs(data) < 1 && main.length > 1) output += separator + `.${parts[1]}`
    }
    return output
  }

  return {
    subStringFileName,
    priceFormat,
    concatHostUrl,
    thousandSeparatorFNS,
    numberSeparator,
    subStringText,
    emailShortener,
  }
}
