<script lang="ts" setup>
import useColors from '@/07-Shared/model/useColors'

const { colors, isDark } = useColors()
</script>

<template>
  <h1 class="mb-4">Налоговые вычеты</h1>
  <p class="mb-4">Налоговые вычеты — это сумма, которая уменьшает размер дохода, с которого уплачивается налог.</p>
  <p class="mb-4">Либо это возврат части ранее уплаченного налога на доходы физического лица,</p>
  <p class="mb-4">например, в связи с покупкой квартиры, расходами на лечение, обучение и т.д.</p>
  <p class="mb-4 font-weight--semi">
    Чтобы получить налоговые вычеты (социальные, ИИС по типу А, имущественные) необходимо иметь Доходы по Основной
    налоговой базе (по заработной плате, от продажи имущества и/или сдачи имущества в аренду и т.п.).
  </p>
  <p class="mb-4 font-weight--semi">Если таких доходов нет, то вычеты получить нельзя.</p>
  <div class="mb-8">
    <el-popover placement="right" trigger="hover" :width="300">
      <template #reference>
        <div class="flex items-center cursor-point" style="width: 200px">
          <el-icon :color="isDark ? colors.dark.ng_green : colors.light.ng_purple" class="mr-2">
            <QuestionFilled />
          </el-icon>
          <p class="font-color--green font-weight--semi">Как получить вычеты</p>
        </div>
      </template>
      <template #default>
        <div style="word-break: break-word">
          <p class="mb-4">Загрузить справку о доходах (ранее 2-НДФЛ) на странице Дополнительные доходы;</p>
          <p class="mb-4">Либо заполнить на странице Дополнительные доходы данные по ситуациям:</p>
          <p>
            Аренда недвижимого имущества, Продажа транспортного средства, Продажа недвижимого имущества, Переуступка
            права требования по договору долевого участия.
          </p>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<style lang="sass" scoped></style>
