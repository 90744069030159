import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "p-4"
}
const _hoisted_4 = { class: "form-warning" }
const _hoisted_5 = {
  class: "font-color--red",
  style: {"word-break":"break-word"}
}

import img from '@/07-Shared/ui/ImgDescriber'
import { useRouter } from 'vue-router'
import { computed, UnwrapNestedRefs, watch } from 'vue'
import GuruLogo from '@/assets/guru_logo.svg'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { loaderDescriber } from '@/06-Entities/File'
import { array, number, bool, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import SingleFileLoader from '@/06-Entities/File/ui/SingleFileLoader.vue'
import { cloneDeep } from 'lodash'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TwoNdflFiles',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const router = useRouter()
const id = computed(() => router.currentRoute.value.params.id as string)

const LoaderDescriber = [
  {
    title: '2-ндфл',
    text: 'Нажмите или перетащите файлы для загрузки отчетов 2-ндфл',
    serverTag: 'two_ndfl_files',
    name: '2-НДФЛ',
    logo: GuruLogo,
    fileFormat: /.*\.(xml|pdf)$/,
    img: img.csv,
  },
]
const loader = loaderDescriber(LoaderDescriber)
loader.mergeFile({
  two_ndfl_files: props.item.item.two_ndfl_files || [],
})

const schema = object({
  rus: bool().default(true),
  physical_person: bool().default(true),
  currency: string().default('RUB'),
  date: string().default(`01.01.2023`),
  amount: string().default('0'),
  other_attributes: object({
    two_ndfl_files: object({
      file_names: array().of(string()).ensure().default([]).min(1, 'Неабходимо загрузить минимум один файл').required(),
      files: array().of(string()).ensure().default([]).min(1).required(),
    }),
  }),
  two_ndfl_files: array().of(
    object({
      id: number(),
      name: string(),
    })
  ),
})
const validationSchema = toTypedSchema(schema)
const { values, validate, errorBag, errors, setValues } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign(
    {},
    {
      ...props.item.item,
    }
  ),
})
function updateValue() {
  setValues({
    other_attributes: {
      two_ndfl_files: loader.toJson().two_ndfl_files,
    },
  })
}

async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid: props.item.item.two_ndfl_files?.length !== 0 || valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  { immediate: true }
)

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Download = _resolveComponent("Download")!
  const _component_WarningFilled = _resolveComponent("WarningFilled")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(loader).describers, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.config.name,
        class: "p-4 border"
      }, [
        (item?.getFilesFromServer?.length > 0)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_1, "Справки " + _toDisplayString(item.config.name), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.getFilesFromServer, (file) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.id,
            class: "mb-4 mr-2 p-4"
          }, [
            _createVNode(_component_el_tooltip, {
              effect: "dark",
              content: file.serverName,
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(file.name), 1)
              ]),
              _: 2
            }, 1032, ["content"]),
            _createElementVNode("a", {
              onClick: _withModifiers(($event: any) => (item.downloadFile(file, id.value)), ["prevent"]),
              class: "clickable-text-link"
            }, [
              (file.isLoading)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    class: "el-icon-loading font-weight--extra",
                    style: {"vertical-align":"middle"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Loading)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_el_icon, {
                    key: 1,
                    class: "el-icon-download font-weight--extra cursor-point",
                    style: {"vertical-align":"middle"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Download)
                    ]),
                    _: 1
                  }))
            ], 8, _hoisted_2)
          ]))
        }), 128)),
        _createElementVNode("div", {
          class: _normalizeClass(["mb-4", { flex: item.localFiles.files.length === 0 }])
        }, [
          _createVNode(SingleFileLoader, {
            describer: item,
            "broker-name": item.config.name || '',
            onUpdate: updateValue
          }, null, 8, ["describer", "broker-name"]),
          (_unref(errors)['other_attributes.two_ndfl_files.file_names'] && props.item.item.two_ndfl_files?.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_el_popover, {
                  placement: "bottom",
                  width: 300
                }, {
                  reference: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, [
                      _createVNode(_component_el_icon, {
                        color: "#FF9A9D",
                        size: "large",
                        class: "cursor-point"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_WarningFilled)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(errors)['other_attributes.two_ndfl_files.file_names'] || 'нет ошибок'), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
      ]))
    }), 128))
  ]))
}
}

})