<script setup lang="ts">
interface IProps {
  countryCode?: number
}
const props = defineProps<IProps>()
</script>

<template>
  <p class="mb-4 font-bold">Данные о движении денежных средств</p>
  <table class="cfr-table mb-8">
    <tr>
      <th>Название</th>
      <th>Значение</th>
    </tr>
    <tr>
      <td class="font-weight--semi">Валюта</td>
      <td>
        <span class="mr-4">{{ props.countryCode || '' }}</span>
      </td>
    </tr>
    <tr>
      <td class="font-weight--semi">Остаток денежных средств на счете на начало отчетного периода</td>
      <td>
        <span class="mr-4">0</span>
      </td>
    </tr>
    <tr>
      <td class="font-weight--semi">Зачислено денежных средств за отчетный период - всего</td>
      <td>
        <span class="mr-4">0</span>
      </td>
    </tr>
    <tr>
      <td class="font-weight--semi">Списано денежных средств за отчетный период - всего</td>
      <td>
        <span class="mr-4">0</span>
      </td>
    </tr>
    <tr>
      <td class="font-weight--semi">Остаток денежных средств на счете на конец отчетного периода</td>
      <td>
        <span class="mr-4">0</span>
      </td>
    </tr>
  </table>
</template>

<style scoped lang="sass"></style>
