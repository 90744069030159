<script setup lang="ts">
import { IGood } from '@/describers/GoodsDescriber'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { SetUp } from '@element-plus/icons-vue'
import LoaderDescriber from '@/06-Entities/File/model/LoaderDescriber'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  goods: IGood[]
  brokerFiles: any
  amountOperations: number | undefined
  addSelection: Record<string, Record<string, number>>
  personalPrice: number
  year: string | number
}
interface IEmits {
  (e: 'invoice-total', value: number | string): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const router = useRouter()
const { describers } = LoaderDescriber()
const { incomesDeductionsDescribers } = AddIncomesRefundsDescriber()
const { numberSeparator } = useTextFilters()
const tariffsRange = [[0], [1, 25], [26, 50], [51, 200], [201, 400], [401, 1000], [1001, 3000]]
const currentTariffRange = computed(() => {
  if (props.amountOperations) {
    for (const range of tariffsRange) {
      const [min, max] = range
      if (props.amountOperations >= min && props.amountOperations <= max) {
        return `(${range.join(' - ')} доходных операций)`
      }
    }
  } else {
    return `0 доходных операций)`
  }
})
const basket = computed(() => props.goods.filter((good) => good.inBasket.value))
const basketTotal = computed(() => {
  return props.goods.filter((good) => good.inBasket.value).reduce((acc, item) => acc + item.price.value, 0)
})
const addTotal = computed(() =>
  Object.keys(props.addSelection).reduce((acc, key) => acc + props.addSelection[key].price, 0)
)
const invoiceTotal = computed(() => {
  const goodNamesInBasket = basket.value.map((i) => i.name)
  if (goodNamesInBasket.includes('Declaration')) {
    return basketTotal.value + addTotal.value + props.personalPrice
  } else if (!goodNamesInBasket.includes('Declaration')) {
    return basketTotal.value + props.personalPrice
  }
})
const brokersRow = computed(() => {
  return props.brokerFiles.reduce((acc: string, fileTag: string, index: number) => {
    const desc = describers.find((i) => i.serverTag === fileTag)
    if (desc) {
      acc += `${desc.config.name}${index === props.brokerFiles.length - 1 ? '' : ', '}`
    }
    return acc
  }, '')
})

watch(
  invoiceTotal,
  (val: any) => {
    emit('invoice-total', val)
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div>
    <div v-for="good in basket">
      <div class="flex justify-between mb-4">
        <h3>{{ good.title }} {{ year }}</h3>
        <p class="font-weight--extra" v-if="good.name === 'declaration_pdf'">
          ₽ {{ numberSeparator(good.price.value, ' ') }}
        </p>
      </div>
      <div class="mb-4" v-if="good.priceTag === 'declaration' || good.priceTag === 'cfr'">
        <div>
          <div class="flex justify-between mb-1">
            <div class="mb-1 flex dark:text-dark-purple text-light-purple">
              <h4 class="mr-1">Брокерские отчеты</h4>
              <h4 v-if="!!props.amountOperations && props.amountOperations > 0">{{ currentTariffRange }}</h4>
            </div>
            <el-tooltip placement="left" content="Изменить файлы брокеров">
              <el-button
                type="primary"
                :icon="SetUp"
                size="small"
                @click="router.push({ name: 'file_in_invoice' })"
                link
                >Изменить</el-button
              >
            </el-tooltip>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div v-html="brokersRow" />
          <p class="font-weight--extra">₽ {{ numberSeparator(good.price.value, ' ') }}</p>
        </div>
      </div>
      <div v-if="good.priceTag === 'declaration' && Object.keys(props.addSelection).length > 0">
        <div class="flex justify-between mb-1">
          <h4 class="dark:text-dark-purple text-light-purple">Доп. доходы и вычеты</h4>
          <el-tooltip placement="left" content="Изменить дополнительные доходы и вычеты">
            <el-button
              type="primary"
              :icon="SetUp"
              size="small"
              @click="router.push({ name: 'pre_add_deductions' })"
              link
              >Изменить</el-button
            >
          </el-tooltip>
        </div>
        <div v-for="(value, addTag) in props.addSelection" :key="addTag" class="flex justify-between mb-1">
          <div>
            <p>{{ incomesDeductionsDescribers.find((i) => i.tag === addTag)?.name }}</p>
          </div>
          <div class="flex">
            <p class="mr-1">{{ value?.qty }} x</p>
            <p class="font-weight--extra">₽ {{ numberSeparator(value?.price, ' ') }}</p>
          </div>
        </div>
      </div>
      <div class="el-divider-purple my-4" />
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
