<script setup lang="ts">
import PreAddIncomesDeduction from '@/04-Widgets/PreAddIncome/PreAddIncomesDeduction.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'

const invoiceStore = useInvoiceStore()
</script>

<template>
  <div v-if="!!invoiceStore.invoice">
    <pre-add-incomes-deduction :id="invoiceStore.invoice.id" />
  </div>
</template>

<style scoped lang="sass"></style>
