export default function usePropertyDeductionDescriber() {
  const agreement_type = [
    {
      type: 'buy_sell',
      label: 'Договор купли-продажи',
    },
    {
      type: 'investment',
      label: 'Инвестирование',
    },
  ]
  const property_type = [
    {
      type: 'residential',
      label: 'Жилой дом',
    },
    {
      type: 'flat',
      label: 'Квартира',
    },
    {
      type: 'room',
      label: 'Комната',
    },
    {
      type: 'share_residential',
      label: 'Доля (доли) в доме, квартире, комнате',
    },
    {
      type: 'land_individual_construction',
      label: 'Земельный участок для индивидуального жилищного строительства',
    },
    {
      type: 'land_with_purchased_residential',
      label: 'Земельный участок, на котором расположен приобретённый жилой дом',
    },
    {
      type: 'residential_building_with_land',
      label: 'Жилой дом с земельным участком.',
    },
    {
      type: 'share_with_land',
      label: 'Доля (доли) в доме, земле',
    },
  ]
  const owner_type = [
    {
      type: 'owner',
      label: 'Собственник объекта',
    },
    {
      type: 'owner_spouse',
      label: 'Супруг(а) собственника объекта',
    },
    {
      type: 'parent_minor_owner',
      label: 'Родитель несовершеннолетнего собственника объекта',
    },
    {
      type: 'common_applicant_minor',
      label: 'Объект в общей долевой собственности заявителя и несовершеннолетнего ребенка',
    },
    {
      type: 'common_applicant_spouse_minor',
      label: 'Объект в общей долевой собственности супруга(и) заявителя и несовершеннолетнего ребенка',
    },
  ]
  const purchase_type = [
    {
      label: 'Новое строительство жилого дома',
      type: '1',
    },
    {
      label: 'Приобретение жилого дома',
      type: '2',
    },
  ]
  return {
    agreement_type,
    property_type,
    owner_type,
    purchase_type,
  }
}
