<script lang="ts" setup>
import { ref, UnwrapNestedRefs, watch, watchEffect } from 'vue'
import currency from '@/describers/CurrencyDescriber'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { toTypedSchema } from '@vee-validate/yup'
import { string as ystr, object as yobj, bool } from 'yup'
import { getFields } from '../model/formFields'
import { useForm } from 'vee-validate'
import { cloneDeep } from 'lodash'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { ElMessageBox } from 'element-plus'
import { setTouchedHelper } from '../lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: { [key: string]: string }
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()

setTouchedHelper(props.item)
const { mainFields } = getFields(props.yearToCalc)
const schema = yobj({
  ...mainFields,
  other_attributes: yobj({
    // rus physyc
    name: ystr().test('name-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      const physical_person = context.from?.[1].value.physical_person
      if (rus === true && physical_person === true) return ystr().required().isValidSync(value)
      return true
    }), // ФИОИн
    // rus organization
    name_organization: ystr().test('name_organization-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      const physical_person = context.from?.[1].value.physical_person
      if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
      return true
    }), // Наим
    inn: ystr()
      .checkNumberString()
      .length(10)
      .test('inn-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
        return true
      }),
    kpp: ystr()
      .checkNumberString()
      .length(9)
      .test('kpp-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
        return true
      }),
    oktmo: ystr()
      .checkNumberString()
      .length(8)
      .test('oktmo-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
        return true
      }),

    // all outh country
    name_income: ystr().test('name_income-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      if (rus === false) return ystr().required().isValidSync(value)
      return true
    }), // НаимИстДох
    oksm: ystr()
      .checkNumberString()
      .test('oksm-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) return ystr().required().isValidSync(value)
        return true
      }), // ОКСМ ИСТ
    oksm_broker: ystr()
      .checkNumberString()
      .test('oksm_broker-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) return ystr().required().isValidSync(value)
        return true
      }), // ОКСМ ЗАЧ
    // paid tax
    paid_tax: ystr().checkNumberString(),
    paid_tax_date: ystr().test('check_tax_date_validation', function (value) {
      const { createError, path } = this
      if (!!this.parent.paid_tax) {
        const reverse = value?.split('.').reverse().join('/') || ''
        const d = new Date(reverse)
        if (!value) return createError({ path, message: 'Поле является обязательным' })
        return (
          d.getFullYear() === props.yearToCalc ||
          createError({ path, message: 'Год не соответсвует отчетному периоду' })
        )
      }
      return true
    }),
    // exponse
    purchasedByYourself: bool(),
    expense_currency: ystr()
      .length(3)
      .when('purchasedByYourself', {
        is: true,
        then: (schema) => schema.required(),
      }),
    expense_date: ystr()
      .checkYearValue(props.yearToCalc)
      .when('purchasedByYourself', {
        is: true,
        then: (schema) => schema.required(),
      }),
    amount_of_expenses: ystr().checkNumberString(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, setValues, errorBag } = useForm({
  validationSchema,
  initialValues: Object.assign(
    {
      rus: true,
      physical_person: true,
      other_attributes: {
        purchasedByYourself: ![undefined, ''].includes(props.item.item?.other_attributes?.expense_date),
      },
    },
    {
      ...props.item.item,
    }
  ),
  validateOnMount: true,
})

const estimate = ref(false)
// если владеет машиной более 3-х лет
watchEffect(() => {
  if (estimate.value) {
    ElMessageBox.confirm(
      'Если срок владения транспортом более 3-х лет, то декларировать доход от его продажи нет необходимости.',
      {
        confirmButtonText: 'Удалить этот доход',
        cancelButtonText: 'Не удалять',
        type: 'warning',
      }
    )
      .then(() => {
        emit('delete')
      })
      .catch(() => {
        estimate.value = false
      })
  }
})

function setOksm(rus: boolean) {
  if (rus) {
    setValues({
      currency: 'RUB',
      other_attributes: {
        expense_currency: 'RUB',
        oksm: '643',
        oksm_broker: '643',
      },
    })
  } else {
    setValues({
      currency: '',
      other_attributes: {
        expense_currency: '',
        oksm: '',
        oksm_broker: '',
      },
    })
  }
}
watch(
  values,
  (val) => {
    val.rus !== undefined && setOksm(val.rus)
  },
  { immediate: true }
)

async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="px-4 pt-4 mb-4">
    <el-row>
      <el-col>
        <div class="mb-4">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Срок владения</p>
            <question-popover>
              <div v-html="helpDescriber?.estimate" />
            </question-popover>
          </div>
          <el-radio v-model="estimate" :label="false">До 3-х лет</el-radio>
          <el-radio v-model="estimate" :label="true">Более 3-х лет</el-radio>
        </div>
      </el-col>
    </el-row>
    <div>
      <div class="mb-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Доход получен</p>
          <question-popover>
            <div v-html="helpDescriber?.income_info" />
          </question-popover>
        </div>
        <FormItem name="rus" v-slot="{ value, onInput }">
          <el-radio-group
            :model-value="value"
            @change="
            (val: boolean) => {
              onInput(val)
            }
          "
          >
            <el-radio :value="true">в РФ</el-radio>
            <el-radio :value="false">в иностранном государстве</el-radio>
          </el-radio-group>
        </FormItem>
      </div>
      <div v-if="!values.rus" class="flex flex-wrap mb-4">
        <div class="mr-4">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Страна источника выплаты</p>
            <question-popover>
              <div v-html="helpDescriber?.oksm" />
            </question-popover>
          </div>
          <form-item name="other_attributes.oksm" v-slot="{ field, value }">
            <el-select filterable placeholder="Выберите страну" v-bind="field" :model-value="value">
              <el-option
                v-for="item in useCountryDescriber()"
                :key="item.code"
                :value="item.code"
                :label="item.shortname"
              />
            </el-select>
          </form-item>
        </div>
        <div>
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Страна зачисления выплаты</p>
            <question-popover>
              <div v-html="helpDescriber?.oksm_broker" />
            </question-popover>
          </div>
          <form-item name="other_attributes.oksm_broker" v-slot="{ field, value }">
            <el-select filterable placeholder="Выберите страну" v-bind="field" :model-value="value">
              <el-option
                v-for="item in useCountryDescriber()"
                :key="item.code"
                :value="item.code"
                :label="item.shortname"
              />
            </el-select>
          </form-item>
        </div>
      </div>
    </div>
    <div>
      <div class="flex items-center mb-4">
        <p class="font-semibold mr-1">Наличие документов о покупке транспорта</p>
        <question-popover>
          <div v-html="helpDescriber?.purchase_by_yourself" />
        </question-popover>
      </div>
      <div class="flex items-center">
        <form-item name="other_attributes.purchasedByYourself" v-slot="{ field, value }">
          <el-radio-group v-bind="field" :model-value="value">
            <el-radio :value="true">Да</el-radio>
            <el-radio :value="false">Нет</el-radio>
            <question-popover>
              <div v-html="helpDescriber?.purchase_by_yourself_no" />
            </question-popover>
          </el-radio-group>
        </form-item>
      </div>
    </div>
  </div>
  <div class="el-divider-purple my-4"></div>
  <div class="px-4">
    <p class="font-semibold mb-4">Данные о доходе</p>
    <div class="flex items-center flex-wrap mb-2">
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Цена продажи</p>
          <question-popover>
            <div v-html="helpDescriber?.amount" />
          </question-popover>
        </div>
        <form-item name="amount" v-slot="{ field, value }">
          <el-input v-bind="field" :model-value="value" />
        </form-item>
      </div>
      <div v-if="!values.rus" class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-weight--extra mr-1">Валюта продажи</p>
          <question-popover>
            <div v-html="helpDescriber?.currency" />
          </question-popover>
        </div>
        <form-item name="currency" v-slot="{ field, value }">
          <el-select v-bind="field" :model-value="value" filterable placeholder="Укажите валюту">
            <el-option v-for="item in currency" :key="item.code" :value="item.code" :label="item.code" />
          </el-select>
        </form-item>
      </div>
      <div>
        <div class="flex items-center mb-1">
          <p class="font-semibold mr-1">Дата получения дохода</p>
          <question-popover>
            <div v-html="helpDescriber?.date" />
          </question-popover>
        </div>
        <form-item name="date" v-slot="{ field, value }">
          <el-date-picker
            v-bind="field"
            :model-value="value"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
          />
        </form-item>
      </div>
    </div>
    <div v-if="!values.rus" class="mb-4">
      <div class="flex items-center flex-wrap">
        <div class="mr-4">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Налог в иностранном государстве</p>
            <question-popover>
              <div v-html="helpDescriber?.paid_tax_transport" />
            </question-popover>
          </div>
          <form-item name="other_attributes.paid_tax" v-slot="{ field, value }">
            <el-input v-bind="field" :model-value="value" />
          </form-item>
        </div>
        <div>
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Дата уплаты налога</p>
            <question-popover>
              <div v-html="helpDescriber?.paid_tax_transport_date" />
            </question-popover>
          </div>
          <form-item name="other_attributes.paid_tax_date" v-slot="{ field, value }">
            <el-date-picker
              type="date"
              placeholder="Выберите дату"
              format="DD.MM.YYYY"
              value-format="DD.MM.YYYY"
              v-bind="field"
              :model-value="value"
            />
          </form-item>
        </div>
      </div>
    </div>
    <div v-if="values.other_attributes?.purchasedByYourself" class="mb-4">
      <div class="flex items-center flex-wrap mb-4">
        <div class="mr-4">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Цена покупки</p>
            <question-popover>
              <div v-html="helpDescriber?.transport_price" />
            </question-popover>
          </div>
          <form-item name="other_attributes.amount_of_expenses" v-slot="{ field, value }">
            <el-input v-bind="field" :model-value="value" />
          </form-item>
        </div>
        <div v-if="!values.rus" class="mr-4">
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Валюта покупки</p>
            <question-popover>
              <div v-html="helpDescriber?.expense_currency" />
            </question-popover>
          </div>
          <form-item name="other_attributes.expense_currency" v-slot="{ field, value }">
            <el-select v-bind="field" :model-value="value" filterable placeholder="Укажите валюту">
              <el-option v-for="item in currency" :key="item.code" :value="item.code" :label="item.code" />
            </el-select>
          </form-item>
        </div>
        <div>
          <div class="flex items-center mb-1">
            <p class="font-semibold mr-1">Дата покупки</p>
            <question-popover>
              <div v-html="helpDescriber?.expense_date" />
            </question-popover>
          </div>
          <form-item name="other_attributes.expense_date" v-slot="{ field, value }">
            <el-date-picker
              type="date"
              placeholder="Выберите дату"
              format="DD.MM.YYYY"
              value-format="DD.MM.YYYY"
              v-bind="field"
              :model-value="value"
            />
          </form-item>
        </div>
      </div>
    </div>
  </div>
  <div class="el-divider-purple my-4"></div>
  <!-- other attributes -->
  <div class="px-4 mb-4">
    <div class="mb-4">
      <div class="flex items-center mb-1">
        <p class="font-semibold">Другая сторона договора</p>
        <question-popover>
          <div v-html="helpDescriber?.name_income" />
        </question-popover>
      </div>
      <div v-if="values.rus">
        <form-item name="physical_person" v-slot="{ field, value }">
          <el-radio-group v-bind="field" :model-value="value">
            <el-radio :value="false">Юридическое лицо</el-radio>
            <el-radio :value="true">Физическое лицо</el-radio>
          </el-radio-group>
        </form-item>
      </div>
    </div>
    <div class="flex items-center flex-wrap">
      <div v-if="values.rus">
        <div v-if="values.physical_person" class="mr-4">
          <p class="font-semibold mb-1">ФИО</p>
          <form-item name="other_attributes.name" v-slot="{ field, value }">
            <el-input v-bind="field" :model-value="value" />
          </form-item>
        </div>
        <div class="flex items-center flex-wrap" v-else>
          <div class="mr-4">
            <p class="font-semibold mb-1">Наименование организации</p>
            <form-item name="other_attributes.name_organization" v-slot="{ field, value }">
              <el-input v-bind="field" :model-value="value" />
            </form-item>
          </div>
          <div class="mr-4">
            <p class="font-semibold mb-1">ИНН</p>
            <form-item name="other_attributes.inn" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="123456789012" v-mask="['############']" />
            </form-item>
          </div>
          <div class="mr-4">
            <p class="font-semibold mb-1">КПП</p>
            <form-item name="other_attributes.kpp" v-slot="{ field, value }">
              <el-input v-bind="field" :model-value="value" placeholder="123456789" v-mask="['#########']" />
            </form-item>
          </div>
          <div class="mr-4">
            <p class="font-semibold mb-1">ОКТМО</p>
            <form-item name="other_attributes.oktmo" v-slot="{ field, value }">
              <el-input v-bind="field" :model-value="value" size="large" placeholder="12345678" v-mask="['########']" />
            </form-item>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center flex-wrap">
          <div>
            <p class="font-semibold mb-1">Наименование</p>
            <form-item name="other_attributes.name_income" v-slot="{ field, value }">
              <el-input v-bind="field" :model-value="value" />
            </form-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.el-radio
  margin-right: 20px !important
</style>
