import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-4" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "flex items-center justify-between" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex items-center justify-between" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex items-center justify-between" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex items-center justify-between" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex items-center justify-between" }
const _hoisted_14 = { class: "flex items-center mb-2" }
const _hoisted_15 = { class: "font-weight--extra" }
const _hoisted_16 = { class: "flex items-center mb-2" }
const _hoisted_17 = { class: "font-weight--extra margin-h--left-s" }

import { ref } from 'vue'
import CalculatedIncomeTableFilter from '@/components/Admin/CalculatedIncomeTableFilter.vue'
import { ICommonExpense } from '@/entityes/invoice/trade_operations/trade_operations.type'
import useCommonExpensesRepository from '@/entityes/invoice/trade_operations/сommon_expenses.repository'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, Operation, EditPen, Remove, CircleCheckFilled, InfoFilled } from '@element-plus/icons-vue'

interface IProps {
  declarationId: string | number
  commonExpenses: ICommonExpense[]
  tPages: number
  cPage: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonExpenses',
  props: {
    declarationId: {},
    commonExpenses: {},
    tPages: {},
    cPage: {}
  },
  setup(__props: any) {

const props = __props
const commonExpensesToDelete = ref([])
const search = ref('')
const sort = ref('')
const loading = ref(false)
const currentEditingRow = ref<any>(undefined)
const {
  getCommonExpenses,
  createCommonExpense,
  destroyCommonExpense,
  updateCommonExpense,
  deleteSelectedCommonExpenses,
} = useCommonExpensesRepository()

function changePage(page: number) {
  loading.value = true
  getCommonExpenses(props.declarationId, page, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса общих расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function create() {
  loading.value = true
  createCommonExpense(props.declarationId)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `создан общий доход`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `создания общего дохода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function update(commonExpense: ICommonExpense) {
  loading.value = false
  updateCommonExpense(commonExpense)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `обновлен общий доход ${commonExpense.id}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления общего дохода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function destroy(commonExpenseId: string | number) {
  loading.value = true
  destroyCommonExpense(commonExpenseId)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `удален общий доход ${commonExpenseId}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления общих доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
const chooseToBulkDelete = (val: ICommonExpense[]) => {
  commonExpensesToDelete.splice(0, commonExpensesToDelete.length, ...val.map((i: ICommonExpense) => i.id))
}
function deleteSelectedRows() {
  loading.value = true
  deleteSelectedCommonExpenses(props.declarationId, commonExpensesToDelete)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `удалены общие доходы`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления общих доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tickerFilter() {
  getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса общих расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function sortEvent(val: string) {
  loading.value = true
  sort.value = val
  getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса общих расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function openIncomeEditor(val: Record<string, string>) {
  if (currentEditingRow.value) currentEditingRow.value.showEditor = false
  val.showEditor = true
  currentEditingRow.value = val
}
function closeIncomeEditor() {
  if (currentEditingRow.value === undefined) return
  currentEditingRow.value.showEditor = false
  currentEditingRow.value = undefined
}

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_pagination, {
        "current-page": props.cPage,
        total: props.tPages,
        layout: "prev, pager, next",
        "page-size": 1,
        onCurrentChange: changePage,
        background: "",
        size: "small"
      }, null, 8, ["current-page", "total"]),
      _createVNode(_component_el_input, {
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        placeholder: "Поиск по общим расходам",
        style: {"width":"300px"},
        onInput: tickerFilter
      }, null, 8, ["modelValue"])
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      data: props.commonExpenses,
      class: "mb-4 w-full",
      "table-layout": "auto",
      stripe: "",
      border: "",
      onSelectionChange: chooseToBulkDelete,
      tabindex: "-1",
      onCellClick: openIncomeEditor,
      onKeyup: [
        _cache[1] || (_cache[1] = _withKeys(($event: any) => (update(currentEditingRow.value)), ["enter"])),
        _withKeys(closeIncomeEditor, ["esc"])
      ]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          type: "selection",
          width: "40"
        }),
        _createVNode(_component_el_table_column, {
          label: "broker",
          fixed: "",
          width: "260"
        }, {
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: scope.row.broker,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.broker) = $event),
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(scope.row.broker), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "description",
          width: "370"
        }, {
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: scope.row.description,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.description) = $event),
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(scope.row.description), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "date",
          width: "140"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "date", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "date",
                onSearchEvent: sortEvent,
                element: "sort"
              })
            ])
          ]),
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_date_picker, {
                  key: 0,
                  modelValue: scope.row.date,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.date) = $event),
                  type: "datetime",
                  format: "YYYY/MM/DD hh:mm:ss",
                  "value-format": "YYYY-MM-DD",
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(scope.row.date), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "paid_sum",
          width: "140"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("p", null, "paid_sum", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "paid_sum",
                onSearchEvent: sortEvent,
                element: "sort"
              })
            ])
          ]),
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: scope.row.paid_sum,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.paid_sum) = $event),
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(scope.row.paid_sum), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "currency",
          width: "140"
        }, {
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: scope.row.currency,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.currency) = $event),
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(scope.row.currency), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "rub_sum",
          width: "140"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "rub_sum", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "rub_sum",
                onSearchEvent: sortEvent,
                element: "sort"
              })
            ])
          ]),
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: scope.row.rub_sum,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.rub_sum) = $event),
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(scope.row.rub_sum), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "rate",
          width: "140"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              _cache[5] || (_cache[5] = _createElementVNode("p", null, "rate", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "rub_sum",
                onSearchEvent: sortEvent,
                element: "sort"
              })
            ])
          ]),
          default: _withCtx((scope) => [
            (scope.row.showEditor)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: scope.row.rate,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.rate) = $event),
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(scope.row.rate), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          fixed: "right",
          width: "40"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, [
              (commonExpensesToDelete.value.length > 0)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    onClick: deleteSelectedRows,
                    type: "danger",
                    icon: _unref(Remove)
                  }, null, 8, ["icon"]))
                : (_openBlock(), _createBlock(_component_el_button, {
                    key: 1,
                    icon: _unref(CirclePlusFilled),
                    type: "primary",
                    onClick: create
                  }, null, 8, ["icon"]))
            ])
          ]),
          default: _withCtx((scope) => [
            _createVNode(_component_el_dropdown, null, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { class: "p-0 py-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_popover, {
                          disabled: scope.row.showEditor,
                          placement: "left",
                          title: "Инфо об общем доходе",
                          width: 240,
                          trigger: "hover"
                        }, {
                          reference: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              icon: _unref(InfoFilled),
                              disabled: scope.row.showEditor,
                              text: "",
                              size: "small",
                              type: "primary",
                              class: "w-full"
                            }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" Доп информация ")
                              ])),
                              _: 2
                            }, 1032, ["icon", "disabled"])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, [
                              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "id:", -1)),
                              _createElementVNode("p", _hoisted_15, _toDisplayString(scope.row.id), 1)
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "static_expense_id:", -1)),
                              _createElementVNode("p", _hoisted_17, _toDisplayString(scope.row.static_expense_id), 1)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["disabled"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_dropdown_item, {
                      disabled: scope.row.showEditor,
                      onClick: ($event: any) => (openIncomeEditor(scope.row)),
                      class: "p-0 py-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          disabled: scope.row.showEditor,
                          class: "w-full",
                          icon: _unref(EditPen),
                          size: "small",
                          text: "",
                          type: "primary"
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Редактировать ")
                          ])),
                          _: 2
                        }, 1032, ["disabled", "icon"])
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"]),
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 0,
                          onClick: ($event: any) => (update(scope.row)),
                          class: "p-0 py-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              class: "w-full",
                              text: "",
                              icon: _unref(CircleCheckFilled),
                              size: "small",
                              type: "success"
                            }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode(" Сохранить ")
                              ])),
                              _: 1
                            }, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 1,
                          onClick: closeIncomeEditor,
                          class: "p-0 py-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              class: "w-full",
                              type: "danger",
                              text: "",
                              icon: _unref(Remove),
                              size: "small"
                            }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode(" Отменить ")
                              ])),
                              _: 1
                            }, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "info",
                  icon: _unref(Operation),
                  size: "small"
                }, null, 8, ["icon"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])), [
      [_directive_loading, loading.value]
    ])
  ], 64))
}
}

})