import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mb-4" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex items-center" }

import CalculatedIncomeTableFilter from '@/components/Admin/CalculatedIncomeTableFilter.vue'
import useIssuersRepository from '@/entityes/invoice/trade_operations/issuers.repository'
import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  declarationId: string | number
  issuers: any
  tPages: number
  cPage: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IssuersTable',
  props: {
    declarationId: {},
    issuers: {},
    tPages: {},
    cPage: {}
  },
  setup(__props: any) {

const props = __props
const sort = ref('')
const search = ref('')
const loading = ref(false)
const { getIssuers } = useIssuersRepository()
const { colors, isDark } = useColors()

function changePage(page: number) {
  loading.value = true
  getIssuers(props.declarationId, page, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка эмитентов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tickerFilter() {
  loading.value = true
  getIssuers(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка эмитентов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function sortEvent(val) {
  loading.value = true
  sort.value = val
  getIssuers(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения эмитентов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tableRowClassName(row: any, rowIndex: number) {
  if (row.diff) {
    console.log(row.diff)
  }
  return 'warning-row'
}

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_WarningFilled = _resolveComponent("WarningFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_pagination, {
        "current-page": props.cPage,
        total: props.tPages,
        layout: "prev, pager, next",
        "page-size": 1,
        onCurrentChange: changePage,
        background: "",
        size: "small"
      }, null, 8, ["current-page", "total"]),
      _createVNode(_component_el_input, {
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        placeholder: "Поиск по эмитентам",
        style: {"width":"300px"},
        onInput: tickerFilter
      }, null, 8, ["modelValue"])
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      data: props.issuers,
      class: _normalizeClass(["mb-4", loading.value ? 'table-height-loading' : '']),
      "table-layout": "auto",
      stripe: "",
      border: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "ticker",
          fixed: "",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                class: _normalizeClass(["mr-2", Math.abs(scope.row.diff) > 10 ? 'table-alert-diff' : ''])
              }, _toDisplayString(scope.row.ticker), 3),
              (Math.abs(scope.row.diff) > 10)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    color: _unref(isDark) ? _unref(colors).dark.ng_red : _unref(colors).light.ng_error
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_WarningFilled)
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "brokers",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.brokers), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "income_currency",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.income_currency), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "expense_currency",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.expense_currency), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "expense_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.expense_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "currency",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.currency), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "income_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.income_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "result_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.result_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "realized_pl",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.realized_pl), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "tax_sum_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.tax_sum_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "total_expenses_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.total_expenses_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "asset_return_without_inflation_after_tax_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.asset_return_without_inflation_after_tax_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "asset_return_without_inflation_with_expenses_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.asset_return_without_inflation_with_expenses_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "asset_return_without_inflation_without_expenses_currency",
          width: "230"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.asset_return_without_inflation_without_expenses_currency), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "asset_return_without_inflation_without_expenses_rub",
          width: "220"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.asset_return_without_inflation_without_expenses_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "effective_tax_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.effective_tax_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "implemented_currency",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.implemented_currency), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "implemented_with_revaluation_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.implemented_with_revaluation_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "implemented_without_revaluation_rub",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.implemented_without_revaluation_rub), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "diff",
          fixed: "",
          width: "100"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "diff", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "diff",
                onSearchEvent: sortEvent,
                element: "sort"
              })
            ])
          ]),
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", {
                class: _normalizeClass(["mr-2", Math.abs(scope.row.diff) > 10 ? 'table-alert-diff' : ''])
              }, _toDisplayString(scope.row.diff), 3),
              (Math.abs(scope.row.diff) > 10)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    color: _unref(isDark) ? _unref(colors).dark.ng_red : _unref(colors).light.ng_error
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_WarningFilled)
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "class"])), [
      [_directive_loading, loading.value]
    ]),
    _createVNode(_component_el_pagination, {
      "current-page": props.cPage,
      total: props.tPages,
      layout: "prev, pager, next",
      "page-size": 1,
      onCurrentChange: changePage,
      background: "",
      size: "small",
      class: "mb-4"
    }, null, 8, ["current-page", "total"])
  ], 64))
}
}

})