import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bounce-in-right"
}
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex items-center" }

import { computed } from 'vue'
import useColors from '@/07-Shared/model/useColors'
import { Discount, Bell } from '@element-plus/icons-vue'

interface IProps {
  ototimer: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OTO',
  props: {
    ototimer: {}
  },
  setup(__props: any) {

const props = __props
const timerOTO = computed(() => props?.ototimer || 0)
const endOto = computed(() => {
  const date = new Date(Date.now() + timerOTO.value * 1000)
  const day = Number(date.getDate()) > 9 ? date.getDate() : `0${date.getDate()}`
  return `${day}-${date.getMonth() + 1}-${date.getFullYear()}`
})
const { colors, isDark } = useColors()

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!

  return (timerOTO.value !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["border font-weight--extra p-2", _unref(isDark) ? 'dark-oto' : 'light-oto'])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Скидка 5%", -1)),
            (timerOTO.value * 1000 < 24 * 3600 * 1000)
              ? (_openBlock(), _createBlock(_component_el_icon, {
                  key: 0,
                  size: "20",
                  class: "icon-shake"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Bell))
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_icon, {
                  key: 1,
                  size: "20",
                  class: "icon-shake"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Discount))
                  ]),
                  _: 1
                }))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mr-1 word" }, "Действительно до:", -1)),
            _createElementVNode("p", null, _toDisplayString(endOto.value), 1)
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}
}

})