import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "no-select box-main" }
const _hoisted_3 = { class: "ng-container" }


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

// import BitrixOptionController from '@/controllers/BitrixOptionsController'
// import newLandingController from '@/controllers/LandingController'
// import { useStore } from 'vuex'
// import { computed, watch } from 'vue'
// import { useRouter } from 'vue-router'
// import usePolitic from '@/use/usePolitic'
// import useScroll from '@/use/useScroll'

// export default {
//   setup() {
//     const chatOptions = BitrixOptionController()
//     chatOptions.methods.hideContactForm()
//     const store = useStore()
//     const user = computed(() => store.state.user.user)
//     const router = useRouter()
//     const { scrollTop } = useScroll()
//     watch(router.currentRoute, scrollTop)
//     store.commit('service/createGlobalEmitter')
//     const getSettingMenuStatus = computed(() => store.getters['menuState/getSettingsState'])
//     const setSettingsClose = () => {
//       if (getSettingMenuStatus.value) store.commit('menuState/setSettingsClose')
//     }
//     const politic = usePolitic()
//     newLandingController().methods.putUtmToLocalStorage()
//
//     const init = () => {
//       store.dispatch('user/Action_LoadUserInfoFromLocalStore')
//       if (store.getters['user/is_login']) {
//         if (politic?.isAdmin.value) {
//           store.dispatch('user/getUserInfo')
//           store.dispatch('user/getProfiles')
//         } else {
//           store.dispatch('user/getProfiles')
//         }
//       }
//     }
//
//     init()
//
//     const utm = localStorage.getItem('ng_utm')
//     return {
//       BitrixOptionController,
//       setSettingsClose,
//       utm,
//       user,
//       ...politic,
//     }
//   },
// }

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}
}

})