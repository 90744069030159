<script lang="ts" setup>
import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'
import useTradeOperationsRepository from '@/entityes/invoice/trade_operations/trade_operation.repository'
import CalculatedIncomes from '@/04-Widgets/TradeOperations/ui/CalculatedIncomes.vue'
import { CircleCloseFilled, Refresh } from '@element-plus/icons-vue'
import CommonExpenses from '@/04-Widgets/TradeOperations/ui/CommonExpenses.vue'
import useCommonExpensesRepository from '@/entityes/invoice/trade_operations/сommon_expenses.repository'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import useIssuersRepository from '@/entityes/invoice/trade_operations/issuers.repository'
import IssuersTable from '@/04-Widgets/TradeOperations/ui/IssuersTable.vue'

interface IProps {
  id: string | number
  declarationId: string | number
  showRecalcButton: boolean
}
interface IEmits {
  (e: 'recalculate'): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const tradeStore = useTradeOperationsStore()
const { calculatedIncomesInit } = useTradeOperationsRepository()
const { getCommonExpenses } = useCommonExpensesRepository()
const { getIssuers } = useIssuersRepository()
const loading = ref(false)
const activeCalcPage = ref('calcIncome')
function recalcDeclaration() {
  emit('recalculate')
}
const clearAllFiltersAndSearch = () => {
  calculatedIncomesInit(props.id, 1, '', '', '')
  getCommonExpenses(props.declarationId, 1, '', '')
  getIssuers(props.declarationId, 1, '', '')
}
function init() {
  loading.value = true
  calculatedIncomesInit(props.id, 1, '', '', '')
    .then(() => {
      getCommonExpenses(props.declarationId, 1, '', '')
        .then(() => {
          getIssuers(props.declarationId, 1, '', '').catch((err) => {
            ElNotification({
              title: 'Ошибка',
              message: `получения списка эмитентов ${err}`,
              type: 'error',
            })
          })
        })
        .catch((err) => {
          ElNotification({
            title: 'Ошибка',
            message: `получения списка общих расходов ${err}`,
            type: 'error',
          })
        })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
init()
</script>

<template>
  <clear-loader v-if="loading" />
  <div v-else>
    <div class="flex items-center justify-between mb-4">
      <h1>Сделки</h1>
      <el-button-group>
        <el-tooltip class="box-item" effect="dark" content="Сбросить фильтры" placement="top">
          <el-button
            :loading="loading"
            :disabled="!props.showRecalcButton"
            :icon="CircleCloseFilled"
            type="danger"
            size="small"
            @click="clearAllFiltersAndSearch"
          >
            Сбросить фильтры
          </el-button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" content="Пересчитать на основании таблицы" placement="top">
          <el-button
            type="success"
            @click="recalcDeclaration"
            :loading="loading"
            :disabled="!props.showRecalcButton"
            :icon="Refresh"
            size="small"
          >
            Пересчитать
          </el-button>
        </el-tooltip>
      </el-button-group>
    </div>
    <div class="select">
      <el-tabs v-model="activeCalcPage">
        <el-tab-pane label="Доходы + Расходы" name="calcIncome" v-if="!!tradeStore.calculatedIncomes">
          <calculated-incomes
            :calculated-incomes="tradeStore.calculatedIncomes"
            :okvs="tradeStore.incomeOkvs"
            :income-codes="tradeStore.incomeCodes"
            :id="props.id"
            :declaration-id="props.declarationId"
            :t-pages="tradeStore.incomesPagination.total_pages"
            :c-page="tradeStore.incomesPagination.current_page"
          />
        </el-tab-pane>
        <el-tab-pane
          label="Общие расходы"
          name="commonExpenses"
          v-if="!!tradeStore.calculatedIncomes && !!tradeStore.commonExpenses"
        >
          <common-expenses
            :declaration-id="props.declarationId"
            :common-expenses="tradeStore.commonExpenses"
            :t-pages="tradeStore.commonExpensesPagination.total_pages"
            :c-page="tradeStore.commonExpensesPagination.current_page"
          />
        </el-tab-pane>
        <el-tab-pane
          v-if="!!tradeStore.calculatedIncomes && !!tradeStore.commonExpenses && !!tradeStore.issuers"
          label="Эмитенты"
          name="issuers"
        >
          <issuers-table
            :declaration-id="props.declarationId"
            :issuers="tradeStore.issuers"
            :t-pages="tradeStore.issuersPagination.total_pages"
            :c-page="tradeStore.issuersPagination.current_page"
            ref="Issuers"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
